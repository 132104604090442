import { routes } from "@/routes";
import { useDispatch } from "react-redux";

import { closeBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import * as APIS from "@services/apis";

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

interface IChangeParam {
  transId: string;
  info: APIS.GoodsBuyDeliveryPayload;
}

const useShoppingChange = () => {
  const dispatch = useDispatch();

  const mutationFn = async ({ transId, info }: IChangeParam): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.putGoodsBuyDelivery(transId, info);
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(
          showToast({ message: "정보가 변경되었습니다.", icon: "success" }),
        );
        dispatch(closeBottomSheet());
        return response.data.data;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(
    [routes.shoppingDetail, "/change"],
    mutationFn,
  );

  return mutation;
};

export default useShoppingChange;
