import Warranty from "@assets/icons/iconWarranty.png";
import iconSuccess48 from "@assets/icons/status_success_48px.png";
import Rectangle from "@assets/images/Rectangle.png";

interface StatusTopAreaProps {
  status: string;
  requestMethod: string;
}
//반송반려, 반송완료, 감정반려, 접수반려 사용 x
const StatusTopArea = ({ status, requestMethod }: StatusTopAreaProps) => {
  const renderContent = () => {
    switch (status) {
      case "접수":
        return (
          <div className="status_top">
            <div className="status_top">
              <img src={iconSuccess48} />
              <h3>
                <span className="fc-bl03">접수 완료</span> 되었습니다.
              </h3>
            </div>
            {requestMethod === "SIMPLE_DELIVERY" ? (
              <div className="status_noti">
                <img src={Rectangle} />
                <p>
                  우체국 기사님이 가져가실 예정입니다.
                  <br />잘 포장해 주세요!
                </p>
              </div>
            ) : (
              <div className="status_noti">
                <img src={Warranty} />
                <p>보증서가 있는 경우 상품과 함께 가져오세요!</p>
              </div>
            )}
          </div>
        );
      case "배송중":
        return (
          <div className="status_top">
            <div className="status_top">
              <h3>
                <span className="fc-bl03">감정센터로 배송중</span> 입니다.
              </h3>
            </div>
          </div>
        );
      case "감정시작":
        return (
          <div className="status_top">
            <div className="status_top">
              <h3>
                <span className="fc-bl03">감정중</span> 입니다.
              </h3>
            </div>
          </div>
        );
      case "감정완료":
        return (
          <div className="status_top">
            <div className="status_top">
              <h3>
                <span className="fc-bl03">감정 완료</span> 되었습니다.
              </h3>
            </div>
          </div>
        );
      case "교환완료":
        return (
          <div className="status_top">
            <div className="status_top">
              <img src={iconSuccess48} />
              <h3>
                <span className="fc-bl03">교환 완료</span> 되었습니다.
              </h3>
            </div>
          </div>
        );
      case "반송신청":
        return (
          <div className="status_top">
            <div className="status_top">
              <img src={iconSuccess48} />
              <h3>
                <span className="fc-bl03">반송중</span>입니다.
              </h3>
            </div>
          </div>
        );
    }
  };
  return renderContent();
};

export default StatusTopArea;
