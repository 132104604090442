import { AuthState } from "@features/auth/authSlice";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState> & {
  auth: AuthState;
};
export type AppDispatch = typeof store.dispatch;
const persistor = persistStore(store);
export { store, persistor };
