import { routes } from "@/routes";
import { setLocalStorageItem } from "@services/storageService";
import constants from "@utils/constants";
import { numberWithCommas } from "@utils/utils";
import { useNavigate } from "react-router-dom";

import ProductGrade from "@components/reviewWidget/ProductGrade";

import { GoodsListInfo } from "../hooks/useGoodsList";
import { soldOut } from "../styles.css";
import * as style from "./GoodsStackInfo.css";

interface GoodsStackInfo {
  goodsListData: GoodsListInfo | undefined;
  tab: number;
}

const GoodsStackInfo = (props: GoodsStackInfo) => {
  const { goodsListData, tab } = props;
  const navigate = useNavigate();
  const handleClickDetail = (goodsId: number) => {
    navigate(`${routes.buyGoodsDetail}/${goodsId}`);
    setLocalStorageItem("fromBuy", tab);
  };

  return (
    <div className="buy_main01_cont">
      <div className="buy_list01">
        {goodsListData?.map((listItem, index) => {
          const { isSoldOut } = listItem;

          const textIndex =
            index + 1 < 4
              ? (String(index + 1) as "1" | "2" | "3")
              : ("rest" as const);
          return (
            <div
              className={`list01_box`}
              key={listItem.goodsId}
              onClick={() => handleClickDetail(listItem.goodsId)}
            >
              <div
                className={style.listImageSection}
                // style={_isSoldout ? {} : {}}
              >
                <img
                  src={`${constants.s3BaseUrl}/${listItem.image}`}
                  className={`${isSoldOut ? soldOut.image : ""}`}
                  alt="상품이미지"
                />
                <div className={style.numbering[textIndex]}>{index + 1}</div>
              </div>
              <div className="list_cont">
                <h3>
                  {!isSoldOut && (
                    <>
                      <span className="per fc-rd01">
                        {listItem.discountRate > 0
                          ? `${listItem.discountRate}%`
                          : ""}
                      </span>
                      {numberWithCommas(listItem.sellingPrice)}원
                    </>
                  )}
                </h3>{" "}
                <p className={`cost ${isSoldOut && soldOut.text}`}>
                  {numberWithCommas(listItem.standardPrice)}원
                </p>
                <p className={`pd_name ${isSoldOut && soldOut.text}`}>
                  {listItem.goodsName}
                </p>
                <p className={`pd_brand ${isSoldOut && soldOut.text}`}>
                  {" "}
                  {Boolean(listItem.brand) && listItem.brand}
                </p>
                <div>
                  <ul>
                    {isSoldOut ? (
                      <div className={soldOut.korButton}>품절</div>
                    ) : (
                      <>
                        {/* <li className="score">
                      </li> */}
                        <ProductGrade id={listItem.goodsId} />
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GoodsStackInfo;
