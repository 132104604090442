import React, { useState, useEffect } from "react";
import iconLeft from "../../assets/icons/icon_left.png";
import iconX from "../../assets/icons/icon_x.png";
import opPd1 from "../../assets/icons/op_pd1.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTenSecondAppraisal,
  selectTenAppraisal,
  setTenSecondAppraisal,
} from "../../features/appraisal-tensecond/appraisalTenSecondSlice";
import { hideLoading, showLoading } from "../../features/loading/loadingSlice";
import * as APIS from "../../services/apis";
import consts from "../../utils/constants";
import * as style from "../appraisal-select-asset/style.css";
import DetailHeader from "../../components/layouts/DetailHeader";
import { closeModal, openModal } from "../../features/modal/modalSlice";
import modalNoti from "../../assets/images/modal_noti.png";
import colors from "@assets/styles/colors.css";

interface AppraisalSelectItemProps {}
interface Item {
  id: number;
  assetType: string;
  guideUrl: string;
  imageUrl: string;
  isUserProduct: boolean;
  isWeightRateChange: boolean;
  itemType: string;
  name: string;
  purityType: string;
  purityTypeName: string;
  sortOrder: number;
  weightRatePct: number;
}

const AppraisalSelectItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [item, setItem] = useState("");
  const [goldItemList, setGoldItemList] = useState<Item[]>([]);
  const [silverItemList, setSilverItemList] = useState<Item[]>([]);
  const [weightRatePct, setWeightRatePct] = useState(0);
  const tenAppraisalData = useSelector(selectTenAppraisal).tenSecondAppraisal;

  const assetType = tenAppraisalData.assetType
    .replace("GOLD", "금")
    .replace("SILVER", "은");

  const handleClickNext = () => {
    if (item !== "") {
      dispatch(setTenSecondAppraisal({ item, weightRatePct }));
      navigate(routes.appraisalSelectPurity);
    }
  };

  useEffect(() => {
    dispatch(showLoading());
    const getGoldProducts = APIS.getAppraisalProducts("GOLD").then(
      (response) => {
        const { data } = response.data;
        setGoldItemList(data);
      },
    );
    // .catch((err) => dispatch(messageError(err)));

    const getSilverProducts = APIS.getAppraisalProducts("SILVER").then(
      (response) => {
        const { data } = response.data;
        setSilverItemList(data);
      },
    );
    // .catch((err) => dispatch(messageError(err)));

    Promise.all([getGoldProducts, getSilverProducts]).finally(() =>
      dispatch(hideLoading()),
    );
  }, []);

  const itemList =
    tenAppraisalData.assetType === "GOLD" ? goldItemList : silverItemList;

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickStop = () => {
    dispatch(resetTenSecondAppraisal());
    dispatch(closeModal());
    navigate(routes.sell);
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={modalNoti} alt="" />
            <h3>감정하신 모든 정보가 사라집니다.</h3>
            <p>그만하시겠어요?</p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleClickStop}>
                그만하기
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={style.wrap}>
      <DetailHeader
        title="감정 예상가 확인하기"
        cancelCallback={handleClickCancel}
        backCallback={handleClickBack}
      />
      <div className="sub_wrap02">
        <div className="sell_wrap01">
          <div className="check_option">
            <span className="check_option_sel">선택:</span>
            <span className="check_option_op gd">{assetType}</span>
          </div>
          <div className="spoqa sell_ing">
            <p>
              <span>2</span>/4
            </p>
          </div>
          <div className="sell_option">
            <h3 className="op_tit">
              <span>감정 품목</span>을 선택해 주세요.
            </h3>
            <ul>
              {itemList.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="op_pd_box">
                      <input
                        type="radio"
                        id={`op_pd${index}`}
                        name="op_pd"
                        value={item.name}
                        onChange={(e) => {
                          setItem(e.target.value);
                          setWeightRatePct(item.weightRatePct);
                        }}
                      />
                      <label htmlFor={`op_pd${index}`}>
                        <img
                          src={`${consts.s3BaseUrl}/${item.imageUrl}`}
                          alt=""
                        />
                      </label>
                      <p>{item.name}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${item !== "" ? "on" : ""}`}
          onClick={handleClickNext}
          style={{
            backgroundColor: item !== "" ? colors.buttonColor : "#ddd",
          }}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default AppraisalSelectItem;
