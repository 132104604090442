import { numFormat } from "@utils/utils";
import { RefObject, createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import IcoInfo24 from "@assets/icons/ico_info_24px.png";

interface StorageFeeConfigDto {
  storage_free_period: number;
  storage_fee_unit_krw: number;
  storage_fee_max_krw: number;
  storage_free_gram_gold: number;
  storage_free_gram_silver: number;
  storage_fee_unit_gold: number;
  storage_fee_unit_silver: number;
}
interface FeesState {
  totalFeeKrw: number;
  storageFeeMonthDtoList: any[];
  beforeStorageFeeList: any[];
  storageFeeConfingDto: StorageFeeConfigDto;
}
interface StorageWeightProps {
  fees: FeesState;
}
const StorageWeight: React.FC<StorageWeightProps> = ({ fees }) => {
  const [freePeriod, setFreePeriod] = useState<number>(0);
  const [unitKrwResponse, setUnitKrwResponse] = useState<number>(0);
  const [maxKrwResponse, setMaxKrwResponse] = useState<number>(0);
  const [goldFreeGram, setGoldFreeGram] = useState<number>(0);
  const [silverFreeGram, setSilverFreeGram] = useState<number>(0);
  const [goldFeeUnit, setGoldFeeUnit] = useState<number>(0);
  const [silverFeeUnit, setSilverFeeUnit] = useState<number>(0);
  const [checkboxRefs, setCheckboxRefs] = useState<
    RefObject<HTMLInputElement>[]
  >([]);

  useEffect(() => {
    setCheckboxRefs((refs) =>
      Array(4)
        .fill(null)
        .map((_, i) => refs[i] || createRef<HTMLInputElement>()),
    );
  }, []);

  const handleDivClick = (index: number) => {
    const currentRef = checkboxRefs[index]?.current;
    if (currentRef) {
      currentRef.checked = !currentRef.checked;
    }
  };

  const fetchConfig = async () => {
    const { storageFeeConfingDto } = fees;
    setFreePeriod(storageFeeConfingDto?.storage_free_period); //무상 보관 기간(월)
    setUnitKrwResponse(storageFeeConfingDto?.storage_fee_unit_krw); //단위당 부과 금액
    setMaxKrwResponse(storageFeeConfingDto?.storage_fee_max_krw); //보관료 부과 최대 금액 제한(원)
    setGoldFreeGram(storageFeeConfingDto?.storage_free_gram_gold); // 무상 보관 중량 금(100 원 / g)
    setSilverFreeGram(storageFeeConfingDto?.storage_free_gram_silver / 1000); // 무상 보관 중량 은(100 원 / g)
    setGoldFeeUnit(storageFeeConfingDto.storage_fee_unit_gold); //보관료 부과 단위 중량 금(1g/100g 미만 절사)
    setSilverFeeUnit(storageFeeConfingDto.storage_fee_unit_silver); //보관료 부과 단위 중량 은(1g/100g 미만 절사)
  };

  const goldFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "GOLD",
  ) || {
    assetType: "GOLD",
    feeGram: 0,
    feeKrw: 0,
  };
  const silverFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "SILVER",
  ) || {
    assetType: "SILVER",
    feeGram: 0,
    feeKrw: 0,
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <div>
      <div className="shadow88 gap mb24">
        <div className="stt_price">
          <ul>
            <li>
              <p>총 보관료</p>
            </li>
            <li>
              <h3>{numFormat(goldFee?.feeKrw + silverFee?.feeKrw)}원</h3>
            </li>
          </ul>
        </div>
        <div className="sg_price asset_price">
          <h3>금</h3>
          <ul>
            <li>・보관료</li>
            <li>
              <span>{goldFee?.feeKrw ? numFormat(goldFee?.feeKrw) : 0}</span>원
            </li>
          </ul>
          <ul>
            <li>・유료 보관 중량</li>
            <li>
              <span>{goldFee?.feeGram ? numFormat(goldFee?.feeGram) : 0}</span>g
            </li>
          </ul>
        </div>
        <div className="stroke-line"></div>
        <div className="ss_price asset_price">
          <h3>은</h3>
          <ul>
            <li>・보관료</li>
            <li>
              <span>
                {silverFee?.feeKrw ? numFormat(silverFee?.feeKrw) : 0}
              </span>
              원
            </li>
          </ul>
          <ul>
            <li>・유료 보관 중량</li>
            <li>
              <span>
                {silverFee?.feeGram ? numFormat(silverFee?.feeGram) : 0}
              </span>
              g
            </li>
          </ul>
        </div>
      </div>
      <div className="sub_wrap09_info">
        <h3 className="info_tit">
          <img src={IcoInfo24} />
          보관료 관련 안내사항
        </h3>
        <div className="sub_wrap09_noti">
          <div className="accordion">
            <ul className="aco_ul">
              <li>
                <div
                  className="btn_cx_down"
                  onClick={() => handleDivClick(0)}
                ></div>
                <input
                  type="checkbox"
                  id="checkbox-1"
                  name="checkbox-accordion"
                  ref={checkboxRefs[0]}
                />
                <label htmlFor="checkbox-1">보관료 개요</label>
                <div className="content">
                  <div
                    className="btn_cx_up"
                    onClick={() => handleDivClick(0)}
                  ></div>
                  <ul>
                    <li>
                      <p>
                        고객님들의 보유 자산에 대한 보관 부대비용 증가 및 안전
                        보안상의 이유로 불가피하게{" "}
                        <b>24년 2월 1일부터 보관료 정책을 시행</b>합니다.
                      </p>
                    </li>
                    <li>
                      <p>
                        금방금방 보관료는 고객님의 자산을 안전하게 보관하기 위한
                        최소한의 비용만 부과하기 위해 산정되었습니다.많은 이해와
                        양해 부탁드립니다.
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="aco_ul">
              <li>
                <div
                  className="btn_cx_down"
                  onClick={() => handleDivClick(1)}
                ></div>
                <input
                  type="checkbox"
                  id="checkbox-2"
                  name="checkbox-accordion"
                  ref={checkboxRefs[1]}
                />
                <label htmlFor="checkbox-2">무료 보관 기간</label>
                <div className="content">
                  <div
                    className="btn_cx_up"
                    onClick={() => handleDivClick(1)}
                  ></div>
                  <ul>
                    <li>
                      <p>
                        <b>
                          자산 생성일의 익월부터 {freePeriod}개월 무료 보관기간
                        </b>
                        으로 서비스가 제공됩니다.
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="aco_ul">
              <li>
                <div
                  className="btn_cx_down"
                  onClick={() => handleDivClick(2)}
                ></div>
                <input
                  type="checkbox"
                  id="checkbox-3"
                  name="checkbox-accordion"
                  ref={checkboxRefs[2]}
                />
                <label htmlFor="checkbox-3">보관료 기준</label>
                <div className="content">
                  <div
                    className="btn_cx_up"
                    onClick={() => handleDivClick(2)}
                  ></div>
                  <h3>금∙은 보관료</h3>
                  <div className="sub09_content">
                    <div className="sub09_table">
                      <div className="name_tb">
                        <ul>
                          <li className="tb_header">자산</li>
                          <li>금</li>
                          <li>은</li>
                        </ul>
                      </div>
                      <div className="num_tb">
                        <ul>
                          <li className="tb_header">기준</li>
                          <li>
                            {numFormat(Number(goldFreeGram))}g 이상부터{" "}
                            {numFormat(Number(goldFeeUnit))}g당{" "}
                            {numFormat(Number(unitKrwResponse))}원
                          </li>
                          <li>
                            {numFormat(Number(silverFreeGram))}kg 이상부터{" "}
                            {numFormat(Number(silverFeeUnit))}g당{" "}
                            {numFormat(Number(unitKrwResponse))}원
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <p>
                        금 {numFormat(Number(goldFeeUnit))}g / 은{" "}
                        {numFormat(Number(silverFeeUnit))}g 미만의 중량은
                        절사합니다.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          월마다 부과되며, 월 최대 수수료는 ‘금’은{" "}
                          {numFormat(Number(maxKrwResponse))}원 ’은’은{" "}
                          {numFormat(Number(maxKrwResponse))}
                          원입니다.
                        </b>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="aco_ul bb_none">
              <li>
                <div
                  className="btn_cx_down"
                  onClick={() => handleDivClick(3)}
                ></div>
                <input
                  type="checkbox"
                  id="checkbox-4"
                  name="checkbox-accordion"
                  ref={checkboxRefs[3]}
                />
                <label htmlFor="checkbox-4">보관료 납부</label>
                <div className="content">
                  <div
                    className="btn_cx_up"
                    onClick={() => handleDivClick(3)}
                  ></div>
                  <ul>
                    <li>
                      <p>
                        <b>
                          무료 보관기간이 끝난 자산에 대하여 익월 1일 0시에
                          보관료가 계산됩니다.
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        출금, 실물인출 시 보관료 납부 팝업이 표시되며 동의 시
                        고객님의 KRW 자산에서 수취됩니다.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          {
                            "[메뉴 > 보관료 > 미납 보관료] 에서 납부 가능합니다."
                          }
                        </b>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageWeight;
