import React from "react";

import iconCheckBlue from "@assets/icons/img_check_blue.png";
import imgProgress from "@assets/images/img_progress.png";
// 진행 전 이미지
import imgProgress01 from "@assets/images/img_progress_01.png";
// 접수
import imgProgress02 from "@assets/images/img_progress_02.png";
// 배송중
import imgProgress03 from "@assets/images/img_progress_03.png";
// 감정중
import imgProgress04 from "@assets/images/img_progress_04.png";
// 감정완료
import imgProgress05 from "@assets/images/img_progress_05.png";
// 교환완료
import imgProgressFin from "@assets/images/img_progress_fin.png";

// 완료 이미지

type Status = "접수" | "감정중" | "감정완료" | "교환완료";
type DeliveryStatus = "접수" | "배송중" | "감정중" | "감정완료" | "교환완료";

interface ProgressAreaProps {
  status: string;
  requestMethod: string;
}

const statusOrder: Status[] = ["접수", "감정중", "감정완료", "교환완료"];
const deliveryStatusOrder: DeliveryStatus[] = [
  "접수",
  "배송중",
  "감정중",
  "감정완료",
  "교환완료",
];

const statusImages: Record<Status | DeliveryStatus, string> = {
  접수: imgProgress01,
  배송중: imgProgress02,
  감정중: imgProgress03,
  감정완료: imgProgress04,
  교환완료: imgProgress05,
};

const ProgressArea: React.FC<ProgressAreaProps> = ({
  status,
  requestMethod,
}) => {
  const order =
    requestMethod === "SIMPLE_DELIVERY" ? deliveryStatusOrder : statusOrder;
  const currentStatusIndex = order.indexOf(
    status
      .replace("감정시작", "감정중")
      .replace("승인대기", "감정중")
      .replace("반송신청", "감정완료")
      .replace("반송대기", "교환완료") as Status | DeliveryStatus,
  );

  const getImage = (step: Status | DeliveryStatus, stepIndex: number) => {
    if (stepIndex < currentStatusIndex) {
      return imgProgressFin;
    }
    if (stepIndex === currentStatusIndex) {
      return statusImages[step];
    }
    return imgProgress;
  };
  return (
    <div className="shadow88 mb16 progress_wrap">
      <h3 className="shadow88_tit">
        <img src={iconCheckBlue} />
        진행 상황
      </h3>
      <div className="progress_box">
        <ul>
          {order.map((step, index) => {
            return (
              <li
                key={step}
                className={
                  index === order.length - 1
                    ? "none"
                    : index === currentStatusIndex
                      ? "on"
                      : index < currentStatusIndex
                        ? "finish"
                        : "off"
                }
              >
                <div>
                  <img src={getImage(step, index)} alt={step} />
                </div>
                <p>{step}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProgressArea;
