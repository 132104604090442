import iconX from "@/assets/icons/icon_x.png";
import guide1 from "@/assets/images/bottomsheet01.png";
import guide2 from "@/assets/images/bottomsheet02.png";
import guide3 from "@/assets/images/bottomsheet03.png";
import { closeBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const steps = [
  {
    image: guide1,
    text: (
      <p>
        은행앱에서 <b className="fc-bl03">가상계좌번호</b>로<br />
        부족한 금액 입금하고
      </p>
    ),
    nextText: "다음",
  },
  {
    image: guide2,
    text: (
      <p>
        <b className="fc-bl03">금방금방앱</b>으로 다시 돌아와서
      </p>
    ),
    prevText: "이전",
    nextText: "다음",
  },
  {
    image: guide3,
    text: (
      <p>
        금방금방앱에서 <b className="fc-bl03">결제하기 버튼</b>을 눌러
        <br /> 결제완료하기
      </p>
    ),
    prevText: "이전",
    nextText: "이해했습니다.",
  },
];

const PurchaseGuide = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const onClose = () => {
    dispatch(closeBottomSheet());
    setStep(0);
  };

  return (
    <div className="sub_bottom02 category_tab_new">
      <div className="sub_bottom02_wrap">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">가상 계좌 입금 및 결제방법</h3>
          <img src={iconX} alt="닫기" className="close" onClick={onClose} />
        </div>
        <div>
          <img src={steps[step].image} />
        </div>
        <div className="bt_text">{steps[step].text}</div>
        <div className="sub_bottom_btn">
          {steps[step].prevText && (
            <button className="bf_btn" onClick={handlePrev}>
              {steps[step].prevText}
            </button>
          )}
          <button
            className="nt_btn"
            onClick={handleNext}
            style={{ width: `${step === 0 ? "100%" : "50%"}` }}
          >
            {steps[step].nextText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseGuide;
