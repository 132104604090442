import { validatePhone } from "./utils";

/** 수령 및 배송 정보 유효성 검사 */
export const validateWithdrawalInfo = (isDelivery: boolean, info: any) => {
  const checks = isDelivery
    ? [
        { condition: !info?.receiver, message: "수령인을 입력해주세요" },
        { condition: !info?.phone, message: "연락처1을 입력해주세요" },
        {
          condition: !validatePhone(info.phone),
          message: "연락처1을 정확히 입력해주세요",
        },
        {
          condition: !info?.postCode,
          message: "우편번호를 입력해주세요",
        },
        { condition: !info?.address, message: "주소를 입력해주세요" },
        {
          condition: !info?.addressDetail,
          message: "상세 주소를 입력해주세요",
        },
        {
          condition:
            info?.addressDetail.trim().length !== info?.addressDetail.length,
          message: "상세 주소의 공백을 지워주세요",
        },
      ]
    : [
        {
          condition: info?.shopId === 0,
          message: "방문할 대리점을 선택해주세요",
        },
        {
          condition: info?.visitDate === null || info?.visitDate === "",
          message: "희망 방문 일자를 선택해주세요",
        },
      ];

  for (let i = 0; i < checks.length; i++) {
    if (checks[i].condition) {
      return { isValid: false, message: checks[i].message };
    }
  }
  return { isValid: true, message: "" };
};
