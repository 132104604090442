import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import * as APIS from "@services/apis";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconLeft from "@assets/icons/icon_left.png";
import ImgSafe from "@assets/images/img_safe.png";
import ImgSafeDisable from "@assets/images/img_safe_disable.png";

import StorageWeight from "./components/StorageWeight";
import UnpaidStorageFee from "./components/UnpaidStorageFee";

interface FeeItem {
  assetType: string;
  feeGram: number;
  feeKrw: number;
}

interface Fees {
  totalFeeKrw: number;
  storageFeeMonthDtoList: any[];
  beforeStorageFeeList: FeeItem[];
  storageFeeConfingDto: {};
}

const StorageFee = () => {
  const [usingTab, setUsingTab] = useState("weight");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  const todayMonth = today.getMonth() + 2;
  const [fees, setFees] = useState<Fees>({
    totalFeeKrw: 0,
    storageFeeMonthDtoList: [],
    beforeStorageFeeList: [],
    storageFeeConfingDto: {},
  });
  const goldFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "GOLD",
  ) || {
    assetType: "GOLD",
    feeGram: 0,
    feeKrw: 0,
  };
  const silverFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "SILVER",
  ) || {
    assetType: "SILVER",
    feeGram: 0,
    feeKrw: 0,
  };

  const handleClickTab = (tabName: string) => {
    setUsingTab(tabName);
  };

  const init = () => {
    let todayYear = today.getFullYear();
    dispatch(showLoading());
    APIS.getStorageFee()
      .then(({ data: { success, data } }) => {
        if (success) {
          let yearList = [
            {
              year: todayYear,
              data: data?.storageFeeMonthDtoList.filter((item: any) => {
                if (new Date(item.feeChargedDate).getFullYear() === todayYear)
                  return item;
              }),
            },
          ];
          const differentYearItems = data?.storageFeeMonthDtoList.filter(
            (item: any) =>
              new Date(item.feeChargedDate).getFullYear() !== todayYear,
          );
          if (differentYearItems && differentYearItems.length > 0) {
            const years = Array.from(
              new Set<number>(
                differentYearItems.map((item: any) =>
                  new Date(item.feeChargedDate).getFullYear(),
                ),
              ),
            );
            years.forEach((year: number) => {
              let obj = {
                year: year,
                data: data?.storageFeeMonthDtoList.filter(
                  (item: any) =>
                    new Date(item.feeChargedDate).getFullYear() === year,
                ),
              };

              if (todayYear > year) {
                yearList.push(obj);
              } else {
                yearList.unshift(obj);
              }
            });
          }
          setFees({
            ...data,
            yearList,
          });
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className="sub_top03 storage">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>보관료</p>
        </div>
      </div>
      <div className="sub_wrap09">
        <div className="sub09_tap">
          <ul>
            <li
              className={usingTab === "weight" ? "on" : ""}
              onClick={() => handleClickTab("weight")}
            >
              <p>유료 보관 중량</p>
            </li>
            <li className="bar"></li>
            <li
              className={usingTab === "unPaidFee" ? "on" : ""}
              onClick={() => handleClickTab("unPaidFee")}
            >
              <p>미납 보관료</p>
            </li>
          </ul>
        </div>
        {usingTab === "weight" ? (
          goldFee?.feeKrw === 0 && silverFee.feeKrw === 0 ? (
            <div className="sub_wrap09_cont">
              <div className="storage_box none">
                <img src={ImgSafeDisable} />
                <h3 className="none_txt">
                  {todayMonth}월 예정 유료 보관 중량이 없습니다.
                </h3>
              </div>
            </div>
          ) : (
            <div className="sub_wrap09_cont">
              <div className="shadow88 storage_box">
                <img src={ImgSafe} />
                <h3 className="month">{todayMonth}월</h3>
                <h3 className="storage_txt">
                  <span>예정</span>유료 보관 중량
                </h3>
                <p className="storage_noti">매일 0시에 갱신됩니다.</p>
              </div>
              <StorageWeight fees={fees} />
            </div>
          )
        ) : fees?.totalFeeKrw === 0 ? (
          <div className="sub_wrap09_cont">
            <div className="storage_box none">
              <img src={ImgSafeDisable} />
              <h3 className="none_txt">납부하실 보관료가 없습니다.</h3>
            </div>
          </div>
        ) : (
          <div className="sub_wrap09_cont">
            <div className="shadow88 storage_box">
              <img src={ImgSafe} />
              <h3 className="month">{todayMonth}월</h3>
              <h3 className="storage_txt">
                <span>예정</span>유료 보관 중량
              </h3>
              <p className="storage_noti">매일 0시에 갱신됩니다.</p>
            </div>
            <UnpaidStorageFee fees={fees} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageFee;
