import { showToast } from "@features/toast/toastSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import iconLocation from "@assets/icons/ico_location_24px.png";
import iconPhone from "@assets/icons/ico_phone_24px.png";
import iconLeft from "@assets/icons/icon_left.png";
import colors from "@assets/styles/colors.css";

import useShopDetail from "./hooks/useShopDetail";
import {
  descWrap,
  iconImg,
  infoWrap,
  shopDesc,
  shopDescPhone,
  shopDetailContainer,
  shopImg,
  shopName,
  shopTitleWrap,
  subTitle,
} from "./styles.css";

const ShopDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { shopId } = params;
  const { ShopDetail } = useShopDetail(shopId ?? "");
  const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "대리점 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };

  return (
    <div>
      <div className="sub_top04">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>대리점 정보</p>
        </div>
      </div>
      <div className={shopDetailContainer}>
        <div className={shopTitleWrap}>
          <img
            className={shopImg}
            src={`${S3_BASE_URL}/${ShopDetail?.imagePath}`}
            alt="대리점 이미지"
          />
          <h3 className={shopName}>{ShopDetail?.shopName}</h3>
        </div>
        <div
          className={infoWrap}
          onClick={() => {
            handleClickPhone(ShopDetail?.phone ?? "");
          }}
        >
          <img className={iconImg} src={iconPhone} alt="전화" />
          <div className={descWrap}>
            <h4 className={subTitle}>전화</h4>
            <div className={shopDescPhone}>{ShopDetail?.phone}</div>
          </div>
        </div>
        <div className={infoWrap}>
          <img className={iconImg} src={iconLocation} alt="주소" />
          <div className={descWrap}>
            <h4 className={subTitle}>주소</h4>{" "}
            <div className={shopDesc}>{ShopDetail?.address}</div>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className="sub_wrap02_btn on"
          style={{
            backgroundColor: colors.buttonColor,
          }}
          onClick={handleClickBack}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default ShopDetail;
