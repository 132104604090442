import { showToast } from "@features/toast/toastSlice";
import { numberWithCommas } from "@utils/utils";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconLeft from "@assets/icons/icon_left.png";
import ImgCaution from "@assets/images/img_caution.png";
import ImgDeposit from "@assets/images/img_deposit.webp";
import ImgMyWon from "@assets/images/img_my_won_24px01.png";
import TooltipDeposit from "@assets/images/tooltip_deposit.png";
import colors from "@assets/styles/colors.css";

import useAcceptableAssets from "./hooks/useAcceptableAssets";
import useAccountInfo from "./hooks/useAccountInfo";
import useDepositConfig from "./hooks/useDepositEnvConfig";
import * as styles from "./styles.css";

const Deposit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AcceptableAssets } = useAcceptableAssets();
  const { AccountInfo } = useAccountInfo("DEPOSIT");
  const { config: DepositConfig } = useDepositConfig();

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleCopyToClipboard = async () => {
    if (AccountInfo?.virtualBankNumber) {
      try {
        await navigator.clipboard.writeText(AccountInfo.virtualBankNumber);
        dispatch(
          showToast({
            message: "계좌번호가 클립보드에 복사되었습니다.",
            icon: "success",
          }),
        );
      } catch (error) {
        dispatch(
          showToast({
            message: "클립보드 복사에 실패했습니다.",
            icon: "error",
          }),
        );
      }
    } else {
      dispatch(
        showToast({ message: "복사할 계좌번호가 없습니다.", icon: "caution" }),
      );
    }
  };

  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>입금하기</p>
        </div>
      </div>
      <div className="bank01_top">
        <h3>
          지정한 입금 계좌에서
          <br />
          금방금방 가상 계좌로 입금해 주세요.
        </h3>
        <img src={ImgDeposit} alt="" className="bank_mimg" />
      </div>
      <div className="bank_wrap01">
        <div className="bank_box01 mb08 arrow">
          <p>내가 지정한 입금계좌</p>
          <ul>
            <li>
              <p>{AccountInfo?.bankName}</p>
            </li>
            <li>
              <h3>{AccountInfo?.bankAccountNumber}</h3>
            </li>
          </ul>
        </div>
        <div className="bank_box02">
          <ul className="bank_box02_ul01 mb08">
            <li>
              <h3 className="fc-bl03">금방금방 가상계좌</h3>
            </li>
            <li>
              <p onClick={handleCopyToClipboard}>계좌번호 복사</p>
            </li>
          </ul>
          <ul className="bank_box02_ul02 mb16">
            <li>
              <p>
                <span>KEB 하나은행</span> (구 외환은행)
              </p>
            </li>
            <li>
              <h3>{AccountInfo?.virtualBankNumber}</h3>
            </li>
          </ul>
          <div className="bank_accordion bank_vertical">
            <ul>
              <li>
                <input
                  type="checkbox"
                  id="bank_aco"
                  name="checkbox-accordion"
                />
                <label htmlFor="bank_aco">
                  <h3>나의 보유 자산</h3>
                  <div className="btn_cx_down"></div>
                  <div className="btn_cx_up"></div>
                </label>
                <div className="bank_content">
                  <ul>
                    <li>
                      <p>보유 원화</p>
                    </li>
                    <li>
                      <p>
                        <span>{numberWithCommas(AcceptableAssets?.krw)}</span>원
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>거래 대기</p>
                    </li>
                    <li>
                      <p>
                        <span>
                          {numberWithCommas(
                            (AcceptableAssets?.krw || 0) -
                              (AcceptableAssets?.acceptableKrw || 0),
                          )}
                        </span>
                        원
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>사용 가능 원화</p>
                    </li>
                    <li>
                      <p>
                        <span>
                          {numberWithCommas(AcceptableAssets?.acceptableKrw)}
                        </span>
                        원
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>{" "}
          </div>
        </div>
        <div className={styles.TooltipWrap}>
          <img src={TooltipDeposit} alt="" className={styles.Tooltip} />
        </div>
        <div className="bank_noti bank_noti01 mb24">
          <h3>
            <img src={ImgCaution} />
            금방금방 가상 계좌로 입금 시 유의사항
          </h3>
          <ul>
            <li>
              <p>본 가상 계좌는 고객님 전용 계좌입니다.</p>
            </li>
            <li>
              <p>
                <span>입금한 금액</span>은 거래여부와 관계없이{" "}
                <span>{DepositConfig?.withdrawTimeLimit}시간 후 출금</span>{" "}
                가능합니다.
              </p>
            </li>
            <li>
              <p>
                <span>최소 입금 금액은 {DepositConfig?.minKrw}원</span>입니다.
              </p>
            </li>
            <li>
              <p>
                최소 입금 금액 미만의 금액을 입금 시도하시면 은행마다 다른 에러
                메시지가 뜨며, 입금이 제한됩니다.
              </p>
            </li>
            <li>
              <p>
                지정한 입금계좌에서만 입금이 가능하며, 타 계좌에서 금방금 방
                가상계좌로 입금은 제한됩니다.
              </p>
            </li>
            <li>
              <p>
                <span>오픈뱅킹 서비스를 통한 입금은 제한</span>됩니다.
              </p>
            </li>
          </ul>
        </div>
        <div className="bank_noti bank_noti02">
          <h3>
            <img src={ImgMyWon} />
            입금 한도
          </h3>
          <div className="bank_tb">
            <ul className="bank_tb1">
              <li className="bank_tb_top">
                <h3>1회 최소</h3>
              </li>
              <li className="bank_tb_cont">
                <span>최소</span>
                <p>
                  <span>{numberWithCommas(DepositConfig?.minKrw)}원</span>
                </p>
              </li>
            </ul>
            <ul className="bank_tb2">
              <li className="bank_tb_top">
                <h3>1일 최대 한도</h3>
              </li>
              <li className="bank_tb_cont">
                <span>1일 최대</span>
                <p>
                  <span>{numberWithCommas(DepositConfig?.maxKrw)}원</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className="sub_wrap02_btn on"
          onClick={handleCopyToClipboard}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          금방금방 가상계좌번호 복사
        </button>
      </div>
    </div>
  );
};

export default Deposit;
