import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import * as APIS from "@services/apis";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useShoppingCancel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutationFn = async (transId: string): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.deleteGoodsBuy(transId);
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(
          showToast({ message: "주문이 취소되었습니다.", icon: "success" }),
        );
        navigate(-1);
        return response.data.data;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(
    [routes.shoppingDetail, "/cancel"],
    mutationFn,
  );

  return mutation;
};

export default useShoppingCancel;
