import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

interface AssetProps {
  acceptableGold: number;
  acceptableKrw: number;
  acceptablePoint: number;
  acceptableSilver: number;
  gold: number;
  krw: number;
  name: string;
  point: number;
  silver: number;
  storageFee: number;
  withdrawKrw: number;
}

const useUserAssets = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getUserHoldingsInfo();
      dispatch(hideLoading());
      return response.data.data as AssetProps;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<AssetProps>(
    [routes.buyGoodsPurchase, "/user/asset"],
    fetchDetails,
  );

  return {
    UserAssets: data,
    isLoading,
    error,
    UserAssetsRefetch: refetch,
  };
};
export default useUserAssets;
