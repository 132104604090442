import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import imgClose from "../../assets/icons/login-close.png";
import imgLogo from "../../assets/images/login-logo.png";
import imgNaver from "../../assets/images/login-naver.png";
import imgKaKao from "../../assets/images/login-kakaotalk.png";
import imgGoogle from "../../assets/images/login-google.png";
import imgApple from "../../assets/images/login-apple.png";
import { routes } from "../../routes";
import { getOauthUrls } from "../../services/apis";
import { postMessage } from "../../utils/utils";
import {
  root,
  close,
  top,
  bottom,
  label,
  sns,
  social,
  socialIcon,
  socialLabel,
  flex,
  imgButton,
  imgButtonImg,
} from "./style.css";
import colors from "../../assets/styles/color";
import { hideLoading, showLoading } from "../../features/loading/loadingSlice";

type AuthUrlsType = "NAVER" | "KAKAO" | "GOOGLE" | "APPLE";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authUrls, setAuthUrls] = useState<
    Record<AuthUrlsType, string> | undefined
  >();
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(showLoading());
    getOauthUrls()
      .then(({ data }) => {
        setAuthUrls(data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  const handleClickSingIn = (type: AuthUrlsType) => {
    return () => {
      if (authUrls && authUrls[type]) {
        window.location.href = authUrls[type];
        postMessage({
          command: "WINDOW_OPEN",
          param: { visible: true, title: type },
        });
      }
    };
  };
  const handleClickClose = () => {
    navigate(-1);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div className={`${root} ${flex}`}>
        <button onClick={handleClickClose} className={`${close} ${imgButton}`}>
          <img src={imgClose} className={imgButtonImg} />
        </button>
        <div className={`${top} ${flex}`}>
          <span className={label}>모바일 금은방</span>
          <img
            src={imgLogo}
            style={{ width: "50vw", height: "44px", objectFit: "contain" }}
          />
        </div>
        <div className={`${bottom} ${flex}`}>
          <span className={sns}>
            SNS간편로그인으로 금방금방 서비스를 이용하세요.
          </span>
          <button
            onClick={handleClickSingIn("NAVER")}
            style={{ backgroundColor: "#21c603" }}
            className={social}
          >
            <img src={imgNaver} className={socialIcon} />
            <span className={socialLabel} style={{ color: "#fff" }}>
              네이버로 시작하기
            </span>
          </button>
          <button
            onClick={handleClickSingIn("KAKAO")}
            style={{ backgroundColor: "#f9e000" }}
            className={social}
          >
            <img src={imgKaKao} className={socialIcon} />
            <span className={socialLabel} style={{ color: "#3b1c1c" }}>
              카카오톡으로 시작하기
            </span>
          </button>

          <button
            onClick={handleClickSingIn("GOOGLE")}
            style={{ backgroundColor: "#fff" }}
            className={social}
          >
            <img src={imgGoogle} className={socialIcon} />
            <span className={socialLabel} style={{ color: "#000" }}>
              구글로 시작하기
            </span>
          </button>

          <button
            onClick={handleClickSingIn("APPLE")}
            style={{ backgroundColor: "#000000" }}
            className={social}
          >
            <img src={imgApple} className={socialIcon} />
            <span className={socialLabel} style={{ color: "#fff" }}>
              Apple로 시작하기
            </span>
          </button>
          <button
            onClick={() => {
              navigate(routes.signInId, { replace: true });
            }}
            style={{ backgroundColor: colors.primary }}
            className={social}
          >
            <div className={`${socialIcon} ${flex}`} />
            <span className={socialLabel} style={{ color: "#fff" }}>
              아이디로 시작하기
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
