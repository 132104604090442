import useCustomQuery from "@hooks/queries/useCustomQuery";
import { getBanner } from "@services/apis";
import { objToQueryString } from "@utils/utils";

import banner_default from "@assets/images/banner_default.webp";

export interface BannerItems {
  noticeId: number;
  subject: string;
  content: string;
  category: "NOTICE" | "EVENT";
  type: string;
  path: string;
  popupPeriod: number | null;
}

const useBanners = () => {
  const fetchBanners = async () => {
    try {
      const params = { type: "BANNER" };
      const response = await getBanner(objToQueryString(params));

      return response.data.data as BannerItems[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return useCustomQuery<BannerItems[]>(["home", "banners"], fetchBanners, {
    queryKey: [], // 타입이 일그러진듯
    select: (data) => {
      const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;
      if (data.length === 0) {
        return [
          {
            noticeId: 24,
            subject: "",
            content: "",
            category: "" as any, // banner없을 때의 타입 체크 pass를 위해서 삽입
            type: "",
            popupPeriod: null,
            path: banner_default,
          },
        ];
      }
      return data.map((item) => ({
        ...item,
        path: `${S3_BASE_URL}${item.path}`,
      }));
    },
  });
};

export default useBanners;
