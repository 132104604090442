import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";

interface PendingCheckParams<T> {
  count?: number; // 초
  maxRetries?: number; // 시도 횟수
  interval?: number; // 반복 주기
  checkFn: () => Promise<{
    // 확인 요청 API 호출
    data: { success: boolean; data: T; message: string };
  }>;
  successCondition: (data: T) => boolean; // 성공 상태
  processingCondition?: (data: T) => boolean; // 진행중 상태
  successMessage?: string; // 성공 시 메시지
  processingMessage?: string; // 진행 중 메시지
  failureMessage?: string; // 실패 메시지
  navigateOnSuccess?: (data: T) => void; // 성공 후 동작
  navigateOnProcessing?: () => void; // 진행중일 시 동작
  navigateOnFailure?: (data?: T | string) => void; // 실패 시 동작
}

const usePendingCheck = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const pendingCheck = <T>(params: PendingCheckParams<T>) => {
    const {
      count = 1,
      maxRetries = 10,
      interval = 1000,
      checkFn,
      successCondition,
      successMessage = "처리가 완료되었습니다.",
      processingCondition,
      processingMessage = "처리 중 입니다.",
      failureMessage = "처리에 실패하였습니다.",
      navigateOnSuccess,
      navigateOnProcessing,
      navigateOnFailure,
    } = params;

    dispatch(showLoading());

    setTimeout(() => {
      checkFn()
        .then(({ data: { success, data, message } }) => {
          if (success) {
            // 성공 상태일 때,
            if (successCondition(data)) {
              dispatch(showToast({ message: successMessage, icon: "success" }));
              if (navigateOnSuccess) navigateOnSuccess(data);
              dispatch(hideLoading());
            } else if (
              (processingCondition && processingCondition(data)) ||
              !processingCondition
            ) {
              // 진행 상태가 없거나 진행 상태가 있을 때 통과했을 때
              if (count >= maxRetries) {
                dispatch(
                  showToast({ message: processingMessage, icon: "caution" }),
                );
                if (navigateOnProcessing) navigateOnProcessing();
                dispatch(hideLoading());
              } else {
                // maxRetries 안 지났으면 재시도
                pendingCheck({ ...params, count: count + 1 });
              }
            } else {
              // 실패나 취소 상태일 때
              dispatch(
                showToast({
                  message: message || failureMessage,
                  icon: "error",
                }),
              );
              dispatch(hideLoading());
              if (navigateOnFailure) navigateOnFailure(message);
            }
          } else {
            // success: false
            dispatch(
              showToast({ message: message || failureMessage, icon: "error" }),
            );
            dispatch(hideLoading());
            if (navigateOnFailure) navigateOnFailure(message);
          }
        })
        .catch(() => {
          // API 호출 실패
          dispatch(showToast({ message: failureMessage, icon: "error" }));
          dispatch(hideLoading());
          if (navigateOnFailure) navigateOnFailure();
        });
    }, interval);
  };

  return pendingCheck;
};

export default usePendingCheck;
