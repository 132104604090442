import { RootState } from "@app/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import * as APIS from "../../services/apis";

// import axios from "axios";
// import consts from "../../utils/constants";
// import { postRequest } from "../../network";
// import { showToast } from "../toast/toastSlice";
// import { useNavigate } from "react-router-dom";
// import { routes } from "@/routes";

//본인인증 안된 회원 정보
// init: true;

//본인인증 된 회원 정보
// init: false;

interface UserState {
  init: boolean;
  id?: number;
  createdAt?: string;
  name?: string;
  sns?: string;
  age?: number;
  gender?: number;
  email?: string;
  phone?: string;
  bankName?: string;
  bankAccountNumber?: string;
  virtualAccountName?: string;
  virtualAccountAccountNumber?: string;
  postCode?: string;
  address?: string;
  addressDetail?: string;
  status?: string;
  recommendShopName?: string;
  recommendShopId?: number;
  gold?: number;
  silver?: number;
  krw?: number;
  point?: number;
  signed?: boolean;
  usingShopId?: number;
  usingShopName?: string;
}

const initialState: UserState = {
  init: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initUser: (state) => {
      state.init = true;
    },
    updateUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
  },
});

// export const userSignIn = () => async (dispatch: any) => {
//   try {
//     const { token } = await postRequest(
//       consts.apiBaseUrl + "/users/signin",
//       {},
//     );
//     localStorage.setItem(consts.token, token);
//     dispatch(userInit);
//   } catch (e: any) {
//     alert(e.message || e);
//   }
// };

// export const userSignOut = async (dispatch: any) => {
//   localStorage.removeItem(consts.accessToken);
//   dispatch(initUser());
// };

// export const userInit = async (dispatch: any) => {
//   const navigate = useNavigate();
//   const token = localStorage.getItem(consts.accessToken);
//   if (token) {
//     try {
//       const res = await axios.get(
//         consts.apiBaseUrl + "/api/authenticate?token=" + token,
//       );
//       const data = res.data;
//       if (data.type === "ADMIN") {
//         localStorage.removeItem(consts.accessToken);
//         dispatch(
//           showToast({
//             message: "관리자는 로그인 불가능합니다.",
//             icon: "error",
//           }),
//         );

//         return;
//       }
//       dispatch(updateUser({ ...data, signed: true }));
//     } catch (e) {
//       localStorage.removeItem(consts.accessToken);
//       dispatch(
//         showToast({
//           message: "토큰 만료되었습니다. 다시 로그인 해주세요.",
//           icon: "error",
//         }),
//       );
//       navigate(routes.login, { replace: true });
//     }
//   }
// };

export const userRefresh = (dispatch: any) => {
  APIS.getUserInfo().then(({ data: { data, success } }) => {
    if (success) {
      dispatch(updateUser(data));
    } else {
      dispatch(initUser());
    }
  });
};

export const { initUser, updateUser } = userSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default userSlice.reducer;
