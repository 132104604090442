import { ButtonBase } from "@mui/material";
import { useEffect, useState } from "react";

import serviceError from "@assets/images/img_service_error.png";
import logoFaded from "@assets/images/logo_faded.png";

import * as style from "./style.css";

const ServiceError = () => {
  const [isBackward, setIsBackWard] = useState(false);

  const handleClick = () => {
    if (isBackward) {
      history.back();
    } else {
      document.location.reload();
    }
  };

  useEffect(() => {
    if (location.href.includes("reload")) {
      setIsBackWard(true);
    }
  }, []);

  return (
    <div className={style.pagePadding}>
      <div className={style.logo.section}>
        <img src={logoFaded} className={style.logo.image} />
      </div>
      <div className={style.error.section}>
        <img src={serviceError} className={style.error.image} />
        <div className={style.error.boldText}>서비스가 원할하지 않습니다.</div>
        <div className={style.error.lightText}>
          잠시 후에 다시 시도해주세요.
        </div>
      </div>
      <div className="sub_btn_wrap">
        <ButtonBase
          style={{
            display: "flex",
            width: "100%",
            height: "48px",
            padding: "0px 24px",
            borderRadius: "8px",
            border: "1px solid var(--kbkb_blue_01-400_Primary, #0CF)",
            marginBottom: 40,
            color: "var(--kbkb_blue_01-500_font, #00BAFF)",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "normal",
          }}
          onClick={() => handleClick()}
        >
          다시 시도
        </ButtonBase>
      </div>
    </div>
  );
};

export default ServiceError;
