import { initFeeRate } from "@/utils/initFeeRate";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import iconCau from "@assets/icons/ico_56px_cau.png";
import colors from "@assets/styles/colors.css";
import { closeModal, openModal } from "@features/modal/modalSlice";
import { formatTime } from "@utils/utils";

import BottomPopup from "./components/BottomPopup";
import Header from "./components/Header";
import MarketPriceSection from "./components/MarketPriceSection";
import Menu from "./components/Menu";

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state || {};

  useEffect(() => {
    initFeeRate(dispatch);

    if (state.errorType === "CANNOT_RESIGNUP") {
      const { leaveDate, reSignupDate } = state;
      dispatch(
        openModal(
          <div className="bg_gr_wrap">
            <div className="modal_wrap03 btn1">
              <img src={iconCau} alt="" />
              <h3>재가입 불가</h3>
              <p>
                ※
                <span className="fc-bl03">
                  {formatTime(leaveDate, "YYYY.MM.DD")}
                </span>
                탈퇴하셨습니다.
                <br />※
                <span className="fc-bl03">
                  {formatTime(reSignupDate, "YYYY.MM.DD")}
                </span>
                부터 재가입 가능합니다.
              </p>
              <div className="modal_btn">
                <button
                  className="bg_bk_btn"
                  style={{
                    backgroundColor: colors.buttonColor,
                  }}
                  onClick={() => {
                    dispatch(closeModal());
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>,
        ),
      );
    }

    // 앱에서 뒤로가기 못하게 제어
    postMessage({ command: "CAN_NOT_GO_BACK" });

    // Flag 서버 호출하여 API 동작 여부 확인
    postMessage({ command: "CALL_FLAG_API" });
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="home_wrap">
        <MarketPriceSection />
        <Menu />
        <BottomPopup />
      </div>
    </>
  );
};

export default Home;
