import { routes } from "@/routes";
import { useDispatch } from "react-redux";

import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import * as APIS from "@services/apis";

export interface IDeliveryInfo {
  postCode: string;
  address: string;
  addressDetail: string;
  addressName: string;
  receiver: string;
  phone: string;
  mobile: string;
  memo: string;
  isDefault: boolean;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useAddressRegister = (
  setActiveTab: (tab: string) => void,
  addressRefetch: () => void,
) => {
  const dispatch = useDispatch();

  const mutationFn = async (deliveryInfo: IDeliveryInfo): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.postRegisterAddress(deliveryInfo);
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(
          showToast({ message: "배송지가 등록되었습니다.", icon: "success" }),
        );
        setActiveTab("list");
        addressRefetch();
        return response.data.data;
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation(
    [routes.deliveryAddressManagement, "/register"],
    mutationFn,
  );

  return mutation;
};

export default useAddressRegister;
