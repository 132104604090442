import { routes } from "@/routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { checkReviewPopUpOpen } from "@components/common/review-bottom-sheet";
import { updateUserConfirmCount } from "@components/common/review-bottom-sheet/const";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { closeModal } from "@features/modal/modalSlice";
import { showToast } from "@features/toast/toastSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import usePendingCheck from "@hooks/usePendingCheck";
import * as APIS from "@services/apis";
import { numberWithCommas } from "@utils/utils";

interface IConfirmParam {
  transId: string;
  isCashReceipt: boolean;
}

interface IConfirmPointResponse {
  success: boolean;
  message: string;
  data: null;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useBuyConfirm = (
  ShoppingDetailRefetch: () => void,
  setIsReviewSheet: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingCheck = usePendingCheck();

  const pendingCompleteBuyConfirm = (transId: string) => {
    pendingCheck<IConfirmPointResponse>({
      checkFn: () => APIS.getBuyConfirm(transId),
      successCondition: (data) => true, // success면 구매 확정, 실패면 실패
      successMessage: "구매 확정되었습니다.",
      processingMessage: "구매 확정 중 입니다.",
      failureMessage: "구매 확정에 실패하였습니다.",
      navigateOnSuccess: () => {
        ShoppingDetailRefetch(); // 성공 시 refetch 호출
        dispatch(closeModal());
        const eventData = {
          transId: transId,
          couponStatus: "PAYMENT",
          eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
        };

        if (checkReviewPopUpOpen()) {
          setIsReviewSheet(true);
        } else {
          updateUserConfirmCount();
        }
        APIS.postBuyEventPoint(eventData).then(
          ({ data: { success, data } }) => {
            if (success) {
              const { amount } = data;
              dispatch(
                showToast({
                  message: `구매 확정 ${numberWithCommas(
                    amount,
                  )}pt가 지급되었습니다.`,
                  icon: "success",
                }),
              );
            }
          },
        );
      },
      navigateOnProcessing: () => {
        dispatch(closeModal());
        navigate(routes.menu);
      },
      navigateOnFailure: () => {
        dispatch(closeModal());
      },
    });
  };

  const mutationFn = async ({
    transId,
    isCashReceipt,
  }: IConfirmParam): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.putBuyConfirm(transId, { isCashReceipt });
      dispatch(hideLoading());
      if (response.data.success) {
        pendingCompleteBuyConfirm(transId);
      } else {
        dispatch(
          showToast({ message: response.data.message, icon: "caution" }),
        );
        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation([routes.shoppingDetail], mutationFn);

  return mutation;
};

export default useBuyConfirm;
