import { postMessage } from "@/utils/utils";
import { SwipeableDrawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import IconX from "@assets/icons/icon_x.png";
import reviewHeart from "@assets/images/review_heart_face.png";
import reviewMagnify from "@assets/images/review_magnify.png";
import reviewStars from "@assets/images/review_stars.png";
import { drawerStyle } from "@components/common/bottom-sheet/style.css";
import { setLocalStorageItem } from "@services/storageService";

import { CONFIRM_COUNT, updateUserConfirmCount } from "./const";
import * as style from "./style.css";

function ReviewCommonLayout(props: { children: React.ReactNode }) {
  return (
    <div className="sub_bottom02 category_tab03">
      <div className="sub_bottom02_wrap" style={{ height: 315 }}>
        {props.children}
      </div>
    </div>
  );
}
function ReviewHeaderLayout(props: {
  headerText: string;
  handleDrawerController: (
    newOpen: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) {
  return (
    <>
      <div className="sub_top01">
        <h3 className={`sub_bottom_tit`} style={{ fontWeight: 700 }}>
          {props.headerText}
        </h3>
        <img
          src={IconX}
          alt="close button"
          className="close"
          style={{ zIndex: 10 }}
          onClick={props.handleDrawerController(false)}
        />
      </div>
    </>
  );
}

interface ReviewContentProps {
  isReviewSheet: boolean;
  handleDrawerController: (
    newOpen: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  headerText: string;
  reviewImageSection: React.ReactNode;
  notificationText: React.ReactNode;
  cancelText: string;
  onCancelClick: (e?: any) => void;
  confirmText: string;
  onConfirmClick: (e?: any) => void;
}

function ReviewContent(props: ReviewContentProps) {
  const {
    isReviewSheet,
    handleDrawerController,
    headerText,
    reviewImageSection,
    notificationText,
    cancelText,
    onCancelClick,
    confirmText,
    onConfirmClick,
  } = props;
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isReviewSheet}
      onClose={handleDrawerController(false)}
      onOpen={handleDrawerController(true)}
      classes={{ paper: drawerStyle }}
    >
      <ReviewCommonLayout>
        <ReviewHeaderLayout
          headerText={headerText}
          handleDrawerController={handleDrawerController}
        />
        <div className={style.notificationSection}>
          {reviewImageSection}
          <div className={style.notificationText}>{notificationText}</div>
        </div>
        <div className={style.buttonSection}>
          <button className={style.grayButton} onClick={onCancelClick}>
            {cancelText}
          </button>
          <button className={style.blueButton} onClick={onConfirmClick}>
            {confirmText}
          </button>
        </div>
      </ReviewCommonLayout>
    </SwipeableDrawer>
  );
}

function detectMobileOS() {
  const isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  if (isApple) return "Apple";
  if (isAndroid) return "Android";
  return "Unknown";
}

const imagePreload = {} as { [key in string]: HTMLImageElement };

interface ReviewBottomSheetProps {
  isReviewSheet: boolean;
  setIsReviewSheet: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReviewBottomSheet = (props: ReviewBottomSheetProps) => {
  const { isReviewSheet, setIsReviewSheet } = props;

  const navigate = useNavigate();
  const [step, setStep] = useState<"hello" | "thanks" | "problem">("hello");

  const handleDrawerController =
    (newOpen: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event === undefined ||
        (event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift"))
      ) {
        updateUserConfirmCount();
        return setIsReviewSheet(false);
      }

      if (newOpen) {
        return setIsReviewSheet(true);
      }
      updateUserConfirmCount();
      return setIsReviewSheet(false);
    };

  const handleOpen = () => {
    const mobileOS = detectMobileOS();
    const APP_STORE =
      "https://apps.apple.com/kr/app/%EA%B8%88%EB%B0%A9%EA%B8%88%EB%B0%A9/id1575200660";
    const PLAY_STORE =
      "https://play.google.com/store/apps/details?id=com.korda.goldmarket";

    if (mobileOS !== "Unknown") {
      setLocalStorageItem(CONFIRM_COUNT, "done");
      handleDrawerController(false)();
      postMessage({
        command: "WINDOW_OPEN",
        param: {
          url: mobileOS === "Android" ? PLAY_STORE : APP_STORE,
        },
      });
    }
  };

  useEffect(() => {
    const imageUrls = {
      reviewHeart,
      reviewMagnify,
      reviewStars,
    } as any;
    Object.keys(imageUrls).forEach((name) => {
      const img = new Image();
      img.src = imageUrls[name];
      img.onload = () => {
        imagePreload[name] = img;
      };
    });
  }, []);

  const Stepper = {
    hello: (
      <ReviewContent
        isReviewSheet={isReviewSheet}
        handleDrawerController={handleDrawerController}
        headerText="안녕하세요!"
        reviewImageSection={
          <div className={style.reviewHeartWrapper}>
            <img alt="리뷰 이미지" src={reviewHeart} />
          </div>
        }
        notificationText={"금방금방이 도움이 되었나요?"}
        cancelText="별로에요"
        onCancelClick={() => setStep("problem")}
        confirmText="네"
        onConfirmClick={() => setStep("thanks")}
      />
    ),
    thanks: (
      <ReviewContent
        isReviewSheet={isReviewSheet}
        handleDrawerController={handleDrawerController}
        headerText="감사합니다!"
        reviewImageSection={
          <div className={style.reviewStarsWrapper}>
            <img alt="리뷰 이미지" src={reviewStars} />
          </div>
        }
        notificationText={
          <>
            많은 사람들이 금방금방과
            <br />
            함꼐할 수 있도록 응원의 리뷰를 남겨주세요!
          </>
        }
        cancelText="다음에"
        onCancelClick={handleDrawerController(false)}
        confirmText="리뷰 남기기"
        onConfirmClick={handleOpen}
      />
    ),
    problem: (
      <ReviewContent
        isReviewSheet={isReviewSheet}
        handleDrawerController={handleDrawerController}
        headerText="무엇이 문제인가요?"
        reviewImageSection={
          <div className={style.reviewMagnifyWrapper}>
            <img alt="리뷰 이미지" src={reviewMagnify} />
          </div>
        }
        notificationText={
          <>
            금방금방에 건의사항을 알려주시면
            <br />
            빠르게 업데이트를 진행하겠습니다!
          </>
        }
        cancelText="다음에"
        onCancelClick={handleDrawerController(false)}
        confirmText="1:1 문의하기"
        onConfirmClick={() => {
          updateUserConfirmCount();
          navigate("/mypage/inquire");
        }}
      />
    ),
  };
  return Stepper[step];
};

export default ReviewBottomSheet;
