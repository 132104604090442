import { routes } from "@/routes";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "@app/store";
import DownArrow from "@assets/icons/ico_24.png";
import BtnMinus from "@assets/images/btn_minus.png";
import BtnMinusBk from "@assets/images/btn_minus_bk.png";
import BtnPlus from "@assets/images/btn_plus.png";
import ImgOptionClose from "@assets/images/img_option_close.png";
import { BottomSheet } from "@components/index";
import { closeBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { setPurchaseInfo } from "@features/purchase/purchaseSlice";
import { showToast } from "@features/toast/toastSlice";
import type { AssetTypes } from "@utils/types";
import { numberWithCommas } from "@utils/utils";

import usePrePrice from "../hooks/usePrePrice";
import { type AssetProps } from "./GoodsDetail";

interface GetLeftOverGrams {
  assetType: AssetTypes | undefined;
  acceptableGold: number;
  acceptableSilver: number;
  goodsGram: number | undefined;
}
function getLeftOverGrams(props: GetLeftOverGrams) {
  const { assetType, acceptableGold, acceptableSilver, goodsGram } = props;
  const acceptableGram =
    assetType === "GOLD" ? acceptableGold : acceptableSilver;

  // undefined 탈출
  if (acceptableGram && goodsGram) {
    // 가용자산이 많을 경우 물건중량 반환
    if (acceptableGram > goodsGram) {
      return goodsGram;
    }
    // 그 외는 전부 가용자산 중량 반환
    return acceptableGram;
  }
  return 0;
}

type Inputs = {
  qty: number;
  optionValue: string;
  standardPrice: number;
  sellingPrice: number;
};

export interface GoodsOptionInfo {
  goodsOptionsInfoId: number;
  optionKind: string;
  optionName: string;
  optionValue: string;
  sortOrder: number;
  isVisible: string;
}

interface SelectOptionProps {
  goodsInfo: {
    standardPrice: number | undefined;
    goodsOptionsInfos: undefined | GoodsOptionInfo[];
    assetType: AssetTypes | undefined;
  };
  acceptable: AssetProps;
}

const SelectOption: React.FC<SelectOptionProps> = (props) => {
  const { acceptableGold, acceptableSilver } = props.acceptable;
  const { standardPrice, goodsOptionsInfos, assetType } = props.goodsInfo;

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const isBottomOpen = useSelector(
    (state: RootState) => state.bottomSheet,
  ).isOpen;

  const purchaseInfo = useSelector((state: RootState) => state.purchase);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    purchaseInfo.optionValue || "선택",
  );

  const { control, handleSubmit, setValue, watch, reset } = useForm<Inputs>({
    defaultValues: {
      qty: purchaseInfo.qty || 1,
      optionValue: purchaseInfo.optionValue,
      standardPrice: purchaseInfo.standardPrice,
      sellingPrice: standardPrice || 0,
    },
  });

  // 실물인출 물품의 가격 체크
  const { prePrice } = usePrePrice({
    qty: watch("qty"),
    goodsId: params.goodsId || "",
    useGram: 0,
  });

  const leftOverGrams = getLeftOverGrams({
    assetType,
    acceptableGold,
    acceptableSilver,
    goodsGram: prePrice?.balanceGrams || 0,
  });

  // 고객 가용자산(금, 은)을 제외했을 때 가격 체크
  const { prePrice: leftOver } = usePrePrice(
    {
      qty: watch("qty"),
      goodsId: params.goodsId || "",
      useGram: leftOverGrams,
    },
    { enabled: isBottomOpen },
  );

  // 옵션 노출 상태가 VISIBLE인 옵션만
  const filteredGoodsOptionsInfos = goodsOptionsInfos?.filter(
    (item) => item.isVisible === "VISIBLE",
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!prePrice?.isAvailable) {
      return;
    }
    const { optionValue } = data;
    const isOptionAvailable =
      filteredGoodsOptionsInfos && filteredGoodsOptionsInfos.length > 0;
    const purchasePath = `${routes.buyGoodsPurchase}/${params?.goodsId}`;

    const handlePurchase = () => {
      dispatch(setPurchaseInfo(data));
      navigate(purchasePath);
      dispatch(closeBottomSheet());
    };

    // 옵션 있을 때 validation 체크
    if (isOptionAvailable) {
      if (optionValue === "") {
        dispatch(
          showToast({ message: "옵션을 선택해주세요", icon: "caution" }),
        );
        return;
      }
    }

    handlePurchase();
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    setSelectedOption(optionValue);
    setValue("optionValue", optionValue);
    setIsOpen(false);
  };

  const handleQtyChange = (change: number, currentQty: number) => {
    const newQty = Math.max(1, currentQty + change);
    setValue("qty", newQty);
  };

  useEffect(() => {
    reset({
      qty: 1,
      optionValue: "",
      standardPrice: standardPrice,
      sellingPrice: prePrice?.price || 0,
    });
    setSelectedOption("선택");
  }, []);

  useEffect(() => {
    setValue("standardPrice", standardPrice as number);
    setValue("sellingPrice", prePrice?.price || 0);
  }, [standardPrice, prePrice, setValue]);

  const heightFinder = (function () {
    if (!filteredGoodsOptionsInfos) {
      return 0;
    }
    const length = filteredGoodsOptionsInfos?.length;

    if (length === 1) {
      return 105;
    }
    if (length === 2) {
      return 152;
    }
    if (length > 3) {
      return 216;
    }
  })();

  return (
    <BottomSheet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`sub_bottom option_tab`}>
          <img src={DownArrow} className="option_icon" />
          <div
            className={`dropdown_option`}
            style={{
              overflowY: "hidden",
              height: (() => {
                if (isOpen) return heightFinder;
                if (filteredGoodsOptionsInfos?.length === 0) return "0px";
                return "56px";
              })(),
              transition: "height 0.15s ease-out",
            }}
          >
            {filteredGoodsOptionsInfos &&
              filteredGoodsOptionsInfos.length > 0 && (
                <>
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className={`dropdownButton ${isOpen ? "open" : ""} ${
                      purchaseInfo.optionValue === "" &&
                      watch("optionValue") === "" &&
                      "checked"
                    }`}
                  >
                    {selectedOption}
                    <img src={ImgOptionClose} alt="" />
                  </button>
                  <ul className={`dropdownMenu ${isOpen ? "open" : ""}`}>
                    {filteredGoodsOptionsInfos?.map((info) => (
                      <li
                        className="dropdownItem"
                        key={info.goodsOptionsInfoId}
                        onClick={() => handleOptionClick(info.optionValue)}
                      >
                        {info.optionValue}
                      </li>
                    ))}
                  </ul>
                </>
              )}
          </div>
          <ul className="option_ul01">
            <li>수량 선택</li>
            <li>
              <div className="option_nb">
                <button
                  type="button"
                  className={`"minus_bt" + ${watch("qty") > 1 ? " on" : ""}`}
                  onClick={() =>
                    watch("qty") > 1 ? handleQtyChange(-1, watch("qty")) : null
                  }
                >
                  <img src={watch("qty") > 1 ? BtnMinusBk : BtnMinus} alt="-" />
                </button>
                <Controller
                  name="qty"
                  control={control}
                  defaultValue={1}
                  render={({ field }) => (
                    <input
                      type="number"
                      {...field}
                      value={field.value || 1}
                      onChange={(e) => {
                        const newQty = Number(e.target.value) || 1;
                        setValue("qty", newQty);
                        handleQtyChange(0, newQty);
                      }}
                    />
                  )}
                />

                <button
                  type="button"
                  className={`plus_bt ${prePrice?.isAvailable ? "on" : ""}`}
                  onClick={() => {
                    if (prePrice?.isAvailable) {
                      handleQtyChange(1, watch("qty"));
                    }
                  }}
                >
                  <img src={BtnPlus} alt="+" />
                </button>
              </div>
            </li>
          </ul>
          <ul className="option_ul02">
            <li>
              <h3>총 금액</h3>
            </li>
            <li>
              <p>{numberWithCommas(prePrice?.price || 0)}원</p>
            </li>
          </ul>
          <ul className="option_ul03">
            <li>
              <p>
                사용 가능한 {assetType === "GOLD" ? "금" : "은"}&nbsp;
                {numberWithCommas(leftOverGrams)}g을 사용할 경우
              </p>
            </li>
            <li>
              <p className="fc-bl03">
                <b>{numberWithCommas(leftOver?.price)}</b>원
              </p>
            </li>
          </ul>
          <button
            type="submit"
            className={`sub_wrap02_btn  ${prePrice?.isAvailable ? "on" : ""}`}
          >
            주문하기
          </button>
        </div>
      </form>
    </BottomSheet>
  );
};

export default SelectOption;
