import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "@services/storageService";
import { checkIsOdd } from "@utils/utils";

export const CONFIRM_COUNT = "confirm_count";

export function getUserConfirmCount(): number | "done" | null {
  return getLocalStorageItem<number | "done">(CONFIRM_COUNT);
}

export function updateUserConfirmCount() {
  const userConfirmCount = getUserConfirmCount();
  if (!userConfirmCount) {
    setLocalStorageItem(CONFIRM_COUNT, 1);
  } else if (userConfirmCount !== "done") {
    setLocalStorageItem(CONFIRM_COUNT, userConfirmCount + 1);
  }
}

export function checkReviewPopUpOpen() {
  const userConfirmCount = getUserConfirmCount();

  // 유저가 한번이라도 리뷰하기 버튼 클릭 시 탈출
  if (!userConfirmCount) {
    return true;
  }
  // 리뷰하기 클릭한 유저는 무조건 미노출
  if (userConfirmCount === "done") {
    return false;
  }
  // 홀수일 때만 오픈로직
  if (checkIsOdd(userConfirmCount + 1)) {
    return true;
  }
  return false;
}

export function checkConfirmCount() {
  const userConfirmCount = getUserConfirmCount();
  // 유저가 한번이라도 리뷰하기 버튼 클릭 시 탈출
  if (userConfirmCount === "done") {
    return false;
  }

  // 초기값이 없을 때
  if (!userConfirmCount) {
    setLocalStorageItem(CONFIRM_COUNT, 1);
    return true;
  }

  const toBeSavedCount = userConfirmCount + 1;
  const isOdd = checkIsOdd(toBeSavedCount);
  if (isOdd) {
    setLocalStorageItem(CONFIRM_COUNT, toBeSavedCount);
    return true;
  }
  setLocalStorageItem(CONFIRM_COUNT, toBeSavedCount);
  return false;
}
