import { routes } from "@/routes";
import { useNavigate } from "react-router-dom";

import iconCheck from "@assets/images/status_success_48px.png";
import colors from "@assets/styles/colors.css";

import iconLeft from "../../../assets/icons/icon_left.png";

const CompletedReservation = () => {
  const navigate = useNavigate();

  const handleClickConfirm = () => {
    navigate(routes.sell);
  };
  return (
    <div className="sub_wrap03">
      <div className="sub_top">
        <div style={{ marginLeft: 35 }}>
          {/* <img src={iconLeft} alt="" className="icon_back" /> */}
          <p>예약 완료</p>
        </div>
      </div>
      <div className="agency_top" style={{ textAlign: "center" }}>
        <img src={iconCheck} alt="" />
        <p>믿을 수 있는 대리점 감정</p>
        <h3>예약이 확정 되었습니다!</h3>
      </div>
      <div className="sub_btn_wrap">
        <button
          className="sub_wrap02_btn on"
          onClick={handleClickConfirm}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default CompletedReservation;
