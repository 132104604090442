import { RootState } from "@app/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FeeRateItem {
  configType: string;
  assetType: string;
  feeRate: number;
  priceLimit: number;
}

interface FeeRateState {
  feeRate: FeeRateItem[];
}

const initialState: FeeRateState = {
  feeRate: [],
};

const feeRateSlice = createSlice({
  name: "feeRate",
  initialState,
  reducers: {
    updateFeeRate: (state, action: PayloadAction<FeeRateItem[]>) => {
      state.feeRate = action.payload;
    },
  },
});

export const { updateFeeRate } = feeRateSlice.actions;
export const selectFeeRate = (state: RootState) => state.feeRate;
export default feeRateSlice.reducer;
