import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { closeModal, openModal } from "@features/modal/modalSlice";
import * as APIS from "@services/apis";
import { numberWithCommas } from "@utils/utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconCau from "@assets/icons/ico_56px_cau.png";
import iconArrowDown from "@assets/icons/ico_arrow_down.png";
import iconArrowUp from "@assets/icons/ico_arrow_up.png";
import iconGold24 from "@assets/icons/ico_gold_24.png";
import iconMyAsset24 from "@assets/icons/ico_my_asset_24.png";
import iconPont24 from "@assets/icons/ico_pont_24.png";
import iconSilver24 from "@assets/icons/ico_silver_24.png";
import iconWon24 from "@assets/icons/ico_won_24.png";
import iconLeft from "@assets/icons/icon_left.png";
import imgCaution from "@assets/images/img_caution.png";
import colors from "@assets/styles/colors.css";

interface RejectPopupProps {
  message: string;
  onClick: () => void;
}

const Leave = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [asset, setAsset] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0,
  });
  const [limit, setLimit] = useState(0);
  const [rejectPopup, setRejectPopup] = useState({
    visible: false,
    message: "",
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    dispatch(showLoading());
    const getUserAsset = APIS.getUserHoldingsInfo().then(
      ({ data: { success, data } }) => {
        if (success) {
          setAsset({ ...asset, ...data });
        }
      },
    );

    const getLeaveLimit = APIS.getEnvConfig("LEAVE_LIMIT_ASSET").then(
      ({ data: { success, data } }) => {
        if (success) {
          setLimit(data.value);
        }
      },
    );

    Promise.all([getUserAsset, getLeaveLimit]).finally(() =>
      dispatch(hideLoading()),
    );
  }, []);

  const handleClickNext = () => {
    if (check) {
      dispatch(showLoading());
      APIS.getLeaveAsset()
        .then(({ data: { success, message } }) => {
          if (success) {
            navigate(routes.leaveReason, { replace: true });
          } else {
            setRejectPopup({ visible: true, message });
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const { krw, gold, silver, point } = asset;
  const formatLimit = numberWithCommas(limit);
  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>탈퇴하기</p>
        </div>
      </div>
      <div className="menu02_wrap">
        <h3 className="menu_wrap_tit">
          금방금방을 탈퇴하기 전에
          <br />
          확인해주세요!
        </h3>
        <div className="menu_shadow88 bg-bl01 mb16">
          <h3 className="menu_title02">
            <img src={iconMyAsset24} />총 보유 자산
          </h3>
          <div className="menu_content">
            <ul>
              <li className="name">
                <img src={iconWon24} />
                <p>원화</p>
              </li>
              <li className="price">
                <p>
                  <span>{numberWithCommas(krw)}</span>원
                </p>
              </li>
            </ul>
            <ul>
              <li className="name">
                <img src={iconPont24} />
                <p>포인트</p>
              </li>
              <li className="price">
                <p>
                  <span>{numberWithCommas(point)}</span>P
                </p>
              </li>
            </ul>
            <ul>
              <li className="name">
                <img src={iconGold24} />
                <p>금</p>
              </li>
              <li className="price">
                <p>
                  <span>{numberWithCommas(gold)}</span>g
                </p>
              </li>
            </ul>
            <ul>
              <li className="name">
                <img src={iconSilver24} />
                <p>은</p>
              </li>
              <li className="price">
                <p>
                  <span>{numberWithCommas(silver)}</span>g
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu_shadow88 bg-gr02">
          <h3 className="menu_title02">
            <img src={imgCaution} />
            주의사항
          </h3>
          <div className="menu_content bg-rd01 mb08">
            <h3 className="menu_cont_tit">회원탈퇴 불가능</h3>
            <div className="menu_cont_box">
              <p>
                평가<span>(보유)</span>금액
              </p>
              <h3 className="fc-rd01">
                <img src={iconArrowUp} /> {formatLimit}원 초과
              </h3>
            </div>
          </div>
          <div className="menu_content bg-bl03 mb08">
            <h3 className="menu_cont_tit">회원탈퇴 가능</h3>
            <div className="menu_cont_box">
              <p>
                평가<span>(보유)</span>금액
              </p>
              <h3 className="fc-bl01">
                <img src={iconArrowDown} /> {formatLimit}원 이하
              </h3>
              <span>㈜한국금거래소디지털에셋에 양도</span>
            </div>
          </div>
          <div className="menu_noti">
            <ul>
              <li>
                <p>
                  <span>보유자산이 있는 경우에는 탈퇴가 불가능</span>합니다.
                  탈퇴를 희망하실 경우에는{" "}
                  <span>모든 자산을 매도 및 출금하신 후 탈퇴</span>하여
                  주십시오.
                </p>
              </li>
              <li>
                <p>
                  보유자산이 <span>10,000원 이하</span>일 경우,{" "}
                  <span>㈜한국금거래소디지털에셋에 자동으로 양도</span>된 후
                  탈퇴 처리 됩니다.
                </p>
              </li>
              <li>
                <p>
                  회원 탈퇴 시 등록된 개인 정보 및 거래 정보는 관계 법령에 따라
                  일정 기간 보관 후 삭제됩니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu_check">
          <input
            type="checkbox"
            id="all-check"
            checked={check}
            onChange={() => {
              setCheck(!check);
            }}
          />
          <label htmlFor="all-check">
            <p>
              남은 자산은 ㈜한국금거래소디지털에셋으로 귀속됨을 확인합니다.{" "}
              <span>(필수)</span>
            </p>
          </label>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className={check === false ? "sub_wrap02_btn" : "sub_wrap02_btn on"}
          onClick={handleClickNext}
          style={{
            backgroundColor: check === false ? "" : colors.buttonColor,
          }}
        >
          다음
        </button>
      </div>
      {rejectPopup.visible && (
        <RejectPopup
          {...rejectPopup}
          onClick={() => {
            dispatch(closeModal());
          }}
        />
      )}
    </div>
  );
};

const RejectPopup: React.FC<RejectPopupProps> = ({ message, onClick }) => {
  const dispatch = useDispatch();
  dispatch(
    openModal(
      <div className="bg_gr_wrap">
        <div className="modal_wrap03 btn1">
          <img src={iconCau} alt="" />
          <h3>탈퇴하실 수 없어요!</h3>
          <p>
            {/* <span>보유한 금, 은을 모두 매도</span>하고
            <br />
            <span>모든 원화를 출금</span>한 후 탈퇴해주세요.
            <br /> */}
            {message.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </p>
          <div className="modal_btn">
            <button
              className="bg_bk_btn"
              onClick={onClick}
              style={{
                backgroundColor: colors.buttonColor,
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>,
    ),
  );
  return <></>;
};

export default Leave;
