import { openBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { numFormat } from "@utils/utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import iconRefresh from "../../../assets/icons/ico_refresh_18.png";
import iconArrowBuy from "../../../assets/icons/icon_arrow_buy.png";
import iconArrowSell from "../../../assets/icons/icon_arrow_sell.png";
import imgCaution from "../../../assets/images/img_caution_gr.png";
import * as APIS from "../../../services/apis";
import DeleteModal from "./DeleteModal";

interface WaitListProps {
  assetType?: string;
  orderType?: string;
}

const WaitList = ({ assetType, orderType }: WaitListProps) => {
  const dispatch = useDispatch();
  const [waitList, setWaitList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [orderPrice, setOrderPrice] = useState("0");
  const [requestGram, setRequestGram] = useState("0");
  const [remainGram, setRemainGram] = useState("0");
  const [deleteId, setDeleteId] = useState();
  const [tradeType, setTradeType] = useState("");
  // const [totalPrice, setTotalPrice] = useState("0");

  const handleClickDelete = ({
    tradeType,
    orderPrice,
    // totalPrice,
    requestGram,
    remainGram,
    id,
  }: any) => {
    setTradeType(tradeType);
    setOrderPrice(orderPrice);
    // setTotalPrice(totalPrice);
    setRequestGram(requestGram);
    setRemainGram(remainGram);
    setDeleteId(id);
    dispatch(openBottomSheet());
  };

  const handleClickRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (assetType) {
      dispatch(showLoading());
      APIS.getProcessingList(assetType)
        .then(({ data: { success, data } }) => {
          if (success) {
            setWaitList(data);
          }
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  }, [assetType, refresh]);

  return waitList.length > 0 ? (
    <>
      <div className="sell_wrap07_rt tab03_wrap">
        <div className="tab03c_top">
          <ul>
            <li>대기중</li>
            <li>
              <span>{waitList.length}</span>건
            </li>
          </ul>
          <img
            src={iconRefresh}
            alt=""
            className="refresh_bt"
            onClick={handleClickRefresh}
          />
        </div>
        <div className="tab03c_list">
          {waitList.map((item: any) => {
            const assetTypeName = item.assetType
              .replace("GOLD", "금")
              .replace("SILVER", "은");
            const historyOrderPrice = numFormat(item.orderPrice);
            const historyRequestGram = numFormat(item.requestGram);
            const remainGram = numFormat(item.remainGram);
            const contractGram =
              numFormat(item.requestGram - item.remainGram) || 0;
            // const feeRate =
            //   item.tradeType === "SELL" ? 1 - item.feeRate : 1 + item.feeRate;
            // const totalPrice = numFormat(
            //   item.orderPrice * item.requestGram * feeRate,
            // );
            return (
              <div className="gr-box02">
                <button
                  onClick={() =>
                    handleClickDelete({
                      tradeType: item.tradeType,
                      orderPrice: historyOrderPrice,
                      // totalPrice: totalPrice,
                      requestGram: item.requestGram,
                      remainGram: item.remainGram,
                      id: item.id,
                    })
                  }
                >
                  취소
                </button>
                <img
                  src={item.tradeType === "SELL" ? iconArrowSell : iconArrowBuy}
                  className="list_icon"
                />
                <ul>
                  <li>
                    <h3>{assetTypeName}</h3>
                  </li>
                  <li>
                    <p className="fc-gr01">
                      {`주문 중량 ${historyOrderPrice}*${historyRequestGram}g`}
                    </p>
                  </li>
                  <li>
                    <p className="fc-bl01">{`체결 ${contractGram}g`}</p>
                  </li>
                  <li>
                    <p className="fc-bk01">{`미체결 ${remainGram}g`}</p>
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <DeleteModal
        assetType={assetType}
        tradeType={tradeType}
        orderType={orderType}
        orderPrice={orderPrice}
        // totalPrice={totalPrice}
        requestGram={requestGram}
        remainGram={remainGram}
        deleteId={deleteId}
        handleClickRefresh={handleClickRefresh}
      />
    </>
  ) : (
    <div className="sell_wrap07_rt tab03_wrap">
      <div className="c_none">
        <img src={imgCaution} alt="" />
        <p>대기중인 주문이 없어요.</p>
      </div>
    </div>
  );
};

export default WaitList;
