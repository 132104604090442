import { routes } from "@/routes";
import { RootState } from "@app/store";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch, useSelector } from "react-redux";

interface GoodsDetailInfo {
  assetType: string;
  lastPrice: number;
  price: number;
  amount: number;
  totalPrice: number;
  earningsRate: number;
  averagePrice: number;
}

const useUserAssetInfo = () => {
  const dispatch = useDispatch();
  const { isSigned } = useSelector((state: RootState) => state.auth); // 로그인 상태와 토큰 정보 가져오기

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getUserAssetInfo();
      dispatch(hideLoading());
      return response.data.data as GoodsDetailInfo[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const {
    data = [],
    error,
    isLoading,
  } = useCustomQuery<GoodsDetailInfo[]>([routes.menu], fetchDetails, {
    enabled: isSigned,
  });
  return { UserAssetInfo: data, isLoading, error };
};

export default useUserAssetInfo;
