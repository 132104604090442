import { routes } from "@/routes";
import { RootState } from "@app/store";
import { updateSignUp } from "@features/auth/signUpSlice";
import { closeBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconX from "@assets/icons/icon_x.png";

interface CityListProps {
  id: number;
  name: string;
}

interface AgencyProps {
  cityList: Array<CityListProps>;
  getCity: Function;
}

const SelectAgency: React.FC<AgencyProps> = ({ cityList, getCity }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shopCity, shopDistrictFirst, shopDistrictSecond } = useSelector(
    (state: RootState) => state.signUp,
  );
  const [selectShop, setSelectShop] = useState<CityListProps>({
    id: 0,
    name: "",
  });

  const handleClickCity = (shop: CityListProps) => {
    setSelectShop(shop);
  };

  const handleClickNext = () => {
    dispatch(updateSignUp({ recommendShop: selectShop }));
    navigate(routes.authRegisterPassword, { replace: true });
  };

  const handleClickPrev = () => {
    if (shopDistrictSecond.id !== null) {
      getCity(shopDistrictFirst.id);
      dispatch(
        updateSignUp({
          shopDistrictSecond: { id: null, name: "" },
        }),
      );
    } else if (shopDistrictFirst.id !== null) {
      getCity(shopCity.id);
      dispatch(
        updateSignUp({
          shopDistrictFirst: { id: null, name: "" },
          shopDistrictSecond: { id: null, name: "" },
        }),
      );
    }
  };

  const handleCloseSheet = () => {
    dispatch(closeBottomSheet());
  };

  return (
    <div className="sub_bottom02 category_tab03">
      <div className="sub_bottom02_wrap">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">대리점 선택</h3>
          <img
            src={IconX}
            alt=""
            className="close"
            onClick={handleCloseSheet}
          />
        </div>
        <ul className="category_list02">
          {cityList.map((item: CityListProps, index) => {
            return (
              <li
                className={`${item.id === selectShop.id ? "on" : ""}`}
                key={index}
                onClick={() => {
                  handleClickCity(item);
                }}
              >
                <p>{item.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="sub_bottom_btn">
        <button className="bf_btn" onClick={handleClickPrev}>
          이전
        </button>
        <button
          className={`nt_btn ${selectShop.id !== 0 ? "on" : ""}`}
          onClick={handleClickNext}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default SelectAgency;
