import { selectMarketPrice } from "@features/market-price/marketPriceSlice";
import { useRefreshMarketPrice } from "@hooks/queries/useRefreshMarketPrice";
import useInterval from "@hooks/useInterval";
import { InitialState } from "@model/marketPrice";
import { CONSTANT } from "@utils/constants";
import { formatTime, numberWithCommas } from "@utils/utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import icoDown from "@assets/icons/ico_down.png";
import icoUp from "@assets/icons/ico_up.png";
import iconSame from "@assets/icons/icon_same.png";
import homeGold from "@assets/images/home_gold.png";
import homeSilver from "@assets/images/home_silver.png";

const MarketPriceSection = () => {
  const [assetType, setAssetType] = useState<"GOLD" | "SILVER">("GOLD");
  const refreshMarketPrice = useRefreshMarketPrice();
  const marketPriceData = useSelector(selectMarketPrice);
  const marketType = assetType.toLowerCase();
  const marketPrice = marketPriceData[marketType as keyof InitialState];
  const currentPrice = marketPrice?.current || "0";
  const previousPrice = marketPrice?.closing || "0";
  const priceChange = Number(currentPrice) - Number(previousPrice);
  const priceChangePercent = (
    (priceChange / Number(previousPrice)) *
    100
  ).toFixed(2);
  const isPriceUp = priceChange > 0;
  const isPriceDown = priceChange < 0;
  const isPriceSame = priceChange === 0;

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  useInterval(() => {
    refreshMarketPrice();
  }, 10000);

  useEffect(() => {
    const setContentWrapperStyle = () => {
      if (contentWrapperRef.current) {
        const headerHeight = 88;
        const homeMenuHeight = window.innerHeight <= 568 ? 104 : 188;
        const homeMainTopHeight = 58;
        const totalFixedHeight =
          headerHeight + homeMenuHeight + homeMainTopHeight;

        const contentWrapperHeight = contentWrapperRef.current.clientHeight;
        const availableHeight =
          window.innerHeight - totalFixedHeight - contentWrapperHeight;

        const marginTop = (availableHeight * 1) / 6;
        contentWrapperRef.current.style.marginTop = `${marginTop}px`;
      }
    };

    setContentWrapperStyle();
    window.addEventListener("resize", setContentWrapperStyle);

    return () => {
      window.removeEventListener("resize", setContentWrapperStyle);
    };
  }, []);

  const handleClickAssetType = (value: "GOLD" | "SILVER") => {
    setAssetType(value);
  };

  return (
    <div className="home_main shadow88">
      <div className="home_main_top">
        <div className="home_title">
          <h3>{CONSTANT[assetType]["TITLE"]} 시세</h3>
          <div>
            <span>
              {formatTime(new Date(marketPriceData.updatedAt), "H시 m분")} 기준
            </span>
          </div>
        </div>
        <div className="home_btn">
          <ul>
            <li
              className={`gold_btn ${assetType === "GOLD" ? "on" : ""}`}
              onClick={() => handleClickAssetType("GOLD")}
            >
              금
            </li>
            <li
              className={`silver_btn ${assetType === "SILVER" ? "on" : ""}`}
              onClick={() => handleClickAssetType("SILVER")}
            >
              은
            </li>
          </ul>
        </div>
      </div>
      <div ref={contentWrapperRef} className="content_wrapper">
        <div className="home_img">
          <img
            src={assetType === "GOLD" ? homeGold : homeSilver}
            alt={`${assetType}`}
          />
        </div>
        <div className="home_price">
          <ul>
            <li className="price_gram">
              <p className="spoqa bg_gray">1g</p>
              <p>
                <span className="spoqa">{numberWithCommas(currentPrice)}</span>
                원
              </p>
            </li>
            <li className="price_don">
              <p className="spoqa bg_gray">1돈</p>
              <p>
                <span className="spoqa">
                  {numberWithCommas((currentPrice * 3.75).toFixed(0))}
                </span>
                원
              </p>
            </li>
          </ul>
          <p
            className={`market_price ${
              isPriceUp ? "plus" : isPriceDown ? "minus" : ""
            }`}
          >
            {isPriceSame ? (
              <>
                0 <img src={iconSame} alt="가격 변동 없음" />
              </>
            ) : (
              <>
                {isPriceUp && "+"}
                {numberWithCommas(priceChange)}
                <img src={isPriceUp ? icoUp : icoDown} alt="가격 변동 아이콘" />
              </>
            )}
            {priceChangePercent}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketPriceSection;
