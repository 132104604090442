import { routes } from "@/routes";
import {
  resetTenSecondAppraisal,
  selectTenAppraisal,
  setTenSecondAppraisal,
} from "@features/appraisal-tensecond/appraisalTenSecondSlice";
import { closeModal, openModal } from "@features/modal/modalSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import gold10 from "@assets/images/img_gold_10.png";
import gold14 from "@assets/images/img_gold_14.png";
import gold18 from "@assets/images/img_gold_18.png";
import gold22 from "@assets/images/img_gold_22.png";
import gold24 from "@assets/images/img_gold_24.png";
import silver60 from "@assets/images/img_silver_60.png";
import silver70 from "@assets/images/img_silver_70.png";
import silver80 from "@assets/images/img_silver_80.png";
import silver90 from "@assets/images/img_silver_90.png";
import silver925 from "@assets/images/img_silver_92.5.png";
import silver99 from "@assets/images/img_silver_99.png";
import silver999 from "@assets/images/img_silver_999.png";
import modalNoti from "@assets/images/modal_noti.png";
import colors from "@assets/styles/colors.css";
import DetailHeader from "@components/layouts/DetailHeader";

import iconLeft from "../../assets/icons/icon_left.png";
import iconX from "../../assets/icons/icon_x.png";
import * as style from "../appraisal-select-asset/style.css";

const AppraisalSelectPurity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenAppraisalData = useSelector(selectTenAppraisal).tenSecondAppraisal;
  // const assetType = tenAppraisalData.assetType;
  const [purity, setPurity] = useState("");
  const selected = tenAppraisalData;
  const { assetType, item } = selected;
  const selectedAssetType = assetType
    .replace("GOLD", "금")
    .replace("SILVER", "은");

  const handleClickNext = () => {
    if (purity !== "") {
      dispatch(setTenSecondAppraisal({ purity: purity }));
      navigate(routes.appraisalSelectWeight);
    }
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickStop = () => {
    dispatch(resetTenSecondAppraisal());
    dispatch(closeModal());
    navigate(routes.sell);
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={modalNoti} alt="" />
            <h3>감정하신 모든 정보가 사라집니다.</h3>
            <p>그만하시겠어요?</p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleClickStop}>
                그만하기
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={style.wrap}>
      <DetailHeader
        title="순도 선택"
        cancelCallback={handleClickCancel}
        backCallback={handleClickBack}
      />
      <div className="sub_wrap02">
        <div className="sell_wrap01 sell_wrap02">
          <div className="check_option">
            <span className="check_option_sel">선택:</span>
            <span className="check_option_op gd">{selectedAssetType}</span>
            <span className="check_option_op gd">{item}</span>
          </div>
          <div className="spoqa sell_ing">
            <p>
              <span>3</span>/4
            </p>
          </div>
          <div className="sell_option">
            <h3 className="op_tit">
              <span>순도</span>를 선택해 주세요.
            </h3>
            {assetType === "GOLD" ? (
              <ul>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd1"
                      name="op_pd"
                      value="24k"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd1">
                      <img src={gold24} alt="" />
                    </label>
                    <p className="spoqa">24k</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd2"
                      name="op_pd"
                      value="22k"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd2">
                      <img src={gold22} alt="" />
                    </label>
                    <p className="spoqa">22k</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd3"
                      name="op_pd"
                      value="18k"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd3">
                      <img src={gold18} alt="" />
                    </label>
                    <p className="spoqa">18k</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd4"
                      name="op_pd"
                      value="14k"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd4">
                      <img src={gold14} alt="" />
                    </label>
                    <p className="spoqa">14k</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd5"
                      name="op_pd"
                      value="10k"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd5">
                      <img src={gold10} alt="" />
                    </label>
                    <p className="spoqa">10k</p>
                  </div>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd1"
                      name="op_pd"
                      value="99.9%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd1">
                      <img src={silver999} alt="" />
                    </label>
                    <p className="spoqa">999%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd2"
                      name="op_pd"
                      value="99%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd2">
                      <img src={silver99} alt="" />
                    </label>
                    <p className="spoqa">99%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd3"
                      name="op_pd"
                      value="92.5%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd3">
                      <img src={silver925} alt="" />
                    </label>
                    <p className="spoqa">92.5%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd4"
                      name="op_pd"
                      value="90%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd4">
                      <img src={silver90} alt="" />
                    </label>
                    <p className="spoqa">90%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd5"
                      name="op_pd"
                      value="80%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd5">
                      <img src={silver80} alt="" />
                    </label>
                    <p className="spoqa">80%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd6"
                      name="op_pd"
                      value="70%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd6">
                      <img src={silver70} alt="" />
                    </label>
                    <p className="spoqa">70%</p>
                  </div>
                </li>
                <li>
                  <div className="op_pd_box">
                    <input
                      type="radio"
                      id="op_pd7"
                      name="op_pd"
                      value="60%"
                      onChange={(e) => {
                        setPurity(e.target.value);
                      }}
                    />
                    <label htmlFor="op_pd7">
                      <img src={silver60} alt="" />
                    </label>
                    <p className="spoqa">60%</p>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${purity !== "" ? "on" : ""}`}
          onClick={handleClickNext}
          style={{
            backgroundColor: purity !== "" ? colors.buttonColor : "#ddd",
          }}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default AppraisalSelectPurity;
