import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export interface IUserAddress {
  id: number;
  postCode: string;
  address: string;
  addressDetail: string;
  isDefault: boolean;
  addressName?: string;
  receiver: string;
  phone: string;
  mobile: string;
  memo: string;
  passwordDoor: null;
}

const useAddressList = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getAddressList();
      dispatch(hideLoading());
      return response.data.data as IUserAddress[];
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IUserAddress[]>(
    [routes.deliveryAddressManagement],
    fetchDetails,
  );

  return { userAddress: data, isLoading, error, userAddressRefetch: refetch };
};

export default useAddressList;
