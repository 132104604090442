import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export type GoodsCategoryInfo = Array<{
  goodsCategoryId: number;
  kind: string;
  image: string;
  goodsIdList: number[];
}>;

const useGoodsCategory = () => {
  const dispatch = useDispatch();

  const fetchCategory = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getGoodsCategory();
      dispatch(hideLoading());
      let data = response.data.data.filter(
        (goods: any) => goods?.goodsIdList.length !== 0,
      );
      return data as GoodsCategoryInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, isFetching } = useCustomQuery(
    [routes.buy],
    fetchCategory,
  );
  return { data, error, isLoading, isFetching };
};

export default useGoodsCategory;
