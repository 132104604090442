interface Role {
  NAME: string;
  LEVEL: number;
}

export interface RoleMap {
  [key: string]: Role;
}

export const ROLE: RoleMap = {
  NONE: { NAME: "NONE", LEVEL: 0 },
  ROLE_TEMP: { NAME: "ROLE_TEMP", LEVEL: 1 }, // SNS Login 상태
  ROLE_DORMANT: { NAME: "ROLE_DORMANT", LEVEL: 1 }, // 휴면 회원
  ROLE_SELF_VERIFICATION: { NAME: "ROLE_SELF_VERIFICATION", LEVEL: 2 }, // 본인인증 완료 상태

  ROLE_BANKACCOUNT_VERIFICATION_MALL: {
    NAME: "ROLE_BANKACCOUNT_VERIFICATION_MALL",
    LEVEL: 2,
  }, // 쇼핑몰 계좌

  ROLE_BANKACCOUNT_VERIFICATION_DEPOSIT: {
    NAME: "ROLE_BANKACCOUNT_VERIFICATION_DEPOSIT",
    LEVEL: 3,
  }, // 입금 계좌 등록 상태
  ROLE_BANKACCOUNT_VERIFICATION: {
    NAME: "ROLE_BANKACCOUNT_VERIFICATION",
    LEVEL: 3,
  }, // 출금 계좌 등록 상태
};

export const routes = {
  main: "/",
  menu: "/menu",
  editProfile: "/editProfile",
  reviewHistory: "/my-page/review-history",
  leave: "/editProfile/leave",
  leaveReason: "/editProfile/leave/reason",
  leaveSms: "/editProfile/leave/sms",
  editEmail: "/editProfile/edit-email",
  notification: "/notification",
  login: "/login",
  signIn: "/sign/sign-in",
  signInId: "/sign/sign-in/id",
  signUpId: "/sign/sign-up/id",
  commonSecureKeypad: "/common/secure-keypad",
  count: "/count",
  buy: "/buy",
  buyGoodsDetail: "/buy/goods/detail",
  buyGoodsPurchase: "/buy/goods/purchase",
  buySearch: "/buy/search",
  sell: "/sell",
  authBankAccount: "/auth/auth-bank-account",
  selfVerification: "/auth/self-verification",
  authRegisterPassword: "/auth/auth-register-password",
  appraisalSelectAsset: "/sell/10second-appraisal/select-asset",
  appraisalSelectItem: "/sell/10second-appraisal/select-item",
  appraisalSelectPurity: "/sell/10second-appraisal/select-purity",
  appraisalSelectWeight: "/sell/10second-appraisal/select-weight",
  appraisalResult: "/sell/10second-appraisal/result",
  appraisalAgency: "/sell/agency-appraisal",
  appraisalDelivery: "/sell/delivery-appraisal",
  tradeAsset: "/trade-asset",
  acceptTerms: "/auth/accept-terms",
  authRegisterShop: "/auth/register-shop",
  authRegisterEmail: "/auth/register-email",
  authGateway: "/auth/gateway",
  trading: "/trading",
  appraisalReservationComplete: "/sell/agency-appraisal/complete",
  myAccountManagement: "/mypage/account-management",
  myAccountVerification: "/mypage/account-verification",
  deposit: "/deposit",
  withdraw: "/withdraw",
  withdrawComplete: "/withdraw-complete",
  tradeHistory: "/mypage/trade-history",
  tradeCompleteDetail: "/mypage/trade-history/complete-detail",
  appraiseDetail: "/mypage/trade-history/appraiseDetail",
  inquire: "/mypage/inquire",
  shoppingDetail: "/mypage/trade-history/shoppingDetail",
  purchaseSuccess: "/purchase-success",
  purchaseFail: "/purchase-fail",
  notice: "/notice",
  event: "/event",
  storageFee: "/storageFee",
  deliveryAddressManagement: "/mypage/delivery-address-management",
  reviewGateway: "/detail",
  shopDetail: "/shop-detail",
} as const;

export const mapRouteToRole = {
  [routes.main]: ROLE.NONE,
  // [routes.depositWithdrawal]: ROLE.ROLE_TEMP,
  // [routes.mypage]: ROLE.ROLE_TEMP,
  // [routes.menu]: ROLE.NONE,
  [routes.signIn]: ROLE.NONE,
  [routes.authGateway]: ROLE.NONE,
  // [routes.signUp]: ROLE.NONE,

  //실물인출
  // [routes.order]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.authBankAccount]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.myPhysicalWithDrawal]: ROLE.ROLE_SELF_VERIFICATION,

  //감정평가
  [routes.appraisalAgency]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.appraise]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.appraiseRegist]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.appraiseDirect]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.appraiseReturn]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.deposit]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.virtualOrder]: ROLE.ROLE_SELF_VERIFICATION,

  //매수매도
  // [routes.mytradehistory]: ROLE.ROLE_SELF_VERIFICATION,

  // 입출금
  // [routes.depositIssueAccount]: ROLE.ROLE_SELF_VERIFICATION,

  // 푸쉬 알림
  // [routes.notification]: ROLE.ROLE_SELF_VERIFICATION,

  // 쿠폰
  // [routes.couponRegister]: ROLE.ROLE_SELF_VERIFICATION,

  //브이리뷰 마이페이지
  // [routes.myreview]: ROLE.ROLE_SELF_VERIFICATION,
};
