import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export interface ICompleteParam {
  assetTypeList: string[];
  tradeTypeList: string[];
  fromDt: string;
  toDt: string;
  page: number;
  size: number;
  sortType: string;
}

export interface ITradeCompleteResponse {
  addTradeType: string | null;
  afterGold: number;
  afterKrw: number;
  afterPoint: number;
  afterSilver: number;
  assetType: string;
  associateName: string | null;
  brandName: string | null;
  completedAt: string;
  goodsId: number | null;
  imagePath: string | null;
  option: string | null;
  pricePerGram: number;
  productName: string | null;
  pureTradeKrw: number;
  rawId: number;
  requestAt: string;
  shopName: string | null;
  totalCount: number;
  totalPrice: number;
  tradeGram: number;
  tradeId: number;
  tradeNumber: string;
  tradePoint: number;
  tradeStatus: string;
  tradeType: string;
  userFee: number;
  yourName: string;

  assetTypeKor: string;
  tradeTypeKor: string;
  status: string;
  requestKrw: number;
  requestGram: number;
  requestPrice: number;
  contractGram: number;
  tradeKrw: number;
  requestId: number;
}

const useTradeCompleteList = (completeFilter: ICompleteParam) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getTradeHistoryCompleteList(completeFilter);
      dispatch(hideLoading());
      const { content, totalPages } = response.data.data;
      return {
        content: content as ITradeCompleteResponse[],
        totalPages: totalPages,
      };
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<{
    content: ITradeCompleteResponse[];
    totalPages: number;
  }>([routes.tradeHistory, "complete", completeFilter], fetchDetails);

  return {
    TradeCompleteList: data?.content,
    totalPages: data?.totalPages || 0,
    isLoading,
    error,
    CompleteRefetch: refetch,
  };
};

export default useTradeCompleteList;
