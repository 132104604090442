import { routes } from "@/routes";
import { closeModal, openModal } from "@features/modal/modalSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import btnX from "@assets/icons/btn_x_16.png";
import InAccountIcon from "@assets/icons/ico_48px.png";
import OutAccountIcon from "@assets/icons/ico_48px_02.png";
import IconLeft from "@assets/icons/icon_left.png";
import ImgPassbook0393 from "@assets/images/img_passbook_03_93px.png";
import colors from "@assets/styles/colors.css";

import useAccountChange from "./hooks/useAccountChange";
import useAccountInfo, { IAccountInfo } from "./hooks/useAccountInfo";
import * as style from "./styles.css";

interface AccountSectionProps {
  accountType: "DEPOSIT" | "WITHDRAW";
  accountInfo: IAccountInfo | undefined;
  icon: string;
  buttonText: "변경" | "+ 등록";
  isOtherAccount: boolean;
  isLoading: boolean;
  refetch: () => void;
}

const AccountSection: React.FC<AccountSectionProps> = ({
  accountType,
  accountInfo,
  icon,
  buttonText,
  isOtherAccount,
  isLoading,
  refetch,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate } = useAccountChange(accountType, refetch);

  const hasAccount =
    accountInfo?.bankAccountStatus === "COMPLETED" &&
    !!accountInfo?.bankAccountNumber;

  const handleClickBtn = () => {
    const accountTypeName = accountType === "DEPOSIT" ? "입금" : "출금";
    const otherAccountTypeName = accountType === "DEPOSIT" ? "출금" : "입금";
    if (isOtherAccount) {
      dispatch(
        openModal(
          <div
            className="bg_gr_wrap"
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            <div
              className={`modal_wrap02 ${style.relative}`}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={ImgPassbook0393} alt="" />
              <img
                src={btnX}
                className={style.modalCloseBtn}
                onClick={() => {
                  dispatch(closeModal());
                }}
              />
              <h3>등록된 {otherAccountTypeName}계좌가 있어요.</h3>
              <p>
                등록하신{" "}
                <span className="fc-bl03">{otherAccountTypeName}계좌</span>를{" "}
                <span className="fc-bl03">{accountTypeName}계좌</span>로
                <br />
                사용하시겠어요?
              </p>
              <div className="modal_btn">
                <button
                  className="bg_gr_btn"
                  onClick={() => {
                    dispatch(closeModal());
                    navigate(routes.myAccountVerification, {
                      state: { bankType: accountType },
                    });
                  }}
                >
                  신규등록
                </button>
                <button
                  className="bg_bk_btn"
                  onClick={() => {
                    mutate(accountType);
                  }}
                  style={{
                    backgroundColor: colors.buttonColor,
                  }}
                >
                  {otherAccountTypeName}계좌 사용
                </button>
              </div>
            </div>
          </div>,
        ),
      );
    } else {
      navigate(routes.myAccountVerification, {
        state: {
          bankType: accountType,
          isAcccountChange: buttonText === "변경",
        },
      });
    }
  };

  return (
    <div
      className={`menu_shadow88_02 ${
        accountType === "DEPOSIT" ? "mb16 bg-rd01" : "bg-bl03"
      }`}
    >
      <ul>
        <li>
          <h3>
            <img src={icon} alt={`${accountType} Icon`} />
            {accountType === "DEPOSIT" ? "입금 계좌" : "출금 계좌"}
          </h3>
          <button
            className={hasAccount ? "change_bt" : "register_bt"}
            onClick={handleClickBtn}
            disabled={isLoading}
          >
            {buttonText}
          </button>
        </li>
      </ul>
      {hasAccount && (
        <ul className="bank_info">
          <li>
            <p>{accountInfo.bankName}</p>
          </li>
          <li>
            <h3>{accountInfo.bankAccountNumber}</h3>
          </li>
        </ul>
      )}
    </div>
  );
};

const AccountManagement: React.FC = () => {
  const navigate = useNavigate();
  const {
    AccountInfo: depositAccount,
    isLoading: isDepositLoading,
    AccountInfoRefetch: refetchDeposit,
  } = useAccountInfo("DEPOSIT");
  const {
    AccountInfo: withdrawAccount,
    isLoading: isWithdrawLoading,
    AccountInfoRefetch: refetchWithdraw,
  } = useAccountInfo("WITHDRAW");

  const hasDepositAccount =
    depositAccount?.bankAccountStatus === "COMPLETED" &&
    !!depositAccount?.bankAccountNumber;
  const hasWithdrawAccount =
    withdrawAccount?.bankAccountStatus === "COMPLETED" &&
    !!withdrawAccount?.bankAccountNumber;

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={IconLeft}
            alt="Back Icon"
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>입출금 계좌 관리</p>
        </div>
      </div>
      <div className="menu02_wrap">
        <h3 className="menu_wrap_tit">
          {hasDepositAccount && hasWithdrawAccount ? (
            "계좌 관리"
          ) : (
            <>
              계좌를 등록하고 <br /> 금방금방 서비스를 이용해보세요!
            </>
          )}
        </h3>
        <AccountSection
          accountType="DEPOSIT"
          accountInfo={depositAccount}
          icon={InAccountIcon}
          buttonText={hasDepositAccount ? "변경" : "+ 등록"}
          isOtherAccount={hasWithdrawAccount}
          isLoading={isDepositLoading}
          refetch={refetchDeposit}
        />
        <AccountSection
          accountType="WITHDRAW"
          accountInfo={withdrawAccount}
          icon={OutAccountIcon}
          buttonText={hasWithdrawAccount ? "변경" : "+ 등록"}
          isOtherAccount={hasDepositAccount}
          isLoading={isWithdrawLoading}
          refetch={refetchWithdraw}
        />
      </div>
    </div>
  );
};

export default AccountManagement;
