import { formatDateTime, numberWithCommas } from "@utils/utils";
import React from "react";
import { useLocation, useNavigate } from "react-router";

import IconGr from "@assets/icons/ico_gr_24.png";
import IconLeft from "@assets/icons/icon_left.png";

import { ITradeCompleteResponse } from "../hooks/useTradeCompleteList";
import { AmountByAsset } from "./TradeCard";

interface LocationState {
  tradeInfo: ITradeCompleteResponse;
}
interface AssetCompleteDetailProps {
  tradeInfo: ITradeCompleteResponse;
}
interface AfterAssetProps {
  afterGold: number;
  afterSilver: number;
  afterKrw: number;
  afterPoint: number;
}

const LabelByTradeTypes = [
  {
    type: "SELL",
    headerLabel: "자산 팔래요",
    mainTitle: "자산 판매 체결",
    textLabel: "판매",
  },
  {
    type: "BUY",
    headerLabel: "자산 살래요",
    mainTitle: "자산 구매 체결",
    textLabel: "구매",
  },
  {
    type: "SEND_ASSET",
    headerLabel: "자산 받음",
    mainTitle: "자산 받기 완료",
    textLabel: "자산 받음",
  },
  {
    type: "RECEIVE_ASSET",
    headerLabel: "자산 보냄",
    mainTitle: "자산 보내기 완료",
    textLabel: "자산 보냄",
  },
  {
    type: "COUPON",
    headerLabel: "쿠폰",
    mainTitle: "쿠폰 지급 완료",
    textLabel: "쿠폰",
  },
  {
    type: "EVENT",
    headerLabel: "이벤트",
    mainTitle: "포인트 지급 완료",
    textLabel: "이벤트",
  },
  {
    type: "DEPOSIT",
    headerLabel: "입금",
    mainTitle: "입금 완료",
    textLabel: "입금",
  },
  {
    type: "WITHDRAW",
    headerLabel: "출금",
    mainTitle: "출금 완료",
    textLabel: "출금",
  },
];

const TradeCompleteDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tradeInfo } = location.state as LocationState;

  const headerText =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.headerLabel || "";
  const mainTitle =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.mainTitle || "";

  const handleClickBack = () => {
    navigate(-1);
  };

  const TradeComponent = tradeTypeDetailComponents.find((item) =>
    item.tradeType.includes(tradeInfo.tradeType),
  )?.component;

  return (
    <div>
      <div className="sub_top04">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>{headerText} 내역</p>
        </div>
      </div>
      <div className="sub_wrap08">
        <h3 className="sub8_main_title">
          <img src={IconGr} />
          {mainTitle}
        </h3>
        {TradeComponent && <TradeComponent tradeInfo={tradeInfo} />}
        <AfterAsset
          afterGold={tradeInfo.afterGold}
          afterSilver={tradeInfo.afterSilver}
          afterKrw={tradeInfo.afterKrw}
          afterPoint={tradeInfo.afterPoint}
        />
      </div>
    </div>
  );
};

export default TradeCompleteDetail;

// 거래 후 잔고
const AfterAsset: React.FC<AfterAssetProps> = ({
  afterGold,
  afterSilver,
  afterKrw,
  afterPoint,
}) => {
  return (
    <div>
      <div className="shadow88 shadow88_02">
        <ul className="total_price02 mb16">
          <li>
            <p>거래 후 잔고</p>
          </li>
        </ul>
        <ul>
          <li>
            <p className="fc-gr01">금</p>
          </li>
          <li>
            <p className="fc-bk01">{numberWithCommas(afterGold)}g</p>
          </li>
        </ul>
        <ul>
          <li>
            <p className="fc-gr01">은</p>
          </li>
          <li>
            <p className="fc-bk01">{numberWithCommas(afterSilver)}g</p>
          </li>
        </ul>
        <ul>
          <li>
            <p className="fc-gr01">원화</p>
          </li>
          <li>
            <p className="fc-bk01">{numberWithCommas(afterKrw)}원</p>
          </li>
        </ul>
        <ul>
          <li>
            <p className="fc-gr01">포인트</p>
          </li>
          <li>
            <p className="fc-bk01">{numberWithCommas(afterPoint)}pt</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

// 자산 팔래요, 살래요 상세
const AssetTradeCompleteDetail: React.FC<AssetCompleteDetailProps> = ({
  tradeInfo,
}) => {
  const statusText =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.textLabel || "";
  const finalAmount =
    tradeInfo.tradeType === "SELL"
      ? tradeInfo.pureTradeKrw - tradeInfo.userFee
      : tradeInfo.pureTradeKrw + tradeInfo.userFee;

  return (
    <div className="shadow88 shadow88_02 mb08">
      <h3 className="price_name">{tradeInfo.assetTypeKor}</h3>
      <ul className="total_price mb16">
        <li>
          <p>최종 {statusText} 금액</p>
        </li>
        <li>
          <h3>{numberWithCommas(finalAmount)}원</h3>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 번호</p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.tradeNumber}</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 일시</p>
        </li>
        <li>
          <p className="fc-bk01">{formatDateTime(tradeInfo.completedAt)}</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">자산</p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.assetTypeKor}</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">체결 가격</p>
        </li>
        <li>
          <p className="fc-bk01">
            {numberWithCommas(tradeInfo.pricePerGram)} 원
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">체결 중량</p>
        </li>
        <li>
          <p className="fc-bk01">{numberWithCommas(tradeInfo.tradeGram)}g</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">{statusText} 금액</p>
        </li>
        <li>
          <p className="fc-bk01">
            {numberWithCommas(tradeInfo.pureTradeKrw)}원
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">수수료</p>
        </li>
        <li>
          <p className="fc-bk01">{numberWithCommas(tradeInfo.userFee)}원</p>
        </li>
      </ul>
    </div>
  );
};

// 자산 보냄, 자산 받음 상세
const AssetSendReceiveCompleteDetail: React.FC<AssetCompleteDetailProps> = ({
  tradeInfo,
}) => {
  const statusText =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.textLabel || "";

  return (
    <div className="shadow88 shadow88_02 mb08">
      <h3 className="price_name">{statusText}</h3>
      <ul className="total_price mb16">
        <li>
          <p>{tradeInfo.assetTypeKor}</p>
        </li>
        <li>
          <h3>{AmountByAsset(tradeInfo)}</h3>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 번호</p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.tradeNumber}</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 일시</p>
        </li>
        <li>
          <p className="fc-bk01">{formatDateTime(tradeInfo.completedAt)}</p>
        </li>
      </ul>

      <ul>
        <li>
          <p className="fc-gr01">
            {tradeInfo.tradeType === "SEND_ASSET" ? "보낸 사람" : "받은 사람"}
          </p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.yourName}</p>
        </li>
      </ul>
    </div>
  );
};

const CouponEvent: React.FC<AssetCompleteDetailProps> = ({ tradeInfo }) => {
  const statusText =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.textLabel || "";
  return (
    <div className="shadow88 shadow88_02 mb08">
      <h3 className="price_name">{statusText}</h3>
      <ul className="total_price mb16">
        <li>
          <p>{tradeInfo.assetTypeKor}</p>
        </li>
        <li>
          <h3>{AmountByAsset(tradeInfo)}</h3>
        </li>
      </ul>

      <ul>
        <li>
          <p className="fc-gr01">
            {tradeInfo.tradeType === "COUPON" ? "쿠폰 번호" : "거래 번호"}
          </p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.tradeNumber}</p>
        </li>
      </ul>
      {tradeInfo.tradeType === "COUPON" ? (
        <ul>
          <li>
            <p className="fc-gr01">자산</p>
          </li>
          <li>
            <p className="fc-bk01">자산</p>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <p className="fc-gr01">거래 일시</p>
          </li>
          <li>
            <p className="fc-bk01">{formatDateTime(tradeInfo.completedAt)}</p>
          </li>
        </ul>
      )}
    </div>
  );
};

const DepositWithdraw: React.FC<AssetCompleteDetailProps> = ({ tradeInfo }) => {
  const statusText =
    LabelByTradeTypes.find((item) => item.type === tradeInfo.tradeType)
      ?.textLabel || "";
  return (
    <div className="shadow88 shadow88_02 mb08">
      <h3 className="price_name">{statusText}</h3>
      <ul className="total_price mb16">
        <li>
          <p>{statusText}액</p>
        </li>
        <li>
          <h3>{AmountByAsset(tradeInfo)}</h3>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 번호</p>
        </li>
        <li>
          <p className="fc-bk01">{tradeInfo.tradeNumber}</p>
        </li>
      </ul>
      <ul>
        <li>
          <p className="fc-gr01">거래 일시</p>
        </li>
        <li>
          <p className="fc-bk01">{formatDateTime(tradeInfo.completedAt)}</p>
        </li>
      </ul>
      {tradeInfo.tradeType === "WITHDRAW" ? (
        <ul>
          <li>
            <p className="fc-gr01">수수료</p>
          </li>
          <li>
            <p className="fc-bk01">{numberWithCommas(tradeInfo.userFee)}원</p>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

const tradeTypeDetailComponents = [
  {
    tradeType: ["SELL", "BUY"],
    component: AssetTradeCompleteDetail,
  },
  {
    tradeType: ["SEND_ASSET", "RECEIVE_ASSET"],
    component: AssetSendReceiveCompleteDetail,
  },
  {
    tradeType: ["COUPON", "EVENT"],
    component: CouponEvent,
  },
  { tradeType: ["DEPOSIT", "WITHDRAW"], component: DepositWithdraw },
];
