import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { getNoticeDetail } from "@services/apis";
import constants from "@utils/constants";
import { formatTime } from "@utils/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import activeIcon from "@assets/icons/ico_notice_active.png";
import notiIcon from "@assets/icons/ico_notice_basic.png";
import DetailHeader from "@components/layouts/DetailHeader";

import { NoticeItem } from "./hooks/useNoticeList";

type Props = {};

function NoticeDetail({}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [noticeDetail, setNoticeDetail] = useState<NoticeItem>();
  let serviceImages = noticeDetail?.serviceImages;
  let imageUrl =
    serviceImages && serviceImages.find((item) => item.imageType === "IMAGE");

  let source = noticeDetail?.status === "FIXED" ? activeIcon : notiIcon;

  const fetchNoticeDetail = async () => {
    dispatch(showLoading());
    try {
      const response = await getNoticeDetail(params?.noticeId || "0");
      setNoticeDetail(response.data.data);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  useEffect(() => {
    fetchNoticeDetail();
  }, []);

  return (
    <div className="detail">
      <DetailHeader style={{ backgroundColor: "#fff" }} title="공지사항 상세" cancelCallback={() => navigate(-1)} />
      <div className="content"style={{ backgroundColor: "#fff" }}>
        <div
          className="title"
          style={{
            margin: "0 24px 25px",
            padding: "16px 0",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "48px",
                height: "48px",
                marginRight: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
                  borderRadius: "16px",
                  backgroundColor: "#F7F7F7",
                }}
              >
                <img
                  style={{}}
                  src={source}
                  alt={`notice_icon_${
                    noticeDetail?.status === "FIXED" ? "active" : "normal"
                  }`}
                />
                {noticeDetail?.status === "FIXED" && (
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#FF0000",
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                    }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                wordBreak: "keep-all",
              }}
            >
              <span
                style={{
                  marginBottom: "16px",
                  color: "#444",
                  fontWeight: "700",
                }}
              >
                {noticeDetail?.subject}
              </span>
              <span style={{ color: "#777", fontSize: "12px" }}>
                {formatTime(new Date(noticeDetail?.startDate || ""))}
              </span>
            </div>
          </div>
        </div>
        <div
          className="body"
          style={{
            display: "flex",
            margin: "0 24px",
          }}
        >
          <div
            style={{
              width: "48px",
              marginRight: "16px",
            }}
          />
          <div
            style={{
              flex: 1,
            }}
          >
            <span
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "keep-all",
              }}
            >
              {noticeDetail?.content}
            </span>
            <div style={{ height: "24px" }} />
            {serviceImages && serviceImages.length > 0 && imageUrl && (
              <div>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={
                    serviceImages.length > 0 && imageUrl
                      ? `${constants.s3BaseUrl}${imageUrl.path}`
                      : ""
                  }
                  alt="공지사항이미지"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeDetail;
