import React, { useState } from "react";
import iconLeft from "../../assets/icons/icon_left.png";
import iconX from "../../assets/icons/icon_x.png";
import imgGoldBar from "../../assets/images/img_goldbar.png";
import imgSilverBar from "../../assets/images/img_silverbar.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useDispatch } from "react-redux";
import {
  resetTenSecondAppraisal,
  setTenSecondAppraisal,
} from "../../features/appraisal-tensecond/appraisalTenSecondSlice";
import { closeModal, openModal } from "../../features/modal/modalSlice";
import modalNoti from "../../assets/images/modal_noti.png";
import DetailHeader from "../../components/layouts/DetailHeader";
import * as style from "./style.css";
import colors from "@assets/styles/colors.css";

interface AppraisalSelectAssetProps {}

const AppraisalSelectAsset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assetType, setAssetType] = useState("");

  const handleClickNext = () => {
    if (assetType !== "") {
      dispatch(setTenSecondAppraisal({ assetType: assetType }));
      navigate(routes.appraisalSelectItem);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickStop = () => {
    dispatch(resetTenSecondAppraisal());
    dispatch(closeModal());
    navigate(routes.sell);
  };

  const handleClickCancel = () => {
    dispatch(
      openModal(
        <div className="bg_gr_wrap">
          <div className="modal_wrap">
            <img src={modalNoti} alt="" />
            <h3>감정하신 모든 정보가 사라집니다.</h3>
            <p>그만하시겠어요?</p>
            <div className="modal_btn">
              <button className="bg_gr_btn" onClick={handleClickStop}>
                그만하기
              </button>
              <button
                className="bg_bk_btn"
                onClick={handleCloseModal}
                style={{
                  backgroundColor: colors.buttonColor,
                }}
              >
                계속하기
              </button>
            </div>
          </div>
        </div>,
      ),
    );
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <div className={style.wrap}>
      <DetailHeader
        title="감정 예상가 확인하기"
        cancelCallback={handleClickCancel}
        backCallback={handleClickBack}
      />
      <div className="sub_wrap02">
        <div className="sell_wrap01 sell_wrap00">
          <div className="check_option">
            <p>
              간편하게 <span>셀프 감정</span>해 보세요!
            </p>
          </div>
          <div className="spoqa sell_ing">
            <p>
              <span>1</span>/4
            </p>
          </div>
          <div className="sell_option">
            <h3 className="op_tit">
              <span>감정받을 자산</span>을 선택해 주세요.
            </h3>
            <ul>
              <li>
                <div className="op_pd_box">
                  <input
                    type="radio"
                    id="op_pd1"
                    name="op_pd"
                    value="GOLD"
                    onChange={(e) => setAssetType("GOLD")}
                    checked={assetType === "GOLD"}
                  />
                  <label htmlFor="op_pd1">
                    <img src={imgGoldBar} alt="" />
                  </label>
                  <p>금</p>
                </div>
              </li>
              <li>
                <div className="op_pd_box">
                  <input
                    type="radio"
                    id="op_pd2"
                    name="op_pd"
                    value="SILVER"
                    onChange={(e) => setAssetType("SILVER")}
                    checked={assetType === "SILVER"}
                  />
                  <label htmlFor="op_pd2">
                    <img src={imgSilverBar} alt="" />
                  </label>
                  <p>은</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${assetType !== "" ? "on" : ""}`}
          onClick={handleClickNext}
          style={{
            backgroundColor: assetType !== "" ? colors.buttonColor : "#ddd",
          }}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default AppraisalSelectAsset;
