import { routes } from "@/routes";
import { selectFeeRate } from "@features/fee-rate/feeRateSlice";
import { useRefreshMarketPrice } from "@hooks/queries/useRefreshMarketPrice";
import useInterval from "@hooks/useInterval";
import { ButtonBase } from "@mui/material";
import { setLocalStorageItem } from "@services/storageService";
import { initFeeRate } from "@utils/initFeeRate";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import tradeArrow from "@assets/icons/ico_tradeArrow.png";
import Banners from "@components/common/banners/Banners";

import { RootState } from "../../app/store";
import { selectMarketPrice } from "../../features/market-price/marketPriceSlice";
import { InitialState } from "../../model/marketPrice";
import * as APIS from "../../services/apis";
// import Calculator from "./components/Calculator";
import Appraisal from "./components/Appraisal";
import AssetsAvailableSale from "./components/AssetsAvailableSale";
import BottomNavigation from "./components/BottomNavigation";
import Header from "./components/Header";
import MarketPriceSection from "./components/MarketPriceSection";

const Sell = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assetType, setAssetType] = useState<"GOLD" | "SILVER">("GOLD");
  const marketPriceData = useSelector(selectMarketPrice);
  const refreshMarketPrice = useRefreshMarketPrice();
  const marketType = assetType
    .replace("GOLD", "gold")
    .replace("SILVER", "silver");
  const marketPrice = marketPriceData[marketType as keyof InitialState];
  const currentPrice = (marketPrice as any)?.current; //시세가
  const closingPrice = (marketPrice as any)?.closing;
  const { feeRate } = useSelector(selectFeeRate); //수수료율
  const { isSigned } = useSelector((state: RootState) => state.auth);
  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
    acceptablePoint: 0,
  });

  useMemo(() => {
    if (isSigned) {
      APIS.getUserHoldingsInfo().then(({ data: { success, data = {} } }) => {
        success && setHoldings(data);
      });
    }
  }, [isSigned]);

  useEffect(() => {
    initFeeRate(dispatch);
  }, [dispatch]);

  useInterval(() => {
    refreshMarketPrice();
  }, 10000);

  return (
    <div>
      <Header />
      <div className="sub_wrap">
        <div className="sell_top">
          <h3>팔래요</h3>
          <ButtonBase
            className="sell_btn"
            onClick={() => {
              navigate(routes.tradeAsset, {
                state: { tradeType: "SELL" },
              });
              setLocalStorageItem("tradingAssetType", "GOLD");
            }}
          >
            <p>
              <img src={tradeArrow} alt="" /> 자산 팔래요
            </p>
          </ButtonBase>
        </div>
        <MarketPriceSection
          assetType={assetType}
          marketPriceData={marketPriceData}
          currentPrice={currentPrice}
          closingPrice={closingPrice}
          setAssetType={setAssetType}
        />
        {/* <Calculator
          assetType={assetType}
          currentPrice={currentPrice}
          feeRate={feeRate}
        /> */}
        {/* {!!isSigned && ( */}
        <AssetsAvailableSale
          holdings={holdings}
          currentPrice={currentPrice}
          feeRate={feeRate}
          navigate={navigate}
          marketPriceData={marketPriceData}
        />
        {/* )} */}
        <Appraisal />
        <Banners />
      </div>
      <BottomNavigation />
    </div>
  );
};

export default Sell;
