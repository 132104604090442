import { routes } from "@/routes";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import IconOk88 from "@assets/icons/icon_88_ok.png";
import IconLeft from "@assets/icons/icon_left.png";
import colors from "@assets/styles/colors.css";
import { ReviewBottomSheet } from "@components/common/review-bottom-sheet";
import {
  checkReviewPopUpOpen,
  updateUserConfirmCount,
} from "@components/common/review-bottom-sheet/const";
import { numberWithCommas } from "@utils/utils";

interface ICompleteResult {
  pureTradeKrw: number;
  userFee: number;
  afterKrw: number;
}

const WithdrawComplete: React.FC = () => {
  const [isReviewSheet, setIsReviewSheet] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const { pureTradeKrw, userFee, afterKrw }: ICompleteResult = locationState;

  const handleClickWithdraw = () => {
    navigate(routes.withdraw);
  };
  const handleClickMain = () => {
    navigate(routes.main);
  };

  useEffect(() => {
    console.log("render");
    if (checkReviewPopUpOpen()) {
      setIsReviewSheet(true);
    } else {
      updateUserConfirmCount();
    }
  }, [setIsReviewSheet]);

  return (
    <>
      <div className="sub_top02">
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickWithdraw}
          />
          <p>출금 완료</p>
        </div>
      </div>
      <div className="sub02_wrap sub02_wrap03">
        <div className="sub02_wrap03_top">
          <img src={IconOk88} className="icon_88" />
          <h3 className="sub02_wrap03_tit">
            <span className="fc-bl03">출금 완료</span> 되었습니다.
          </h3>
        </div>
        <div className="shadow88">
          <div className="sub02_02 fail">
            <ul className="sub02_02_ul02">
              <li>
                <p>출금</p>
              </li>
              <li>
                <h3 className="fc-bl01">-{numberWithCommas(pureTradeKrw)}원</h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>수수료</p>
              </li>
              <li>
                <h3>{numberWithCommas(userFee)}원</h3>
              </li>
            </ul>
            <ul className="sub02_02_ul02">
              <li>
                <p>출금 후 잔액</p>
              </li>
              <li>
                <h3>{numberWithCommas(afterKrw)}원</h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="sub02_wrap03_btn">
          <button
            className="bl-btn01"
            onClick={handleClickMain}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            확인
          </button>
        </div>
      </div>
      <ReviewBottomSheet
        isReviewSheet={isReviewSheet}
        setIsReviewSheet={setIsReviewSheet}
      />
    </>
  );
};

export default WithdrawComplete;
