import { routes } from "@/routes";
import { RootState } from "@app/store";
import { openBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { ButtonBase } from "@mui/material";
import { STORAGE_KEY } from "@utils/constants";
import { phoneNumberFormat, postMessage } from "@utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconEmail from "@assets/icons/ico_email_18.png";
import iconHome from "@assets/icons/ico_home.png";
import iconLock from "@assets/icons/ico_lock_18.png";
import iconPassbook from "@assets/icons/ico_passbook_18.png";
import iconPeople from "@assets/icons/ico_people_18.png";
import iconPhone from "@assets/icons/ico_phone_18.png";
import iconLeft from "@assets/icons/icon_left.png";
import SelectShops from "@components/common/select-shop/SelectShops";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user);
  const auth = useSelector((state: RootState) => state.auth);

  const handleClickEditLoginPwd = () => {
    postMessage({
      command: "CHANGE_PASSWORD",
      param: {
        uri: import.meta.env.VITE_API_URL,
        token: sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN),
      },
    });
  };

  return (
    <div>
      <SelectShops from="UPDATE_USING_SHOP" />
      <div className="sub_top">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>내 정보 변경</p>
        </div>
      </div>
      <div className="menu02_wrap">
        <div className="menu_shadow88 mb16">
          <h3 className="menu_title">기본정보</h3>
          <ul>
            <li>
              <img src={iconPeople} />
              <p>{userInfo.name}</p>
            </li>
            <li>
              <img src={iconPhone} />
              <p>{phoneNumberFormat(userInfo.phone)}</p>
              <ButtonBase
                onClick={() => {
                  navigate(routes.selfVerification, {
                    state: { purpose: "UPDATE_PHONENUMBER" },
                  });
                }}
              >
                변경
              </ButtonBase>
            </li>
            <li>
              <img src={iconEmail} />
              <p>{userInfo.email}</p>
              <ButtonBase
                onClick={() => {
                  navigate(routes.editEmail);
                }}
              >
                변경
              </ButtonBase>
            </li>
            <li>
              <img src={iconHome} />
              <p>배송지</p>
              <ButtonBase
                onClick={() => {
                  navigate(routes.deliveryAddressManagement, {
                    state: { showSelectButton: false },
                  });
                }}
              >
                관리
              </ButtonBase>
            </li>
          </ul>
        </div>
        <div className="menu_shadow88 mb16">
          <h3 className="menu_title">보안･거래 정보</h3>
          <ul>
            {auth.sns === "LOGIN_ID" && (
              <li>
                <img src={iconLock} />
                <p>로그인 비밀번호 변경</p>
                <button onClick={handleClickEditLoginPwd}>변경</button>
              </li>
            )}
            <li>
              <img src={iconLock} />
              <p>결제 비밀번호</p>
              <button
                onClick={() => {
                  navigate(routes.selfVerification, {
                    state: { purpose: "UPDATE_SECURE_PASSWORD" },
                  });
                }}
              >
                변경
              </button>
            </li>
            <li>
              <img src={iconPassbook} />
              <p>입출금 계좌</p>
              <button
                onClick={() => {
                  navigate(routes.myAccountManagement);
                }}
              >
                관리
              </button>
            </li>
          </ul>
        </div>
        <div className="menu_shadow88">
          <h3 className="menu_title">대리점</h3>
          <div className="menu_bshadow88_02 mb08">
            <h3 className="box_title">추천 대리점</h3>
            <div className="menu_box">
              <p>{userInfo?.recommendShopName}</p>
            </div>
          </div>
          <div className="menu_bshadow88_02 mb12">
            <h3 className="box_title">이용 대리점</h3>
            <ButtonBase
              className="menu_box select"
              style={{
                width: "100%",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                dispatch(openBottomSheet());
              }}
            >
              <p>{userInfo?.usingShopName}</p>
            </ButtonBase>
          </div>
        </div>
        <button
          className="logout_bt"
          onClick={() => {
            navigate(routes.leave);
          }}
        >
          탈퇴하기
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
