import { ch2pattern } from "@utils/utils";

import { GoodsListInfo } from "../hooks/useGoodsList";
import { SearchObject } from "./hooks/useSearch";

export const SEARCH = {
  DROP_DOWN_ITEMS: [
    "인기순",
    "신상품순",
    "낮은 가격순",
    "높은 가격순",
    "할인율순",
  ] as const,
  RECOMMEND_KEYWORDS: [
    "1.875g",
    "3.75g",
    "10g",
    "100g",
    // "0.5돈",
    // "1돈",
    // "2돈",
  ] as const,
};

export const sortByDropDown = (
  toShowGoodsList: GoodsListInfo | undefined,
  dropDownItem: (typeof SEARCH.DROP_DOWN_ITEMS)[number],
) => {
  switch (dropDownItem) {
    case "낮은 가격순": {
      return toShowGoodsList?.sort((a, b) => a.sellingPrice - b.sellingPrice);
    }
    case "높은 가격순": {
      return toShowGoodsList?.sort((a, b) => b.sellingPrice - a.sellingPrice);
    }
    case "신상품순": {
      return toShowGoodsList?.sort(
        (a, b) =>
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      );
    }
    case "인기순": {
      return toShowGoodsList?.sort((a, b) => b.salesCount - a.salesCount);
    }
    case "할인율순": {
      return toShowGoodsList?.sort((a, b) => b.discountRate - a.discountRate);
    }
  }
};

const createFuzzyMatcher = (input: any) => {
  const pattern = input.split("").map(ch2pattern).join(".*?");
  return new RegExp(pattern);
};

export const getMatchedItem = (
  searchObject: SearchObject,
  goodsListData: GoodsListInfo | undefined,
) => {
  // 검색 성공 시 반환
  if (searchObject.step === "SUCCESS" && goodsListData) {
    return goodsListData.filter(({ goodsName }) => {
      return createFuzzyMatcher(searchObject.lastSearched).test(goodsName);
    });
  }

  // 실패 시 반환
  return [];
};

const makeArrayItemsUnique = (incomeArray: string[]) => {
  return Array.from(new Set(incomeArray));
};

const sliceArrayTo = (incomeArray: string[], slicePoint: number) => {
  return incomeArray.slice(0, slicePoint);
};

export const toUniqueArrayWithLength = (
  incomeArray: string[],
  slicePoint: number,
) => {
  const uniqueArray = makeArrayItemsUnique(incomeArray);
  return sliceArrayTo(uniqueArray, slicePoint);
};
