import { routes } from "@/routes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@features/bottom-sheet/bottomSheetSlice";
import { selectFeeRate } from "@features/fee-rate/feeRateSlice";
import { showToast } from "@features/toast/toastSlice";
import { postExpectTrade } from "@services/apis";
import { numberWithCommas } from "@utils/utils";

import TradeModal, { ModalType } from "../../assetTrade/components/TradeModal";

interface OrderInputProps {
  marketPrice: any;
  minPrice: number;
  maxPrice: number;
  assetType?: string;
  tradeType: string; // "SELL" | "BUY";
  posibleprice?: number;
  inputs: any;
  setInputs: any;
  orderType: string; // Exclude<orderPriceType, "RECOMMEND">;
  setOrderType: any;
  authBlock: Function;
}

interface Inputs {
  price: string;
  amount: string;
}

const OrderInput: React.FC<OrderInputProps> = ({
  marketPrice,
  minPrice,
  maxPrice,
  assetType,
  tradeType,
  posibleprice,
  inputs,
  setInputs,
  orderType,
  setOrderType,
  authBlock,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useSelector((state: RootState) => state.user);
  const [baseWeight, setBaseWeight] = useState("g");
  const [modalType, setModalType] = useState<ModalType>("");
  const [possiblePriceDon, setPossiblePriceDon] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const { feeRate } = useSelector(selectFeeRate);
  const [fee, setFee] = useState(0);
  const [tempInputs, setTempInputs] = useState({ price: "", amount: "" });

  const getSellBuyTitle = (
    tradeType: OrderInputProps["tradeType"],
    orderType: OrderInputProps["orderType"],
  ) => {
    if (tradeType === "BUY") {
      if (orderType === "MARKET") {
        return "최종 구매 예상 금액";
      }
      return "최종 구매 금액";
    }
    if (orderType === "MARKET") {
      return "최종 판매 예상 금액";
    }
    return "최종 판매 금액";
  };

  const handleClickBaseWeight = (type: string) => {
    setInputs((prevInputs: Inputs) => ({
      ...prevInputs,
      amount: "",
    }));
    setBaseWeight(type);
  };

  const handleClickOrderType = (type: string) => {
    setInputs((prevInputs: Inputs) => ({
      ...prevInputs,
      price: "",
    }));
    setOrderType(type);
  };

  const onClickModal = (type: any) => {
    setModalType(type);
    dispatch(openBottomSheet());
  };

  const resetTempInputs = () => {
    setTempInputs({
      amount: "",
      price: "",
    });
  };

  const handleChangePrice = (type: string) => {
    let currentPrice = parseInt(inputs.price.replace(/\D/g, ""), 10);
    if (isNaN(currentPrice)) {
      currentPrice = 0; // 초기값이 빈 문자열일 때 0으로 설정
    }
    if (type === "plus") {
      if (assetType === "GOLD") {
        // 100 단위로 올림
        if (currentPrice % 100 !== 0) {
          currentPrice = Math.ceil(currentPrice / 100) * 100;
        } else {
          currentPrice += 100;
        }
      } else {
        // 5 단위로 올림
        if (currentPrice % 5 !== 0) {
          currentPrice = Math.ceil(currentPrice / 5) * 5;
        } else {
          currentPrice += 5;
        }
      }
    } else {
      if (assetType === "GOLD") {
        // 100 단위로 내림
        if (currentPrice % 100 !== 0) {
          currentPrice = Math.floor(currentPrice / 100) * 100;
        } else {
          currentPrice -= 100;
          if (currentPrice < 0) {
            currentPrice = 0;
          }
        }
      } else {
        // 5 단위로 올림
        if (currentPrice % 5 !== 0) {
          currentPrice = Math.floor(currentPrice / 5) * 5;
        } else {
          currentPrice -= 5;
          if (currentPrice < 0) {
            currentPrice = 0;
          }
        }
      }
    }
    setInputs((prevInputs: Inputs) => ({
      ...prevInputs,
      price: currentPrice.toString(),
    }));
  };
  const handleChangeAmount = (type: string) => {
    let currentAmount = parseFloat(inputs.amount.replace(/[^\d.]/g, ""));
    if (isNaN(currentAmount)) {
      currentAmount = 0; // 초기값이 빈 문자열일 때 0으로 설정
    }
    if (type === "plus") {
      currentAmount += 1;
    } else {
      currentAmount -= 1;
      if (currentAmount < 0) {
        currentAmount = 0;
      }
    }
    if (assetType === "GOLD" && baseWeight === "g") {
      currentAmount = parseFloat(currentAmount.toFixed(3));
    } else if (assetType === "GOLD" && baseWeight === "don") {
      currentAmount = parseFloat(currentAmount.toFixed(1));
    } else if (assetType === "SILVER" && baseWeight === "g") {
      currentAmount = parseFloat(currentAmount.toFixed(1));
    } else {
      currentAmount = parseFloat(String(currentAmount));
    }
    setInputs((prevInputs: Inputs) => ({
      ...prevInputs,
      amount: currentAmount.toString(),
    }));
  };

  const handleTrade = () => {
    if (!authBlock()) {
      return false;
    } else {
      // 시장가가 아닐때
      if (orderType !== "MARKET") {
        if (
          inputs.amount === "" ||
          inputs.price === "" ||
          inputs.amount === "0"
        ) {
          dispatch(
            showToast({ message: "값을 입력하여 주세요.", icon: "caution" }),
          );
        } else {
          setModalType("orderCheck");
          dispatch(openBottomSheet());
        }
      } else {
        // 시장가일 때
        if (inputs.amount === "" || inputs.amount === "0") {
          dispatch(
            showToast({ message: "값을 입력하여 주세요.", icon: "caution" }),
          );
        } else {
          setModalType("orderCheck");
          dispatch(openBottomSheet());
        }
      }
    }
  };

  useEffect(() => {
    if (orderType !== "MARKET") {
      let total;
      if (baseWeight === "g") {
        total = Math.floor(inputs.price * inputs.amount);
      } else {
        total = Math.floor(inputs.price * 3.75 * inputs.amount);
      }
      const feeRateValue =
        (feeRate.find(({ configType, assetType: asset }: any) => {
          return asset === assetType && configType === `${tradeType}`;
        }) || {})["feeRate"] || 0;
      let fee = 0;
      if (feeRateValue) fee = Math.floor(total * feeRateValue);
      if (tradeType === "SELL") fee = -fee;
      setFee(fee);
      setTotalPrice(total);
    } else if (inputs.amount !== "") {
      const payload = {
        tradeType: tradeType,
        assetType: assetType,
        orderType: orderType,
        requestGram:
          baseWeight === "g"
            ? inputs.amount
            : baseWeight === "don"
              ? `${(parseFloat(inputs.amount) * 3.75).toFixed(3)}`
              : inputs.amount,
      };
      postExpectTrade(payload).then(({ data: { success, data, message } }) => {
        if (success) {
          const fee = tradeType === "BUY" ? data.tradeFee : -data.tradeFee;
          // setInputs((prevInputs: Inputs) => ({
          //   ...prevInputs,
          //   price: data.orderPrice,
          // }));
          setTotalPrice(data?.tradeKrw);
          setFee(fee);
        } else {
          if (message) {
            dispatch(showToast({ message: message, icon: "error" }));
          }
        }
      });
    }
  }, [
    inputs.price,
    inputs.amount,
    assetType,
    tradeType,
    baseWeight,
    orderType,
  ]);

  useEffect(() => {
    if (posibleprice) {
      setPossiblePriceDon(Number((posibleprice / 3.75).toFixed(4)));
    }
  }, [posibleprice]);

  useEffect(() => {
    if (assetType === "SILVER") {
      setBaseWeight("g");
    }
  }, [assetType]);

  return (
    <>
      <div className="sell_wrap07_rt">
        <div className="sell_wrap07_rt_box">
          <div className="sell_wrap07_toptab01 sell_tab">
            <input
              type="radio"
              name="tabmenu"
              id="tab01"
              checked={orderType === "LIMITS" ? true : false}
              readOnly
            />
            <label
              htmlFor="tab01"
              onClick={() => handleClickOrderType("LIMITS")}
            >
              <p>지정가</p>
            </label>
            <input
              type="radio"
              name="tabmenu"
              id="tab02"
              checked={orderType === "MARKET" ? true : false}
              readOnly
            />
            <label
              htmlFor="tab02"
              onClick={() => handleClickOrderType("MARKET")}
            >
              <p>시장가</p>
            </label>
            <div className="tab_content01">
              <div className="block"></div>
              <div className="tab01_con conbox">
                <h3
                  onClick={() => {
                    resetTempInputs();
                    onClickModal("price");
                  }}
                >
                  <input
                    type="text"
                    placeholder="0원"
                    value={numberWithCommas(inputs.price)}
                    readOnly
                  />
                </h3>
                <ul>
                  <li
                    className="minus"
                    onClick={() => handleChangePrice("minus")}
                  >
                    -
                  </li>
                  <li className="bar"></li>
                  <li
                    className="plus"
                    onClick={() => handleChangePrice("plus")}
                  >
                    +
                  </li>
                </ul>
              </div>
              <div className="tab02_con conbox">
                <h3>즉시 체결 가격</h3>
                <ul>
                  <li className="minus">-</li>
                  <li className="bar"></li>
                  <li className="plus">+</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="limit_price">
            <p style={{ width: "100%" }}>
              1돈 가격:
              {marketPrice?.price &&
                numberWithCommas(Math.trunc(marketPrice?.price * 3.75))}
              원
            </p>
          </div>
          <div
            className={`sell_wrap07_toptab02 sell_tab ${
              assetType === "GOLD" ? "" : "silver"
            }`}
          >
            <input
              type="radio"
              name="tabmenu02"
              id="tabgram"
              checked={baseWeight === "g" ? true : false}
              readOnly
            />
            <label htmlFor="tabgram" onClick={() => handleClickBaseWeight("g")}>
              <p>
                <span>g</span>으로 거래
              </p>
            </label>
            {assetType === "GOLD" && (
              <>
                <input
                  type="radio"
                  name="tabmenu02"
                  id="tabdon"
                  checked={baseWeight === "don" ? true : false}
                  readOnly
                />
                <label
                  htmlFor="tabdon"
                  onClick={() => handleClickBaseWeight("don")}
                >
                  <p>
                    <span>돈</span>으로 거래
                  </p>
                </label>
              </>
            )}
            <div className="tab_content02">
              <div className="block"></div>
              <div
                className={`${
                  baseWeight === "g" ? "tabgram_con" : "tabdon_con"
                } conbox02`}
              >
                <h3
                  onClick={() => {
                    resetTempInputs();
                    onClickModal("amount");
                  }}
                >
                  <input
                    type="text"
                    value={inputs.amount}
                    placeholder={`${
                      assetType === "GOLD" && baseWeight === "g"
                        ? "최소 0.005"
                        : assetType === "GOLD" && baseWeight === "don"
                          ? "최소  0.1"
                          : assetType === "SILVER" && baseWeight === "g"
                            ? "최소 0.5"
                            : "최소 1"
                    }${baseWeight === "g" ? "g" : "돈"}`}
                    readOnly
                  />
                </h3>
                <ul>
                  <li
                    className="minus"
                    onClick={() => handleChangeAmount("minus")}
                  >
                    -1{baseWeight === "g" ? "g" : "돈"}
                  </li>
                  <li className="bar"></li>
                  <li
                    className="plus"
                    onClick={() => handleChangeAmount("plus")}
                  >
                    +1{baseWeight === "g" ? "g" : "돈"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="rt_box_price">
            {tradeType === "SELL" ? (
              <ul>
                <li>보유 중량</li>
                <li>
                  <p>
                    <span>
                      {posibleprice && numberWithCommas(posibleprice)}
                    </span>
                    g
                  </p>
                  <p className="don">{numberWithCommas(possiblePriceDon)}돈</p>
                </li>
              </ul>
            ) : (
              <ul className="tab02_ul">
                <li>구매 가능 KRW</li>
                <li>
                  <p>
                    <span>
                      {posibleprice && numberWithCommas(posibleprice)}
                    </span>
                    원
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
        {/* <div className="sell_wrap07_price">
          <ul>
            <li>{tradeType === "SELL" ? "판매 중량" : "구매 중량"}</li>
            <li>
              <p>
                <span>00.00</span>g
              </p>
              <p className="don">00,00돈</p>
            </li>
          </ul>
        </div> */}
        <div className="sell_wrap07_tprice">
          <h3>{getSellBuyTitle(tradeType, orderType)}</h3>
          <p>
            <span>{numberWithCommas(totalPrice + fee)}</span>원
          </p>
          <button
            className={`${tradeType === "SELL" ? "tab01_bt" : "tab02_bt"} ${
              orderType !== "MARKET"
                ? inputs.price !== "" &&
                  inputs.amount !== "" &&
                  inputs.amount !== "0"
                  ? "on"
                  : ""
                : inputs.amount !== "" && inputs.amount !== "0"
                  ? "on"
                  : ""
            }`}
            onClick={handleTrade}
          >
            {tradeType === "SELL" ? "판매하기" : "구매하기"}
          </button>
        </div>
      </div>
      <TradeModal
        modalType={modalType}
        baseWeight={baseWeight}
        inputs={tempInputs}
        resultInputs={inputs}
        assetType={assetType ? assetType : ""}
        posibleprice={posibleprice ? posibleprice : 0}
        minPrice={minPrice}
        maxPrice={maxPrice}
        selectOrderType={orderType}
        feeValue={fee}
        tradeType={tradeType}
        totalPrice={totalPrice}
        onClose={() => {
          setModalType("");
          dispatch(closeBottomSheet());
        }}
        onChangeOrderType={() => {}}
        onChangeInputs={(value: any, name: any) => {
          setTempInputs({
            ...tempInputs,
            [name]: value,
          });
        }}
        onChangeTemptoResult={(value: any, name: any) => {
          setInputs((prevInputs: any) => ({
            ...prevInputs,
            [name]: value,
          }));
        }}
        onChangeTotalPrice={(total: number, fee: number) => {
          setTotalPrice(total);
          setFee(fee);
        }}
        onClickConfirm={() => {
          navigate(routes.commonSecureKeypad, {
            state: {
              type: "TRADE",
              payload: {
                tradeType: tradeType,
                assetType: assetType,
                orderPrice: inputs.price,
                orderType: orderType,
                requestGram:
                  baseWeight === "g"
                    ? inputs.amount
                    : baseWeight === "don"
                      ? `${(parseFloat(inputs.amount) * 3.75).toFixed(3)}`
                      : inputs.amount,
                requestTotal: Math.floor(totalPrice),
                userId: id,
              },
            },
          });
        }}
      />
    </>
  );
};

export default OrderInput;
