import { numberWithCommas, truncateToThreeDecimalPlaces } from "@utils/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useTradeIngList, { ITradeIngResponse } from "../hooks/useTradeIngList";
import AssetCancel from "./AssetCancel";
import NoRecords from "./NoRecords";
import {
  AppraiseTradeCard,
  AssetSendReceiveCard,
  AssetTradeCard,
  CouponCard,
  DepositCard,
  EventCard,
  ShoppingCard,
  StorageFeeCard,
  TradeCardProps,
  WithdrawCard,
} from "./TradeCard";

interface TradeType {
  type: string;
  label: string;
  component: React.ComponentType<TradeCardProps>;
}

const ING_TRADE_TYPES_MAPPING: TradeType[] = [
  { type: "APPRAISAL", label: "감정평가", component: AppraiseTradeCard },
  { type: "GOODS", label: "쇼핑", component: ShoppingCard },
  { type: "SELL", label: "자산 팔래요", component: AssetTradeCard },
  { type: "BUY", label: "자산 살래요", component: AssetTradeCard },
  { type: "WITHDRAW", label: "출금", component: WithdrawCard },
];

const TRADE_TYPES_MAPPING: TradeType[] = [
  { type: "APPRAISAL", label: "감정평가", component: AppraiseTradeCard },
  { type: "GOODS", label: "쇼핑", component: ShoppingCard },
  { type: "SELL", label: "자산 팔래요", component: AssetTradeCard },
  { type: "BUY", label: "자산 살래요", component: AssetTradeCard },
  { type: "WITHDRAW", label: "출금", component: WithdrawCard },
  { type: "DEPOSIT", label: "입금", component: DepositCard },
  { type: "COUPON", label: "쿠폰", component: CouponCard },
  { type: "EVENT", label: "이벤트", component: EventCard },
  {
    type: "SEND_ASSET",
    label: "자산 보냄(상속)",
    component: AssetSendReceiveCard,
  },
  {
    type: "RECEIVE_ASSET",
    label: "자산 받음(상속)",
    component: AssetSendReceiveCard,
  },
  { type: "STORAGE_FEE", label: "보관료", component: StorageFeeCard },
];

interface IngTradeHistoryProps {
  openBottomSheet: (content: React.ReactNode) => void;
}

const IngTradeHistory: React.FC<IngTradeHistoryProps> = ({
  openBottomSheet,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTradeType = queryParams.get("tradeType") || "APPRAISAL";
  const initialStatusType = queryParams.get("statusType") || "ING";

  const [selectedTradeInfo, setSelectedTradeInfo] =
    useState<ITradeIngResponse | null>(null);
  const [selectedTradeType, setSelectedTradeType] =
    useState<string>(initialTradeType);

  const { TradeIngList, IngRefetch, noRecordsMessage, isFetched } =
    useTradeIngList();

  const prevStatusType = useRef(initialStatusType);

  useEffect(() => {
    if (
      initialStatusType === "ING" &&
      initialStatusType !== prevStatusType.current
    ) {
      IngRefetch();
      prevStatusType.current = initialStatusType;
    }
  }, [initialStatusType, selectedTradeType, IngRefetch]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tradeType", selectedTradeType);
    params.set("statusType", initialStatusType);

    navigate({ search: params.toString() }, { replace: true });
  }, [selectedTradeType, initialStatusType, navigate]);

  const filteredTradeList = (type: string) =>
    TradeIngList?.filter(
      (trade: ITradeIngResponse) => trade.tradeType === type,
    ) || [];

  const renderTradeCards = () => {
    if (filteredTradeList(selectedTradeType).length === 0) {
      return (
        <NoRecords
          noRecordsMessage={
            isFetched ? noRecordsMessage : "이용 내역 확인 예정입니다."
          }
        />
      );
    }
    return filteredTradeList(selectedTradeType).map((trade) => {
      const matchedComponent = TRADE_TYPES_MAPPING.find(
        (item) => item.type === trade.tradeType,
      );
      const Component = matchedComponent?.component!;
      return (
        <Component
          key={trade?.requestAt + trade.requestId}
          tradeInfo={trade}
          statusType={initialStatusType}
          onCancel={(tradeInfo: any) => {
            setSelectedTradeInfo(tradeInfo);
            openBottomSheet(
              <AssetCancel
                assetType={tradeInfo?.assetType}
                tradeType={tradeInfo?.tradeType}
                orderPrice={tradeInfo?.requestPrice ?? 0}
                requestGram={tradeInfo?.requestGram ?? 0}
                remainGram={numberWithCommas(
                  truncateToThreeDecimalPlaces(
                    (tradeInfo?.requestGram ?? 0) -
                      (tradeInfo?.contractGram ?? 0),
                  ),
                )}
                deleteId={tradeInfo?.requestId}
                refetch={IngRefetch}
              />,
            );
          }}
        />
      );
    });
  };

  const tradeTypeCounts = useMemo(() => {
    const counts: { [key: string]: number } = ING_TRADE_TYPES_MAPPING.reduce(
      (acc, curr) => {
        acc[curr.type] = 0;
        return acc;
      },
      {} as { [key: string]: number },
    );

    TradeIngList?.forEach((trade: ITradeIngResponse) => {
      if (counts.hasOwnProperty(trade.tradeType)) {
        counts[trade.tradeType]++;
      }
    });

    return counts;
  }, [TradeIngList]);

  return (
    <div>
      <div className="sub08_slide" style={{ backgroundColor: "#fafafa" }}>
        <ul>
          {ING_TRADE_TYPES_MAPPING.map(({ type, label }) => (
            <li
              key={type}
              className={selectedTradeType === type ? "on" : ""}
              onClick={() => setSelectedTradeType(type)}
            >
              <p>
                {label}
                <span className="bg-gr">{tradeTypeCounts[type]}</span>
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className={`scont_wrap`}>{renderTradeCards()}</div>
    </div>
  );
};

export default IngTradeHistory;
