import {
  closeBottomSheet,
  selectBottomSheet,
} from "@features/bottom-sheet/bottomSheetSlice";
import { SwipeableDrawer } from "@mui/material";
import {
  ASSET_TYPES,
  PERIODS,
  SORT_TYPES,
  TRADE_TYPES,
} from "@utils/constants";
import { formatTime } from "@utils/utils";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import IconAssets from "@assets/icons/ico_asset_24.png";
import IconCalendar from "@assets/icons/ico_calendar_24.png";
import iconCalendarBlack from "@assets/icons/ico_calendar_black.png";
import IconRefresh from "@assets/icons/ico_refresh_14.png";
import IconSort from "@assets/icons/ico_sort_24.png";
import IconTransaction from "@assets/icons/ico_transaction_24.png";
import IconX from "@assets/icons/icon_x.png";
import colors from "@assets/styles/colors.css";
import { drawerStyle } from "@components/common/bottom-sheet/style.css";
import DateRangePicker from "@components/common/calendar/DateRangePicker";

import { ICompleteParam } from "../hooks/useTradeCompleteList";
import * as styles from "../styles.css";

const convertToViewCalendarFormat = (dateData: string) => {
  return dateData.split("-").join(".");
};

const calculateDates = (period: string): { fromDt: string; toDt: string } => {
  const today: any = moment();
  let fromDt = today.clone();

  switch (period) {
    case "TODAY":
      return { fromDt: formatTime(today), toDt: formatTime(today) };
    case "WEEK":
      fromDt = today.clone().subtract(1, "weeks");
      break;
    case "MONTH":
      fromDt = today.clone().subtract(1, "months");
      break;
    case "3MONTH":
      fromDt = today.clone().subtract(3, "months");
      break;
    default:
      return { fromDt: "", toDt: "" };
  }

  return { fromDt: formatTime(fromDt), toDt: formatTime(today) };
};

const detectPeriod = (fromDt: string, toDt: string): SelectedPeriod => {
  const today = moment().startOf("day");
  const fromDate = moment(fromDt);
  const toDate = moment(toDt);

  if (fromDate.isSame(today) && toDate.isSame(today)) {
    return "TODAY";
  } else if (
    fromDate.isSame(today.clone().subtract(1, "weeks")) &&
    toDate.isSame(today)
  ) {
    return "WEEK";
  } else if (
    fromDate.isSame(today.clone().subtract(1, "months")) &&
    toDate.isSame(today)
  ) {
    return "MONTH";
  } else if (
    fromDate.isSame(today.clone().subtract(3, "months")) &&
    toDate.isSame(today)
  ) {
    return "3MONTH";
  } else {
    return "CUSTOM";
  }
};

interface TradeFilterCalendarDrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  toggleDrawer: (newOpen: boolean) => () => void;
}

const TradeFilterCalendarDrawer: React.FC<TradeFilterCalendarDrawerProps> = ({
  isOpen,
  children,
  toggleDrawer,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      disableSwipeToOpen
      open={isOpen}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      classes={{ paper: drawerStyle }}
    >
      {children}
    </SwipeableDrawer>
  );
};

interface TradeFilterProps {
  onConfirm: (param: ICompleteParam, period: string, sort: string) => void;
  statusType: string;
}

type SelectedPeriod = (typeof PERIODS)[number]["value"];

interface CalendarState {
  isStart: boolean; // start 인지 end인지 판별 여부
  isOpen: boolean;
  start: string | null;
  end: string | null;
}

const TradeFilter: React.FC<TradeFilterProps> = ({ onConfirm, statusType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useSelector(selectBottomSheet);

  // URL에서 completeParam 가져오기
  const queryParams = new URLSearchParams(location.search);
  const initialCompleteParam = useMemo(() => {
    const savedParams = queryParams.get("completeParam");
    return savedParams ? JSON.parse(savedParams) : null;
  }, [queryParams]);

  const initialPeriod = initialCompleteParam
    ? detectPeriod(initialCompleteParam.fromDt, initialCompleteParam.toDt)
    : "3MONTH";

  const [selectedPeriod, setSelectedPeriod] =
    useState<SelectedPeriod>(initialPeriod);
  const [selectedTradeTypes, setSelectedTradeTypes] = useState<string[]>(
    initialCompleteParam?.tradeTypeList?.length
      ? initialCompleteParam.tradeTypeList
      : [...TRADE_TYPES.map((t) => t.type)],
  );
  const [selectedAssetTypes, setSelectedAssetTypes] = useState<string[]>(
    initialCompleteParam?.assetTypeList?.length
      ? initialCompleteParam.assetTypeList
      : [...ASSET_TYPES.map((t) => t.type)],
  );
  const [sortOrder, setSortOrder] = useState<string>(
    initialCompleteParam?.sortType || "DESC",
  );
  const [calendarStates, setCalendarStates] = useState<CalendarState>({
    isStart: true,
    isOpen: false,
    start: initialCompleteParam?.fromDt || null,
    end: initialCompleteParam?.toDt || null,
  });

  useEffect(() => {
    // URL에서 completeParam 가져오기
    const queryParams = new URLSearchParams(location.search);
    const savedParams = queryParams.get("completeParam");
    const parsedParams = savedParams ? JSON.parse(savedParams) : null;

    if (parsedParams) {
      setSelectedPeriod(detectPeriod(parsedParams.fromDt, parsedParams.toDt));

      // 거래 유형이 빈 배열이면 전체 선택
      setSelectedTradeTypes(
        parsedParams.tradeTypeList?.length
          ? parsedParams.tradeTypeList
          : [...TRADE_TYPES.map((t) => t.type)],
      );

      // 거래 자산이 빈 배열이면 전체 선택
      setSelectedAssetTypes(
        parsedParams.assetTypeList?.length
          ? parsedParams.assetTypeList
          : [...ASSET_TYPES.map((t) => t.type)],
      );

      setSortOrder(parsedParams.sortType || "DESC");
      setCalendarStates({
        isStart: true,
        isOpen: false,
        start: parsedParams.fromDt || null,
        end: parsedParams.toDt || null,
      });
    } else {
      // params 없으면 초기화
      setSelectedPeriod("3MONTH");
      setSelectedTradeTypes([...TRADE_TYPES.map((t) => t.type)]);
      setSelectedAssetTypes([...ASSET_TYPES.map((t) => t.type)]);
      setSortOrder("DESC");
      setCalendarStates({
        isStart: true,
        isOpen: false,
        start: null,
        end: null,
      });
    }
  }, [statusType, location.search]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setCalendarStates((prev) => ({ ...prev, isOpen: newOpen }));
  };

  useEffect(() => {
    if (contentRef.current) {
      // 바텀시트 열리면 상단 이동
      contentRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  const handleCalendarOpen = (isFirst: boolean) => {
    setCalendarStates((prev) => ({ ...prev, isStart: isFirst, isOpen: true }));
  };

  const handleCalendarResult = (calendarOutcome: string, isStart: boolean) => {
    if (isStart) {
      setCalendarStates((prev) => ({
        ...prev,
        start: calendarOutcome,
        isOpen: false,
      }));
    } else {
      setCalendarStates((prev) => ({
        ...prev,
        end: calendarOutcome,
        isOpen: false,
      }));
    }
  };

  const handleToggleTradeType = (type: string) => {
    setSelectedTradeTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type],
    );
  };

  const handleToggleAssetType = (type: string) => {
    setSelectedAssetTypes((prev) =>
      prev.includes(type) ? prev.filter((a) => a !== type) : [...prev, type],
    );
  };

  const handleSelectAllTradeTypes = () => {
    setSelectedTradeTypes((prev) =>
      prev.length === TRADE_TYPES.length
        ? []
        : [...TRADE_TYPES.map((t) => t.type)],
    );
  };

  const handleSelectAllAssetTypes = () => {
    setSelectedAssetTypes((prev) =>
      prev.length === ASSET_TYPES.length
        ? []
        : [...ASSET_TYPES.map((a) => a.type)],
    );
  };

  const handleConfirm = () => {
    const baseFilter = {
      assetTypeList: selectedAssetTypes,
      tradeTypeList: selectedTradeTypes,
      page: 0,
      size: 30,
      sortType: sortOrder,
    };

    if (selectedPeriod !== "CUSTOM") {
      const { fromDt, toDt } = calculateDates(selectedPeriod);
      const newFilter: ICompleteParam = { ...baseFilter, fromDt, toDt };
      onConfirm(newFilter, selectedPeriod, sortOrder);
    } else if (calendarStates.start && calendarStates.end) {
      const { start, end } = calendarStates;
      const newFilter: ICompleteParam = {
        ...baseFilter,
        fromDt: start,
        toDt: end,
      };
      onConfirm(newFilter, selectedPeriod, sortOrder);
    }

    dispatch(closeBottomSheet());
  };

  return (
    <div>
      <div className="sub_bottom_wrap02">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">조회 옵션</h3>
          <button
            className={styles.resetBtn}
            onClick={() => {
              setSelectedPeriod("3MONTH");
              setSelectedTradeTypes([...TRADE_TYPES.map((t) => t.type)]);
              setSelectedAssetTypes([...ASSET_TYPES.map((t) => t.type)]);
              setSortOrder("DESC");
            }}
          >
            <img
              className={styles.resetIcon}
              src={IconRefresh}
              alt="초기화 아이콘"
            />{" "}
            초기화
          </button>
          <img
            src={IconX}
            alt="닫기 아이콘"
            className="close"
            onClick={() => dispatch(closeBottomSheet())}
          />
        </div>
        <div ref={contentRef} style={{ overflowY: "auto", maxHeight: "60vh" }}>
          <div className="option_wrap">
            <div className="option_title">
              <img src={IconCalendar} alt="캘린더 아이콘" />
              <h3>기간 선택</h3>
            </div>
            <ul>
              {PERIODS.map((period, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    name="period"
                    id={`period${index}`}
                    checked={selectedPeriod === period.value}
                    onChange={() => setSelectedPeriod(period.value)}
                  />
                  <label htmlFor={`period${index}`}>
                    <p>{period.label}</p>
                  </label>
                </li>
              ))}
            </ul>
            {selectedPeriod === "CUSTOM" && (
              <div className={styles.dateRange.box}>
                <div
                  className={styles.dateRange.calendarInfo}
                  onClick={() => handleCalendarOpen(true)}
                >
                  <img
                    className={styles.dateRange.calendarIcon}
                    src={iconCalendarBlack}
                  />
                  <span className={styles.dateRange.calendarText}>
                    {!calendarStates.start
                      ? "0000.00.00"
                      : convertToViewCalendarFormat(calendarStates.start)}
                  </span>
                </div>
                <div>~</div>
                <div
                  className={styles.dateRange.calendarInfo}
                  onClick={() => handleCalendarOpen(false)}
                >
                  <img
                    className={styles.dateRange.calendarIcon}
                    src={iconCalendarBlack}
                  />
                  <span className={styles.dateRange.calendarText}>
                    {!calendarStates.end
                      ? "0000.00.00"
                      : convertToViewCalendarFormat(calendarStates.end)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="option_wrap">
            <div className="option_title">
              <img src={IconTransaction} alt="거래 아이콘" />
              <h3>거래 유형</h3>
            </div>
            <ul>
              <li>
                <input
                  type="checkbox"
                  name="transaction"
                  id="transactionAll"
                  checked={selectedTradeTypes.length === TRADE_TYPES.length}
                  onChange={handleSelectAllTradeTypes}
                />
                <label htmlFor="transactionAll">
                  <p>전체</p>
                </label>
              </li>
              {TRADE_TYPES.map((trade, index) => (
                <li key={trade.type}>
                  <input
                    type="checkbox"
                    name="transaction"
                    id={`transaction${index}`}
                    checked={selectedTradeTypes.includes(trade.type)}
                    onChange={() => handleToggleTradeType(trade.type)}
                  />
                  <label htmlFor={`transaction${index}`}>
                    <p>{trade.label}</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="option_wrap">
            <div className="option_title">
              <img src={IconAssets} alt="자산 아이콘" />
              <h3>거래 자산</h3>
            </div>
            <ul>
              <li>
                <input
                  type="checkbox"
                  name="asset"
                  id="assetAll"
                  checked={selectedAssetTypes.length === ASSET_TYPES.length}
                  onChange={handleSelectAllAssetTypes}
                />
                <label htmlFor="assetAll">
                  <p>전체</p>
                </label>
              </li>
              {ASSET_TYPES.map((asset, index) => (
                <li key={asset.type}>
                  <input
                    type="checkbox"
                    name="asset"
                    id={`asset${index}`}
                    checked={selectedAssetTypes.includes(asset.type)}
                    onChange={() => handleToggleAssetType(asset.type)}
                  />
                  <label htmlFor={`asset${index}`}>
                    <p>{asset.label}</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="option_wrap">
            <div className="option_title">
              <img src={IconSort} alt="정렬 아이콘" />
              <h3>정렬 순서</h3>
            </div>
            <ul>
              {SORT_TYPES.map((sort, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    name="sort"
                    id={`sort${index}`}
                    checked={sortOrder === sort.value}
                    onChange={() => setSortOrder(sort.value)}
                  />
                  <label htmlFor={`sort${index}`}>
                    <p>{sort.label}</p>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.tradeFilterBtnWrap}>
        <button
          className={styles.confirmBtn}
          onClick={handleConfirm}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          확인
        </button>
      </div>
      <TradeFilterCalendarDrawer
        isOpen={calendarStates.isOpen}
        toggleDrawer={toggleDrawer}
      >
        <DateRangePicker
          isStart={calendarStates.isStart}
          datePicked={
            calendarStates.isStart ? calendarStates.start : calendarStates.end
          }
          oppositeDate={
            calendarStates.isStart ? calendarStates.end : calendarStates.start
          }
          handleDatePicked={handleCalendarResult}
        />
      </TradeFilterCalendarDrawer>
    </div>
  );
};

export default TradeFilter;
