import { formatTime } from "@utils/utils";
import base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";
import React, { useEffect, useMemo, useRef, useState } from "react";

export default function useCryptoKeyboard() {
  const [secureItems, setSecureItems] = useState([]);
  const pressFlag = useRef(false);

  const digest = async (raw) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(raw);
    const hash = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  };

  const pressKey = async (key) => {
    if (pressFlag.current || secureItems.length >= 6) {
      return false;
    }

    pressFlag.current = true;

    const raw = (last || "") + key;
    const hashHex = await digest(raw);
    setSecureItems([...secureItems, hashHex]);

    pressFlag.current = false;

    return true;
  };

  const removeOne = () => {
    if (!secureItems.length) {
      return false;
    }

    setSecureItems((secureItems) => {
      return secureItems.slice(0, -1);
    });

    return true;
  };

  const clearAll = () => {
    setSecureItems([]);
  };

  const getResult = () => {
    if (secureItems.length !== 6) {
      return null;
    }

    return btoa(formatTime("YYYYMMDDHHmmss") + "_" + secureItems[5]);
  };

  const result = getResult();
  const count = secureItems.length;
  const last = secureItems[secureItems.length - 1] || "";

  const secured = { result, count, last };
  const actions = { pressKey, removeOne, clearAll };

  return {
    secured,
    actions,
  };
}
