import { closeBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import constants from "@utils/constants";
import { useDispatch } from "react-redux";

import cate_ico_all_36 from "@assets/images/cate_ico_all_36.png";
import cate_ico_bracelet_36 from "@assets/images/cate_ico_bracelet_36.png";
import cate_ico_bracelet_baby_36 from "@assets/images/cate_ico_bracelet_baby_36.png";
import cate_ico_gold_36 from "@assets/images/cate_ico_gold_36.png";
import cate_ico_necklace_36 from "@assets/images/cate_ico_necklace_36.png";
import cate_ico_ring_36 from "@assets/images/cate_ico_ring_36.png";
import cate_ico_ring_baby_36 from "@assets/images/cate_ico_ring_baby_36.png";
import cate_ico_silver_36 from "@assets/images/cate_ico_silver_36.png";
import cate_ico_star_36 from "@assets/images/cate_ico_star_36.png";
import { BottomSheet } from "@components/index";

import useGoodsCategory from "../hooks/useGoodsCategory";

const CATEGORY = [
  { src: cate_ico_gold_36, value: "골드바" },
  { src: cate_ico_silver_36, value: "실버바" },
  { src: cate_ico_ring_baby_36, value: "돌반지" },
  { src: cate_ico_bracelet_baby_36, value: "돌팔찌" },
  { src: cate_ico_ring_36, value: "순금 반지" },
  { src: cate_ico_bracelet_36, value: "순금 팔찌" },
  { src: cate_ico_necklace_36, value: "순금 목걸이" },
  { src: cate_ico_star_36, value: "순금 기념품" },
  { src: cate_ico_all_36, value: "전체" },
];

interface CategoryBottomSheetProps {
  tab: number;
  handleTab: (newTab: number) => void;
}

const CategoryBottomSheet = (props: CategoryBottomSheetProps) => {
  const { tab, handleTab } = props;

  const { data: goodsCategoryData } = useGoodsCategory();

  const dispatch = useDispatch();
  const hanldeCategoryClick = (newTab: number) => {
    handleTab(newTab);
    dispatch(closeBottomSheet());
  };
  return (
    <BottomSheet>
      <div className="sub_bottom category_tab">
        <div className="sub_bottom_wrap">
          <div className="sub_top01">
            <h3 className="sub_bottom_tit">카테고리</h3>
          </div>
          <ul className="category_list">
            {goodsCategoryData?.map((item) => {
              const isOn = tab === item.goodsCategoryId;
              return (
                <li
                  className={isOn ? "on" : ""}
                  key={item.goodsCategoryId}
                  onClick={() => hanldeCategoryClick(item.goodsCategoryId)}
                >
                  <img
                    src={constants.s3BaseUrl + item.image}
                    alt={`${item.kind}_image`}
                  />
                  <p>{item.kind}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </BottomSheet>
  );
};

export default CategoryBottomSheet;
