import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import * as APIS from "@services/apis";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import iconLeft from "@assets/icons/icon_left.png";

import ActionButtonArea from "./ActionButtonArea";
import AfterAsset from "./AfterAsset";
import DetailInfo from "./DetailInfo";
import ProgressArea from "./ProgressArea";
import StatusTopArea from "./StatusTopArea";

interface DetailProps {
  status: string;
  requestMethod: string;
  afterGold: number;
  afterSilver: number;
  afterKrw: number;
  afterPoint: number;
}

const initialState: DetailProps = {
  status: "",
  requestMethod: "",
  afterGold: 0,
  afterSilver: 0,
  afterKrw: 0,
  afterPoint: 0,
};

const AppraiseDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, statusType } = state;
  const [detail, setDetail] = useState<DetailProps>(initialState);

  useEffect(() => {
    fetchAppriaisalOrder();
  }, [state, id]);

  const fetchAppriaisalOrder = () => {
    dispatch(showLoading());
    APIS.getAppraisalRequestDetail(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setDetail(data);
        } else {
          dispatch(showToast({ message, icon: "error" }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const { status, requestMethod } = detail;
  /**
   * requestMethod
   * SIMPLE_VISIT("내방"),
   * DIRECT_VISIT("대리점"),
   * SIMPLE_DELIVERY("택배"),
   */
  return (
    <div>
      <div className="sub_top04">
        <div>
          <img
            src={iconLeft}
            alt="back"
            className="icon_back"
            onClick={() => {
              navigate(-1);
            }}
          />
          <p>감정평가 내역</p>
        </div>
      </div>
      <StatusTopArea status={status} requestMethod={requestMethod} />
      <div className="sub_wrap08 progress" style={{ marginBottom: "12.5px" }}>
        <ProgressArea status={status} requestMethod={requestMethod} />
        <DetailInfo detail={detail} setDetail={setDetail} />
        {statusType === "COMPLETE" ? (
          <AfterAsset
            afterGold={detail.afterGold}
            afterSilver={detail.afterSilver}
            afterKrw={detail.afterKrw}
            afterPoint={detail.afterPoint}
          />
        ) : (
          <ActionButtonArea detail={detail} />
        )}
      </div>
    </div>
  );
};

export default AppraiseDetail;
