import iconLeft from "../../assets/icons/icon_left.png";
import iconX from "../../assets/icons/icon_x.png";

interface HeaderType {
  title: string;
  cancelCallback?: Function;
  backCallback?: Function;
  style?: React.CSSProperties
}
export default function DetailHeader({
  title,
  cancelCallback,
  backCallback,
  style,
}: HeaderType) {
  return (
    <div className="sub_top" style={style}>
      <div>
        {backCallback ? (
          <img
            src={iconLeft}
            alt="back"
            className="icon_back"
            onClick={() => {
              backCallback();
            }}
          />
        ) : (
          <div
            className="padding"
            style={{
              width: "24px",
            }}
          ></div>
        )}
        <p>{title}</p>
      </div>
      {cancelCallback && (
        <img
          src={iconX}
          alt="close"
          className="close"
          onClick={() => {
            cancelCallback();
          }}
        />
      )}
    </div>
  );
}
