import moment from "moment";
import { useEffect, useState } from "react";

import { subBtnWrap, subWrap02Btn } from "@pages/buy/styles.css";

import iconArrowLeft from "../../../assets/icons/ico_arrow_left_c.png";
import iconArrowRight from "../../../assets/icons/ico_arrow_right_c.png";
import { calcDate, formatTime } from "../../../utils/utils";
import { useWorkingDays2 } from "./Calendar.hooks";

interface ReservationDataProps {
  shopId?: number | null;
  visitDate?: any;
}
interface CalendarProps {
  reservationData: ReservationDataProps;
  setReservationData: (result: Record<string, unknown>) => void;
  earliestVisitDate?: string;
}

const DAYS_OF_WEEK = ["일", "월", "화", "수", "목", "금", "토"];

const Calendar: React.FC<CalendarProps> = ({
  reservationData,
  setReservationData,
  earliestVisitDate = "",
}) => {
  const { visitDate, shopId } = reservationData;

  const [month, setMonth] = useState(() => {
    if (reservationData.visitDate) {
      return new Date(reservationData.visitDate);
    }
    if (earliestVisitDate) {
      return new Date(earliestVisitDate);
    }
    return new Date();
  });

  const { selectedDate, setSelectedDate, workingDays } = useWorkingDays2(
    shopId,
    month,
    reservationData,
    setMonth,
    earliestVisitDate,
  );

  const minDate = earliestVisitDate
    ? new Date(earliestVisitDate) > new Date()
      ? new Date(earliestVisitDate)
      : new Date()
    : new Date();
  const maxDate = calcDate("M", 1);
  const holidays = workingDays
    ?.filter(({ isFullDayOff }) => isFullDayOff)
    .map(({ date }) => date);

  const handleChangeMonth = (month: Date) => {
    setMonth(month);
  };

  const handleChangeDate = (date: any) => {
    const schedule =
      workingDays?.find(
        ({ date: workingDate }) => workingDate === formatTime(date),
      ) || {};

    setReservationData({
      ...reservationData,
      ...schedule,
      visitDate: formatTime(date),
    });
  };

  // generate 함수: 각 날짜 셀을 생성하며, isChecked 조건으로 선택 상태를 판단합니다
  const generate = () => {
    const today = moment(month);
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <tr className="date" key={`${week}-${endWeek}`}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .startOf("year")
                .week(week)
                .startOf("week")
                .add(n + i, "day");

              const dateString = formatTime(current.toDate());

              const outOfRange =
                (minDate && formatTime(minDate) > dateString) ||
                (maxDate && formatTime(maxDate) < dateString);

              const isOtherMonth = current.format("M") !== today.format("M");
              const isChecked = dateString === formatTime(selectedDate);

              const isDisabled = holidays?.includes(dateString) || outOfRange;
              const isToday = current.isSame(new Date(), "day");

              return (
                <td
                  key={n + i}
                  className={`${isChecked && !isOtherMonth ? "selected" : ""} ${
                    isDisabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    !isOtherMonth &&
                      !isDisabled &&
                      setSelectedDate(current.toDate());
                  }}
                >
                  <p>{!isOtherMonth && current.format("D")}</p>
                  {isToday && !isOtherMonth && (
                    <span
                      className="cal_today"
                      style={{
                        color: `${
                          formatTime(selectedDate) === dateString
                            ? "#ffffff"
                            : "#00ABF6"
                        }`,
                      }}
                    >
                      오늘
                    </span>
                  )}
                </td>
              );
            })}
        </tr>,
      );
    }
    return calendar;
  };

  useEffect(() => {
    if (visitDate) {
      if (
        (minDate && formatTime(visitDate) < formatTime(minDate)) ||
        (maxDate && formatTime(visitDate) > formatTime(maxDate))
      ) {
        handleChangeDate(null);
      }
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    if (visitDate && holidays?.includes(formatTime(visitDate))) {
      handleChangeDate(null);
    }
  }, [holidays]);

  return (
    <>
      <div className="sub_calendar">
        <div className="sub_calendar_top">
          <button onClick={() => handleChangeMonth(calcDate("M", -1, month))}>
            <img src={iconArrowLeft} alt="" />
          </button>
          <h3>
            {formatTime(month, "YYYY")
              .split("")
              .map((c, i) => (
                <td key={i} className="spoqa">
                  <p>{c}</p>
                </td>
              ))}
            <td>년</td>
            {formatTime(month, "MM")
              .split("")
              .map((c, i) => (
                <td key={i} className="spoqa">
                  <p>{c}</p>
                </td>
              ))}
            <td>월</td>
          </h3>
          <button onClick={() => handleChangeMonth(calcDate("M", 1, month))}>
            <img src={iconArrowRight} alt="" />
          </button>
        </div>
        <div className="sub_calendar_tb">
          <table>
            <thead>
              <tr>
                {DAYS_OF_WEEK.map((day, index) => (
                  <td
                    className={`${
                      index === 0 ? "sun" : index === 6 ? "sat" : ""
                    }`}
                    key={index}
                  >
                    <p>{day}</p>
                  </td>
                ))}
              </tr>
            </thead>
            {generate()}
          </table>
        </div>
      </div>
      <div className={subBtnWrap}>
        <button
          className={subWrap02Btn}
          onClick={() => handleChangeDate(selectedDate)}
        >
          선택
        </button>
      </div>
    </>
  );
};

export default Calendar;
