import { routes } from "@/routes";
import { setLocalStorageItem } from "@services/storageService";
import { numberWithCommas } from "@utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";

import iconDown from "@assets/icons/ico_down.png";
import iconUp from "@assets/icons/ico_up.png";
// import iconSame from "@assets/icons/icon_same.png";
import iconX from "@assets/icons/icon_x.png";

import * as styles from "../styles.css";

interface HeaderProps {
  assetType: string;
  setAssetType: React.Dispatch<React.SetStateAction<string>>;
  marketPrice: any;
  tradeType: string;
  isFromMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  assetType,
  setAssetType,
  marketPrice,
  tradeType,
  isFromMenu,
}) => {
  const navigate = useNavigate();

  const handleClickAssetType = (value: string) => {
    setAssetType(value);
    setLocalStorageItem("tradingAssetType", value);
  };

  const handleClickClose = () => {
    // if (isFromMenu) {
    navigate(-1);
    // } else {
    //   navigate(routes.tradeAsset, {
    //     state: {
    //       tradeType: tradeType,
    //       assetType: assetType,
    //     },
    //     replace: true,
    //   });
    // }
  };

  return (
    <div>
      <div
        className={`price_top ${
          marketPrice?.updown === "up"
            ? "up"
            : marketPrice?.updown === "down"
              ? "down"
              : "same"
        }`}
      >
        <div className={styles.homeBtn}>
          <ul className={styles.homeBtnUl}>
            <li
              className={`${styles.homeBtnLi} ${
                assetType === "GOLD" ? styles.homeBtnGoldOn : ""
              }`}
              onClick={() => {
                handleClickAssetType("GOLD");
              }}
            >
              금
            </li>
            <li
              className={`${styles.homeBtnLi} ${
                assetType === "SILVER" ? styles.homeBtnSilverOn : ""
              }`}
              onClick={() => {
                handleClickAssetType("SILVER");
              }}
            >
              은
            </li>
          </ul>
        </div>
        <h3>
          {marketPrice?.price && numberWithCommas(marketPrice?.price)}원
          <span>/1g</span>
        </h3>
        <span>
          {marketPrice?.updown !== "same" && (
            <img
              src={`${marketPrice?.updown === "up" ? iconUp : iconDown}`}
              alt=""
            />
          )}
          {marketPrice?.sumPrice && numberWithCommas(marketPrice?.sumPrice)}원 (
          {marketPrice?.sumper}%)
        </span>
      </div>
      <img src={iconX} alt="" className="close" onClick={handleClickClose} />
    </div>
  );
};

export default Header;
