import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { getCurrentMyPosition, getDistanceFromLatLonInKm } from "@utils/utils";
import { useDispatch } from "react-redux";

export interface IPosition {
  lat: number;
  lng: number;
}

export interface IShopInfo {
  id: number;
  name: string;
  phoneNumber: string;
  fax: null | string;
  city: string;
  address: string;
  addressDetail: string;
  district: string;
  latitude: number;
  longitude: number;
  shopType: string;
  visibleOrder: null | number;
  postCode: string;
  distance: number;
}
interface UseGetShopProps {
  goodsId?: string;
  service: string;
}

const useGetShop = ({ goodsId, service = "" }: UseGetShopProps) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      // 사용자 위치 조회
      const position = await getCurrentMyPosition()
        .then((position: any) => {
          return position;
        })
        .catch((position) => {
          return position;
        });
      const { lat, lng } = position;

      let response;
      if (service === "appraisal") {
        response = await APIS.getAppraisalShop();
      } else {
        if (goodsId !== undefined && goodsId !== null) {
          response = await APIS.getShop(goodsId);
        }
      }

      if (response) {
        let shops = response.data.data.map((shop: IShopInfo) => {
          if (shop.latitude && shop.longitude && lat && lng) {
            const distance = getDistanceFromLatLonInKm(
              lat,
              lng,
              shop.latitude,
              shop.longitude,
            );
            return { ...shop, distance: Number(distance.toFixed(2)) };
          }
          return shop;
        });
        shops.sort((a: IShopInfo, b: IShopInfo) => a.distance - b.distance);
        dispatch(hideLoading());
        return shops;
      }
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };
  const queryKey = service === "buy" ? [service, { goodsId }] : [service];

  const { data, error, isLoading } = useCustomQuery<IShopInfo[]>(
    queryKey,
    fetchDetails,
  );

  return { ShopInfo: data, isLoading, error };
};

export default useGetShop;
