import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import * as APIS from "@services/apis";
import constants from "@utils/constants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BtnFile from "@assets/icons/btn_file.png";
import BtnSend from "@assets/icons/btn_send.png";
import Icon14 from "@assets/icons/ico_14px.png";
import IconLeft from "@assets/icons/icon_left.png";
import BtnClear from "@assets/images/btn_clear.png";

import { imageWrap } from "./styles.css";

interface ConversationItem {
  content: string;
  contentType: string;
  createdAt: string;
  id: string;
  managerName: string;
  qnAWriter: string;
  userId: any;
  userName: string;
  userSocialId: any;
}

interface CustomFile extends File {
  uri?: string | ArrayBuffer | null;
}
const Inquire = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contentHeight, setContentHeight] = useState(0);
  const [pastConversation, setPastConversation] = useState<{
    [key: string]: ConversationItem[];
  }>({});
  const [message, setMessage] = useState(""); //메시지 내용
  const [qnaImage, setQnaImage] = useState<CustomFile | null>(null); // 이미지 첨부 내용
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    !contentHeight && setContentHeight(contentRef.current?.offsetHeight || 0);
    fetchItem();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [pastConversation]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const fetchItem = () => {
    dispatch(showLoading());
    APIS.getQnaList()
      .then(({ data: { success, data } }) => {
        if (success) {
          data.unshift({
            content: `안녕하세요. 금방금방입니다.
            무엇을 도와드릴까요?
            
            채팅 상담 운영시간
            월~금 09:00-17:00 | 주말·공휴일 휴무
            
            문의 사항을 남겨주시면 위 운영시간 내에 답변을 드리고 있으니 양해 부탁드립니다.`,
            contentType: "TEXT",
            createdAt: "",
            id: "",
            managerName: "MANAGER",
            qnAWriter: "MANAGER",
            userId: null,
            userName: null,
            userSocialId: null,
          });
          setPastConversation(reformData(data));
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const reformData = (data: any): { [key: string]: ConversationItem[] } => {
    const itemData: ConversationItem[] = [...data] || [];
    const changePastConversation: (ConversationItem & { day: string })[] =
      itemData.map((item, key) => {
        return { ...item, day: item?.["createdAt"].split(" ")[0] };
      });
    const dayList = changePastConversation.map((item, key) => {
      return { day: item?.day };
    });

    const deduplicationDay = dayList.filter((dayName, idx, arr) => {
      return arr.findIndex((item) => item.day === dayName.day) === idx;
    });

    let parseData: { [key: string]: ConversationItem[] } = {};
    let arrayData: ConversationItem[] = [];

    deduplicationDay.forEach((day) => {
      // day 날짜 필터링
      changePastConversation.forEach((item) => {
        if (item.day === day.day) {
          arrayData.push(item);
          parseData[day.day] = [...arrayData];
        }
      });
      arrayData = [];
    });

    return parseData;
  };

  const validationData = () => {
    if (message === "") {
      return "내용을 입력하여 주세요";
    }
    return "";
  };

  const handleClickRegister = () => {
    if (!qnaImage) {
      const errorMessage = validationData();
      if (errorMessage) {
        dispatch(showToast({ message: errorMessage, icon: "caution" }));
        return false;
      }
    }
    const json = JSON.stringify({
      content: message,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("qna", blob);

    if (qnaImage) {
      formData.append("image", qnaImage);
    } else {
      formData.append("image", ""); // 빈 Blob을 추가하여 빈 이미지를 처리
    }

    dispatch(showLoading());
    APIS.postQnaRegister(formData)
      .then(({ data: { success, data } }) => {
        if (!success) {
          dispatch(
            showToast({
              message: "문의 접수에 실패하였습니다",
              icon: "caution",
            }),
          );
        } else {
          setMessage("");
          setQnaImage(null);
          data.unshift({
            content: "궁금하신 사항을 입력해 주세요",
            contentType: "TEXT",
            createdAt: "",
            id: "",
            managerName: "MANAGER",
            qnaWriter: "MANAGER",
            userId: null,
            userName: null,
            userSocialId: null,
          });

          setPastConversation(reformData(data));
        }
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  const onChangeImage = (e: any) => {
    const { name } = e.target;
    const file = e.target.files[0] as CustomFile;
    if (file) {
      if (!file.type.match(/image-*/)) {
        dispatch(
          showToast({ message: "등록할 수 없는 파일입니다.", icon: "caution" }),
        );
        return;
      }
      const fs = new FileReader();
      fs.onloadend = () => {
        file.uri = fs.result;
        if (name === "qnaImage") {
          setQnaImage(file);
          setMessage("");
        }
      };
      fs.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 500) {
      return dispatch(
        showToast({ message: "최대 글자수는 500자입니다.", icon: "caution" }),
      );
    }
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = scrollHeight + "px";
    }
    setMessage(value);
  };

  return (
    <div className="qna_wrap">
      <div className="sub_top05">
        {/* <!--스크롤 할떄 적용~ className="sub_top05 shadow"--> */}
        <div>
          <img
            src={IconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>1:1 문의</p>
        </div>
      </div>
      {/* 대화 내용창 */}
      {contentHeight ? (
        <>
          <div
            className="qa_wrap"
            id="chatList"
            onLoad={() => {
              scrollToBottom();
            }}
          >
            {Object.keys(pastConversation).length ? (
              Object.keys(pastConversation)?.map((day: string, idx) => {
                if (day !== "") {
                  return (
                    <div key={day}>
                      <div className="speech_date">
                        <p>
                          <img src={Icon14} />
                          {day}
                        </p>
                      </div>
                      {Array.isArray(pastConversation[day]) &&
                        pastConversation[day]?.map((item, index) => {
                          const defaultProps = {
                            ...item,
                            userType:
                              item?.qnAWriter === "USER" ? "user" : "manager",
                            messageType:
                              item.contentType === "IMAGE" ? "image" : "text",
                          };
                          return (
                            <ChatItem {...defaultProps} key={`item_${index}`} />
                          );
                        })}
                    </div>
                  );
                } else {
                  const defaultProps = {
                    userType: "manager",
                    messageType: "text",
                    content: `안녕하세요. 금방금방입니다.
                    무엇을 도와드릴까요?
                    
                    채팅 상담 운영시간
                    월~금 09:00-17:00 | 주말·공휴일 휴무
                    
                    문의 사항을 남겨주시면 위 운영시간 내에 답변을 드리고 있으니 양해 부탁드립니다.`,
                  };
                  return <ChatItem key={idx} {...defaultProps} />;
                }
              })
            ) : (
              <div style={{ marginTop: 720, flex: 1, alignItems: "center" }} />
            )}
            <div ref={messagesEndRef} />
          </div>
          {/* 메시지 입력창 */}
          <div
            className={`qa_bottom ${
              textareaRef.current &&
              Number(textareaRef.current.style.height.replace("px", "")) > 28
                ? "line3"
                : ""
            }${
              qnaImage && Object.keys(qnaImage).length !== 0
                ? "img_message"
                : ""
            }`}
          >
            <div className="file">
              <input
                accept="image/*"
                type="file"
                id="rasied-image-file"
                name="qnaImage"
                style={{ display: "none" }}
                onChange={onChangeImage}
                onClick={onInputClick}
              />
              <label htmlFor="rasied-image-file">
                <img src={BtnFile} />
              </label>
            </div>
            <div className="text_input">
              {qnaImage && Object.keys(qnaImage).length !== 0 ? (
                <div className="send_img" style={{ alignContent: "center" }}>
                  {qnaImage && (
                    <img src={qnaImage.uri as string} className="img_file" />
                  )}
                  <img
                    src={BtnClear}
                    className="btn_clear"
                    onClick={() => {
                      setQnaImage(null);
                    }}
                  />
                </div>
              ) : (
                <textarea
                  ref={textareaRef}
                  rows={1}
                  value={message}
                  placeholder="메시지를 입력하세요."
                  onChange={handleChangeText}
                  style={{
                    resize: "none",
                    overflow: "hidden",
                    maxHeight: 84,
                    marginRight: 5,
                  }}
                />
              )}
              <img
                src={BtnSend}
                className="btn_send"
                onClick={handleClickRegister}
              />
            </div>
          </div>
        </>
      ) : (
        <div ref={contentRef} style={{ flex: 1 }} />
      )}
    </div>
  );
};

const ChatItem = (props: any) => {
  const formatDate = (date: any) => {
    const time = moment(date).format("HH:mm");
    const getTime = time.substring(0, 2);
    const intTime = parseInt(getTime);
    let str = "";
    let cvHour = 0;
    if (intTime < 12) {
      str = "오전";
    } else {
      str = "오후";
    }
    if (intTime === 12) {
      cvHour = intTime;
    } else {
      cvHour = intTime % 12;
    }
    const res = str + " " + ("0" + cvHour).slice(-2) + time.slice(-3);
    return res;
  };

  return (
    <div
      className={`speech_bubble ${
        props.userType === "user" ? "bubble02" : "bubble01"
      }`}
    >
      {props.userType === "user" && props.createdAt ? (
        <div className="txt_time">{formatDate(props.createdAt)}</div>
      ) : null}
      {props.messageType === "image" ? (
        // 이미지 넣는곳
        <div className={imageWrap}>
          <img src={`${constants.s3BaseUrl}${props.content}`} />
        </div>
      ) : (
        // 메시지 넣는곳
        <div className={`${props.userType === "user" ? "bk_box" : "wh_box"}`}>
          <p>
            {props.content?.split("").map((x: string, i: number) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </p>
        </div>
      )}
      {props.userType === "manager" && props.createdAt ? (
        <div className="txt_time">{formatDate(props.createdAt)}</div>
      ) : null}
    </div>
  );
};
export default Inquire;
