import { QueryKey, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export interface ResponseType<T> extends AxiosResponse {
  data: T;
}
interface ErrorType<T> extends AxiosResponse {
  response: {
    data: T;
  };
}

type NetworkError = ErrorType<{ message: string }>; // 존재하지 않는 경로 등
type ServerError<T = unknown> = ErrorType<T>; // 서버에서 에러 반환 등

export type APIErrorType<T = { message: string }> =
  | NetworkError
  | ServerError<T>;

type Params = { [key: string]: any };
type QueryFunction<Response> = (params: Params) => Promise<Response>;

/**
 *
 * @param queryKey 고유한 쿼리키: [routes, API, id]
 * @param queryFn API
 * @param options useQuery 옵션
 * @returns useQuery 결과
 */
function useCustomQuery<Response>(
  queryKey: QueryKey,
  queryFn: QueryFunction<Response>,
  options?: any,
) {
  const commonOptions = {
    retry: 0,
  };

  return useQuery<Response, APIErrorType, Response, QueryKey>({
    queryKey,
    queryFn,
    ...commonOptions,
    ...options,
  });
}

export default useCustomQuery;
