import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store";
import { setPurchaseInfo } from "@features/purchase/purchaseSlice";
import { numberWithCommas } from "@utils/utils";

import usePrePrice from "../hooks/usePrePrice";
import useUserAssets from "../hooks/useUserAssets";
import * as styles from "../styles.css";

interface IEstimatedAmountProps {
  assetType: string;
}

const EstimatedAmount: React.FC<IEstimatedAmountProps> = ({ assetType }) => {
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const {
    qty,
    goodsId,
    isUseKrw,
    usePoint,
    useKrw,
    useGram,
    sellingPrice,
    depositPrice,
  } = purchaseInfo;
  const { prePrice, prePriceRefetch } = usePrePrice({
    qty: qty,
    goodsId: goodsId || "",
    useGram: useGram,
  });
  const { UserAssets } = useUserAssets();

  // 중량이나 가격이 변동되면 결제 예상금액 재조회
  useEffect(() => {
    prePriceRefetch();
  }, [sellingPrice, prePriceRefetch]);

  // 결제 예상금액이 있으면 전역상태 값 업데이트
  useEffect(() => {
    if (prePrice) {
      updatePurchaseInfo();
    }
  }, [prePrice]);

  // 현금 사용 여부, 결제 예정금액이 바뀌면 값 업데이트
  useEffect(() => {
    updatePurchaseInfo();
  }, [isUseKrw, UserAssets]);

  // 전역상태에 계산된 값 반영
  const updatePurchaseInfo = () => {
    if (!prePrice) return;
    const { depositPrice, maxAvailablePoints, maxAvailableCash } =
      calculateDepositPrice();
    dispatch(
      setPurchaseInfo({
        depositPrice,
        useKrw: maxAvailableCash,
        usePoint: maxAvailablePoints,
        buyPrice: prePrice.buyPrice,
      }),
    );
  };

  // 최종 결제 금액 재계산
  const calculateDepositPrice = () => {
    const priceAfterGram = prePrice?.price ?? 0;
    const maxAvailablePoints = Math.min(
      priceAfterGram,
      UserAssets?.acceptablePoint ?? 0,
    );
    const priceAfterPoints = priceAfterGram - maxAvailablePoints;
    const maxAvailableCash = isUseKrw
      ? Math.min(priceAfterPoints, UserAssets?.acceptableKrw ?? 0)
      : 0;
    const depositPrice = priceAfterPoints - maxAvailableCash;

    return {
      depositPrice: Math.max(depositPrice, 0),
      maxAvailablePoints,
      maxAvailableCash,
    };
  };

  return (
    <div>
      <div className="shadow88 sub02_06">
        <h3 className="shadow88_tit">결제 예정 금액</h3>
        <ul className="sub02_06_ul01">
          <li>
            <p className={styles.sellingPriceTitle}>주문 금액</p>
          </li>
          <li>
            <h3>{numberWithCommas(sellingPrice)}원</h3>
          </li>
        </ul>
        <div className="sub02_06_list">
          <ul>
            <li>
              <p>・보유 {assetType === "GOLD" ? "금" : "은"} 차감</p>
            </li>
            <li>
              <p>
                <span className={styles.useGramTitle}>
                  -{numberWithCommas(useGram)}g
                </span>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>・보유 현금 차감</p>
            </li>
            <li>
              <p>
                <span>-{numberWithCommas(useKrw)}</span>원
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>・포인트 적용</p>
            </li>
            <li>
              <p>
                <span>-{numberWithCommas(usePoint)}</span>pt
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="shadow88 bg-bl01">
        <ul>
          <li>
            <h3>최종 결제 금액</h3>
          </li>
          <li>
            <h3 className="fc-bl01">{numberWithCommas(depositPrice)}원</h3>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EstimatedAmount;
