import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export type NoticeItem = {
  id: number;
  category: string;
  content: string;
  count: number;
  subject: string;
  startDate: string;
  status: string;
  serviceImages: [
    { id: number; imageType: string; path: string; tradeType: string },
  ];
};

export type NoticeListInfo = {
  fixed: Array<NoticeItem>;
  open: Array<NoticeItem>;
};

const useNoticeList = () => {
  const dispatch = useDispatch();

  const fetchNoticeList = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getNotice();
      dispatch(hideLoading());
      return response.data.data as NoticeListInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading } = useCustomQuery(
    [routes.notice, "list"],
    fetchNoticeList,
  );
  return { data, error, isLoading };
};

export default useNoticeList;
