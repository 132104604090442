import { useState } from "react";
import SelectAgency from "./SelectAgency";
import SelectDate from "./SelectDate";
import Authentication from "./Authentication";
import AppraisalHome from "./AppraisalHome";
import { BottomSheet } from "../../../components/index";

interface ReservationDataProps {
  acceptTerms?: boolean | null;
  method?: string | null;
  shopId?: number | null;
  userId?: number | null;
  visitDate?: string | null;
  distance?: number;
  shopName?: string;
}
interface BottomSheetProps {
  lastPage?: string | null;
}
const ReservationBottomSheet = ({ lastPage }: BottomSheetProps) => {
  const [progressScreen, setProgressScreen] = useState<
    "home" | "agency" | "date" | "auth" | "completed"
  >("home");
  const [reservationData, setReservationData] = useState<ReservationDataProps>({
    acceptTerms: false,
    method: null,
    shopId: null,
    userId: null,
    visitDate: null,
    shopName: "",
  });

  const handleCalendarReserve = (data: unknown) => {
    setReservationData(data as any);
    setProgressScreen("home");
  };

  const renderScreen = () => {
    switch (progressScreen) {
      case "home":
        return (
          <AppraisalHome
            setProgressScreen={setProgressScreen}
            reservationData={reservationData}
            setReservationData={setReservationData}
            lastPage={lastPage}
          />
        );
      case "agency":
        return (
          <SelectAgency
            goHome={() => setProgressScreen("home")}
            reservationData={reservationData}
            setReservationData={setReservationData}
          />
        );
      case "date":
        return (
          <SelectDate
            goHome={() => setProgressScreen("home")}
            reservationData={reservationData}
            setReservationData={handleCalendarReserve}
          />
        );
      case "auth":
        return <Authentication setProgressScreen={setProgressScreen} />;
      default:
        return (
          <AppraisalHome
            setProgressScreen={setProgressScreen}
            reservationData={reservationData}
            setReservationData={setReservationData}
            lastPage={lastPage}
          />
        );
    }
  };
  return <BottomSheet>{renderScreen()}</BottomSheet>;
};

export default ReservationBottomSheet;
