import { RootState } from "@app/store";
import {
  closeBottomSheet,
  selectBottomSheet,
} from "@features/bottom-sheet/bottomSheetSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import { updateUser } from "@features/user/userSlice";
import { ButtonBase } from "@mui/material";
import * as APIS from "@services/apis";
import {
  ch2pattern,
  getCurrentMyPosition,
  getDistanceFromLatLonInKm,
  numberWithCommas,
} from "@utils/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import iconPhone from "@assets/icons/ico_phone.png";
import iconSearch from "@assets/icons/ico_search.png";
import iconLeft from "@assets/icons/icon_left.png";
import imgCaution from "@assets/images/img_caution_gr2.png";

import BottomSheet from "../bottom-sheet/BottomSheet";
import * as styles from "./style.css";

// import { bottomWrap, nextBtn } from "./style.css";

interface SelectShopsProps {
  goBack?: () => void;
  from?: "SIGNUP" | "UPDATE_USING_SHOP";
}
interface DataItem {
  latitude: number;
  longitude: number;
  name: any;
}

interface Shop {
  id: number;
  name: string;
  distance: number;
  address: string;
}

//NOTE:  from === "SIGNUP" 사용한 곳이 없다.
const SelectShops: React.FC<SelectShopsProps> = ({ goBack, from }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectBottomSheet);
  const userInfo = useSelector((state: RootState) => state.user);

  const [keyword, setKeyword] = useState("");
  const [shops, setShops] = useState<Shop[]>([]);
  const [shopInfo, setShopInfo] = useState({
    shopId: 0,
    shopName: "",
  });

  const onClose = () => {
    if (goBack) {
      goBack();
    }
    dispatch(closeBottomSheet());
  };

  const onChangeShop = (shop: any) => {
    dispatch(showLoading());
    APIS.updateUsingShop({
      using_shop: shop.shopId,
    })
      .then(({ data }) => {
        const { success, message } = data;
        if (success) {
          dispatch(
            updateUser({
              ...userInfo,
              usingShopId: shop.shopId,
              usingShopName: shop.shopName,
            }),
          );
          dispatch(
            showToast({
              message: "이용대리점이 변경되었습니다.",
              icon: "success",
            }),
          );
          setKeyword("");
          onClose();
        } else if (message === "[6172]현재와 동일한 대리점입니다.") {
          dispatch(
            showToast({
              message: "현재와 동일한 대리점입니다",
              icon: "info",
            }),
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  // const handleClickNext = () => {
  // dispatch(
  //   updateSignUp({
  //     recommandShop: {
  //       id: shopInfo.shopId,
  //       name: shopInfo.shopName,
  //     },
  //   })
  // );
  // setSearchKeyword("");
  // onCloseModal();
  // history.push(routes.authRegisterEmail);
  // };

  const init = async () => {
    // 현재 위치 불러오기
    dispatch(showLoading());
    const position = await getCurrentMyPosition()
      .then((position: any) => {
        return position;
      })
      .catch((position) => {
        return position;
      });
    const { lat, lng } = position;

    let func =
      from === "SIGNUP" || from === "UPDATE_USING_SHOP"
        ? APIS.getNearbyShop // 금방금방 v1에선 메인 > 대리점 안내 클릭시 사용.
        : APIS.getAppraisalShop;
    func()
      .then(({ data: { success, data } }) => {
        if (success) {
          let sortedData = data;
          if (lat && lng) {
            // 위치 허용인 경우
            sortedData = data.map((item: DataItem) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (latitude && longitude) {
                distance = Number(
                  getDistanceFromLatLonInKm(
                    lat, //유저
                    lng, //유저
                    latitude, //데이터
                    longitude, //데이터
                  ).toFixed(2),
                );
              }
              return { ...item, distance };
            });
            // distance에 따라 정렬
            sortedData = sortedData.sort(
              (a: any, b: any) => a.distance - b.distance,
            );
          } else {
            sortedData = sortedData.sort((a: any, b: any) => {
              let aName = a.name;
              let bName = b.name;
              if (aName < bName) return -1;
              else if (aName === bName) return 0;
              else return 1;
            });
          }
          // 위치 허용 X: 서버에서 오는 순서대로 적용
          const matchShops = sortedData.filter((item: any) => {
            return createFuzzyMatcher(keyword).test(item?.name);
          });
          if (matchShops.length === 0) {
            setShops([]);
          } else {
            setShops(matchShops);
          }
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const handleClickPhone = (phoneNumber: string) => {
    if (phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      dispatch(
        showToast({
          message: "매장 전화번호가 등록되지 않았습니다.",
          icon: "caution",
        }),
      );
    }
  };

  const createFuzzyMatcher = (input: any) => {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  };
  const mapDataToComponent = (shops: any) => {
    return (
      <div className="sub_search_c s_res">
        {shops.map((shop: any, index: any) => {
          return (
            <div className="shadow88" key={shop.id}>
              <ul>
                <ul
                  style={{ flex: 1 }}
                  onClick={() => {
                    if (from === "SIGNUP") {
                      setShopInfo({
                        shopId: shop.id,
                        shopName: shop.name,
                      });
                    }
                    if (from === "UPDATE_USING_SHOP") {
                      onChangeShop({
                        shopId: shop.id,
                        shopName: shop.name,
                      });
                    }
                  }}
                >
                  <li className="box_num spoqa">
                    <h3>{index + 1}</h3>
                  </li>
                  <li className="box_txt">
                    <h3>
                      {/* <span>모두의금</span> 00점 */}
                      {shop.name}
                    </h3>
                    <p>
                      {!!shop.distance && (
                        <>
                          <span>{numberWithCommas(shop.distance)}</span>
                          km
                        </>
                      )}
                    </p>
                    <span>{shop.address}</span>
                  </li>
                </ul>
                <ul>
                  <button
                    className={styles.iconButton}
                    onClick={() => handleClickPhone(shop.phoneNumber)}
                  >
                    <img src={iconPhone} />
                  </button>
                </ul>
              </ul>
            </div>
          );
        })}
      </div>
    );
  };

  // const ShopList = useMemo(() => {
  //   if (matchShops.length === 0) {
  //     return (
  //       <div className="sub_search_c s_none">
  //         <img src={imgCaution} />
  //         <h3>검색 결과가 없습니다.</h3>
  //         <p>ex. 0000 또는 00으로 다시 검색해 보세요!</p>
  //       </div>
  //     );
  //   } else {
  //     return mapDataToComponent(matchShops);
  //   }
  // }, [shops, keyword]);

  useEffect(() => {
    if (!isOpen) {
      setKeyword("");
      setShops([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (from === "UPDATE_USING_SHOP") {
      setShopInfo({
        shopId: userInfo?.usingShopId || 0,
        shopName: userInfo?.usingShopName || "",
      });
    }
  }, [from]);

  return (
    <BottomSheet>
      <div className="sub_bottom search_tab">
        <div className="sub_bottom_wrap">
          <div className="sub_top_fix">
            <div className="sub_top01">
              <h3 className="sub_bottom_tit">
                <img
                  src={iconLeft}
                  alt=""
                  className="icon_back"
                  onClick={() => {
                    onClose();
                  }}
                />
                대리점 선택
              </h3>
            </div>
            <div className="sub_search">
              <input
                type="text"
                id="search-1"
                placeholder="대리점을 검색하세요. (예: 종로, 대전)"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <label htmlFor="search-1">
                <ButtonBase
                  onClick={() => {
                    init();
                  }}
                >
                  <img src={iconSearch} alt="" className="search_icon" />
                </ButtonBase>
              </label>
            </div>
          </div>
          {shops.length === 0 ? (
            <div className="sub_search_c s_none">
              <img src={imgCaution} />
              <h3>검색 결과가 없습니다.</h3>
              {/* <p>ex. 0000 또는 00으로 다시 검색해 보세요!</p> */}
            </div>
          ) : (
            mapDataToComponent(shops)
          )}
        </div>
        {/* {from === "SIGNUP" && (
          <div className={bottomWrap}>
            <ButtonBase
              className={`sub_wrap03_btn ${nextBtn} ${
                shopInfo.shopId === 0 ? "" : "on"
              }`}
              onClick={() => {
                handleClickNext();
              }}
            >
              팔래요
            </ButtonBase>
          </div>
        )} */}
      </div>
    </BottomSheet>
  );
};

export default SelectShops;
