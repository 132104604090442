import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store";
import iconAmount24 from "@assets/icons/ico-amount-24-px.png";
import icoShop from "@assets/icons/ico_shop_24px.png";
import iconSell0301 from "@assets/icons/icon_sell03_01.png";
import { selectAuth } from "@features/user/userSlice";
import { getDeliverableAppraisalShop } from "@services/apis";

import { routes } from "../../../routes";

const Appraisal = () => {
  const navigate = useNavigate();
  const authInfo = useSelector(selectAuth);

  const {
    role: { LEVEL: userLevel },
    isSigned,
  } = useSelector((state: RootState) => state.auth);
  const isSelfVerification = isSigned && userLevel === 1;

  const { data: deliverableShop } = useQuery({
    queryKey: ["/sell", "deliverableAppraisalShop"],
    queryFn: getDeliverableAppraisalShop,
    select: (res) => res.data.data,
  });

  const isDeliverableShop =
    deliverableShop &&
    deliverableShop.some((item) => String(item) === authInfo.recommendShop);

  return (
    <div className="sell_main sell_main03 shadow88">
      <div className="sell_main03_top">
        <div className="sell_main03_txt">
          <h3>감정평가</h3>
          <p>내 자산(금/은) 얼마일까요?</p>
        </div>
        {isSigned && !isSelfVerification && (
          <>
            <span
              className="sell_main03_btn"
              onClick={() => {
                navigate(
                  `${routes.tradeHistory}/?tradeType=APPRAISAL&statusType=ING`,
                );
              }}
            >
              예약 내역
            </span>
          </>
        )}
      </div>
      <div className="sell_main03_list">
        <ul>
          {isDeliverableShop && (
            <li onClick={() => navigate(routes.appraisalDelivery)}>
              <img src={icoShop} alt="" className="icon_sell_main03" />
              택배 접수
            </li>
          )}
          <li onClick={() => navigate(routes.appraisalAgency)}>
            <img src={iconSell0301} alt="" className="icon_sell_main03" />
            대리점 감정 예약하기
          </li>
          <li onClick={() => navigate(routes.appraisalSelectAsset)}>
            <img src={iconAmount24} alt="" className="icon_sell_main03" />
            감정 예상가 확인하기
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Appraisal;
