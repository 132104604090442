import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import * as APIS from "@services/apis";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconLeft from "@assets/icons/icon_left.png";
import colors from "@assets/styles/colors.css";

interface Reason {
  description: string;
  group: string;
  leaveReason: string;
}

interface ReasonGroup {
  title: string;
  list: Reason[];
}

const LeaveReason = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reasonList, setReasonList] = useState<ReasonGroup[]>([]);
  const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    dispatch(showLoading());
    APIS.getLeaveReason()
      .then(({ data: { success, data } }) => {
        if (success) {
          const reasonList = [];

          for (let reason of data) {
            const list = reasonList.find(
              ({ title }) => title === reason.group,
            )?.list;

            if (list) {
              list.push(reason);
            } else {
              reasonList.push({ title: reason.group, list: [reason] });
            }
          }

          setReasonList(reasonList);
        }
      })
      .finally(() => dispatch(hideLoading()));
  }, []);

  const handleChangeRadio = (e: any) => {
    setSelectedReason(e.target.value);
  };

  const handleClickNext = () => {
    if (selectedReason) {
      navigate(routes.leaveSms, {
        state: { leaveReason: selectedReason },
        replace: true,
      });
    }
  };

  return (
    <div>
      <div className="sub_top">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>탈퇴하기</p>
        </div>
      </div>
      <div className="menu02_wrap">
        <div className="menu_wrap_tit">
          <h3>탈퇴하시는 이유를 알려주세요.</h3>
          <p>
            아래 항목에서 <span>가장 가까운 이유 1개</span>를 선택해주세요.
          </p>
        </div>
        {reasonList.map(({ title, list }, i) => {
          return (
            <div className={`shadow88 bg-gr01 ${i !== 0 ? "gap" : ""}`} key={i}>
              {!!title && <h3 className="menu_title02">{title}</h3>}
              {list.map(({ description, leaveReason }, j) => {
                return (
                  <div className="op_pd_box" key={j.toString()}>
                    <input
                      type="radio"
                      id={`radio${i}_${j}`}
                      name="radio"
                      value={leaveReason}
                      onChange={handleChangeRadio}
                      checked={selectedReason === leaveReason}
                    />
                    <label htmlFor={`radio${i}_${j}`}>
                      <p>{description}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="sub_btn_wrap">
        <button
          className={`sub_wrap02_btn ${selectedReason !== "" ? "on" : ""}`}
          onClick={handleClickNext}
          style={{
            backgroundColor: selectedReason !== "" ? colors.buttonColor : "",
          }}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default LeaveReason;
