import { routes } from "@/routes";
import { UseQueryOptions } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";

/**
 * 실물인출 가격 체크
 * - params 내부 useGram: 숫자를 집어넣으면 해당 중량을 제외한 물품의 가격을 반환
 */
const usePrePrice = (
  params: APIS.IPrepriceProps,
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn">,
) => {
  const dispatch = useDispatch();

  const fetchDetails = async (): Promise<APIS.IPreprice> => {
    if (!params.goodsId || params.goodsId === "") {
      throw new Error("유효한 goodsId가 없습니다.");
    }
    dispatch(showLoading());
    try {
      const response = await APIS.getPrePrice(params);
      dispatch(hideLoading());
      return response.data.data;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery(
    [routes.buyGoodsDetail, params],
    fetchDetails,
    options,
  );

  return { prePrice: data, isLoading, error, prePriceRefetch: refetch };
};

export default usePrePrice;
