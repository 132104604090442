import { closeBottomSheet } from "@features/bottom-sheet/bottomSheetSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import { numberWithCommas } from "@utils/utils";
import { useDispatch } from "react-redux";

import imgCaution from "../../../assets/images/img_caution_rd.png";
import * as APIS from "../../../services/apis";
import * as styles from "../styles.css";

interface AssetCancelProps {
  assetType?: string;
  tradeType?: string;
  orderType?: string;
  orderPrice?: number;
  requestGram?: number;
  remainGram?: string;
  deleteId?: number;
  refetch: () => void;
}

const AssetCancel = ({
  assetType,
  tradeType,
  orderType,
  orderPrice,
  //   totalPrice,
  requestGram,
  remainGram,
  deleteId,
  refetch,
}: AssetCancelProps) => {
  const dispatch = useDispatch();
  const assetTypeName = assetType
    ?.replace("GOLD", "금")
    ?.replace("SILVER", "은");
  const orderTypeName = orderType?.replace("LIMITS", "지정가");

  const handleClickClose = () => {
    dispatch(closeBottomSheet());
  };
  const cancelOrder = async () => {
    dispatch(showLoading());
    const serviceBreak = await APIS.getServiceBreak();
    if (serviceBreak) {
      dispatch(showToast({ message: serviceBreak, icon: "caution" }));
      dispatch(hideLoading());
      return;
    }

    APIS.deleteBuySell(deleteId)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(
            showToast({ message: "주문이 취소되었습니다.", icon: "success" }),
          );
          dispatch(closeBottomSheet());
          refetch();
        } else {
          dispatch(showToast({ message, icon: "error" }));
        }
      })
      .catch(() => {
        dispatch(showToast({ message: "요청이 실패했습니다.", icon: "error" }));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  return (
    <div className="sub_bottom rv_tab receipt_tab02">
      <div className="sub_bottom_wrap">
        <img src={imgCaution} alt="" />
        <div className="receipt_tit">
          <h3>
            <span className="fc-rd01">{`${
              tradeType === "SELL" ? "매도" : "매수"
            } 주문`}</span>
            을<span className="fc-rd01"> 취소</span>하시겠어요?
          </h3>
          <p className="fc-gr01">
            <span>미체결된 중량</span>만 <span>취소</span>됩니다.
          </p>
        </div>
        <div className="receipt_wrap">
          <div className="receipt_top">
            <ul className="receipt_ul01">
              <li className="receipt_left">주문 상품</li>
              <li className="receipt_right">
                <span>{assetTypeName}</span>
              </li>
            </ul>
            <ul className="receipt_ul01">
              <li className="receipt_left">
                {`${tradeType === "SELL" ? "매도" : "매수"} 가격`}
                {/* 가격 */}
              </li>
              <li className="receipt_right">
                <span>{orderPrice ? numberWithCommas(orderPrice) : "0"}</span>원
              </li>
            </ul>
          </div>
          <div className="receipt_bottom">
            <ul className="receipt_ul01">
              <li className="receipt_left">
                <span
                  className={`${tradeType === "SELL" ? "fc-bl01" : "fc-rd01"}`}
                >
                  주문 중량
                </span>
              </li>
              <li className="receipt_right">
                <p
                  className={`${tradeType === "SELL" ? "fc-bl01" : "fc-rd01"}`}
                >
                  <span>
                    {requestGram ? numberWithCommas(requestGram) : "0"}
                  </span>
                  g
                </p>
              </li>
            </ul>
            <ul className="receipt_ul01">
              <li className="receipt_left">
                <p className="fc-bk01">
                  <span>미체결 중량</span>
                </p>
              </li>
              <li className="receipt_right">
                <p className="fc-bk01">
                  <span>{remainGram ? numberWithCommas(remainGram) : "0"}</span>
                  g
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.receiptTab02}>
        <button
          className={`${styles.receiptTabBtn} ${styles.receiptTabCloseBtn}`}
          onClick={handleClickClose}
        >
          닫기
        </button>
        <button
          className={`${styles.receiptTabBtn} ${styles.receiptTabCancelBtn}`}
          onClick={cancelOrder}
        >
          주문 취소
        </button>
      </div>
    </div>
  );
};

export default AssetCancel;
