import { postMessage } from "@utils/utils";
import { useEffect } from "react";

interface HeaderState {
  visible: boolean;
  goBack: string;
  title?: string;
  onGoBack: any;
  onload?: any;
}

const RNHeader = ({
  visible = true,
  goBack = "DEFAULT",
  title = "",
  onGoBack,
  onload,
}: HeaderState) => {
  useEffect(() => {
    postMessage({
      command: "WINDOW_OPEN",
      param: { visible, goBack: onGoBack ? "CUSTOM" : goBack, title },
    });

    if (onGoBack) {
      const listener = (event: any) => {
        if (event.data === "CUSTOM_GO_BACK") {
          onGoBack();
        }
      };

      if (window.GoldMarketApp) {
        document.addEventListener("message", listener);
        window.addEventListener("message", listener);

        return () => {
          document.removeEventListener("message", listener);
          window.removeEventListener("message", listener);
        };
      }
    }
  }, [goBack, onload]);
  return null;
};

export default RNHeader;
