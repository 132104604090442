import { queryOptions, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";

import { ShopHoursResponse, getShopHours } from "@services/apis";
import { APIResponse } from "@utils/types";
import { formatTime } from "@utils/utils";

interface WorkingDay {
  date: string;
  isFullDayOff: boolean;
  dayWeek: number;
}
interface BusinessHour {
  dayWeek: string;
  openHr: string;
  closeHr: string;
  isFullDayOff: boolean;
}

function turnIntoWorkingDays(
  data: APIResponse<ShopHoursResponse>,
  month: Date,
) {
  const { businessHoursList, holidaysList } = data.data;

  const businessList: {
    [key: string]: {
      isFullDayOff: boolean;
      open: string;
      close: string;
    };
  } = {};

  const resList: WorkingDay[] = [];

  businessHoursList.forEach(
    ({ dayWeek, isFullDayOff, openHr, closeHr }: BusinessHour) => {
      businessList[dayWeek] = {
        isFullDayOff,
        open: openHr,
        close: closeHr,
      };
    },
  );

  const copy = new Date(month);
  copy.setMonth(copy.getMonth() + 1);
  copy.setDate(0);
  const lastDay = copy.getDate();

  for (let i = 1; i <= lastDay; i++) {
    const current = new Date(month);
    current.setDate(i);
    const currentDay = current.getDay();

    // 관리자 샵에서 영업일 미설정 시 그냥 전부 렌더링
    if (Object.keys(businessList).length === 0) {
      resList.push({
        date: formatTime(current),
        isFullDayOff: false,
        dayWeek: currentDay,
      });
    } else if (businessList[currentDay]) {
      // 관리자 샵에서 영업일 설정한 것이 있다면 해당 날짜는 영업일로 지정
      resList.push({
        date: formatTime(current),
        isFullDayOff: false,
        dayWeek: currentDay,
      });
    } else {
      // 영업일로 오지 않은 모든 것은 휴무일로 지정
      resList.push({
        date: formatTime(current),
        isFullDayOff: true,
        dayWeek: currentDay,
      });
    }
  }

  // 이후 휴무일로 따로 지정된 것을 기반으로 isFullDayOff을 따로 변경
  holidaysList.forEach(({ manageDate, isFullDayOff }) => {
    const holiday = resList.find(({ date }) => date === manageDate);
    if (holiday) {
      holiday.isFullDayOff = isFullDayOff;
    }
  });
  return resList;
}

/** 다음 영업일 찾기 */
function findNextWorkingDay(currentDate: string, days: WorkingDay[]) {
  for (const day of days) {
    if (day.date > currentDate && day.isFullDayOff === false) {
      return day;
    }
  }
  return undefined;
}

const calendarQueries = {
  shopHours: (shopId: number | null | undefined, month: Date) =>
    queryOptions({
      queryKey: ["shopHours", shopId, formatTime(month, "YYYY-MM")] as const,
      queryFn: (context) =>
        getShopHours(context.queryKey[1] as number, context.queryKey[2]),
      enabled: Boolean(shopId && month),
      select: (res) => turnIntoWorkingDays(res.data, month),
    }),
};
/** 2달치를 조회하여 현재 봐야할 휴뮤일 리스트와 캘린더 내부 선택 상태 반환  */
export function useWorkingDays2(
  shopId: number | null | undefined,
  month: Date,
  reservationData: {
    shopId?: number | null;
    visitDate?: any;
  },
  setMonth: React.Dispatch<React.SetStateAction<Date>>,
  earliestVisitDate?: string,
) {
  const [selectedDate, setSelectedDate] = useState(() => {
    if (reservationData.visitDate) {
      return new Date(reservationData.visitDate);
    }

    if (!earliestVisitDate) return new Date();
    return new Date(earliestVisitDate);
  });

  // 한 달이 전부 휴가일 수 있기에 다음 달까지 총 2달치 조회
  const { data: current, isSuccess: currentIsSuccess } = useQuery({
    ...calendarQueries.shopHours(shopId, month),
  });
  const { data: nextData, isSuccess: nextIsSuccess } = useQuery({
    ...calendarQueries.shopHours(
      shopId,
      moment(month).add(1, "months").toDate(),
    ),
  });

  useEffect(() => {
    if (currentIsSuccess && nextIsSuccess) {
      const formattedSelectedDate = formatTime(selectedDate);

      // 현재 날짜가 휴일인지
      const matchedDaysOff = current.find(
        (workingDay) =>
          workingDay.date === formattedSelectedDate && workingDay.isFullDayOff,
      );

      if (matchedDaysOff) {
        const nextWorkingDay = findNextWorkingDay(matchedDaysOff.date, current);

        if (nextWorkingDay) {
          setSelectedDate(new Date(nextWorkingDay.date));
        } else {
          const nextWorkingDay2 = findNextWorkingDay(
            matchedDaysOff.date,
            nextData,
          );
          if (nextWorkingDay2) {
            setMonth(new Date(nextWorkingDay2.date));
            setSelectedDate(new Date(nextWorkingDay2.date));
          }
        }
      }
    }
  }, [currentIsSuccess, nextIsSuccess]);

  return { selectedDate, setSelectedDate, workingDays: current };
}
