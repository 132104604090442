import { RootState } from "@/app/store";
import {
  closeBottomSheet,
  openBottomSheet,
} from "@/features/bottom-sheet/bottomSheetSlice";
import { openModal } from "@/features/modal/modalSlice";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import { showToast } from "@/features/toast/toastSlice";
import useConfig from "@/hooks/useConfig";
import { routes } from "@/routes";
import { formatCurrency, numberWithCommas } from "@/utils/utils";
import { validateWithdrawalInfo } from "@/utils/validations";
import { useEffect, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import iconInfoRd from "@assets/icons/ico_info_24px_rd.png";
import iconNotiBlue from "@assets/icons/ico_noti_blue.png";
import iconLeft from "@assets/icons/icon_left.png";
import checkboxBlue from "@assets/images/checkBox_bl.png";
import checkboxGray from "@assets/images/checkBox_gr.png";
import imgPassbook from "@assets/images/imgPassbook.png";
import imgBg from "@assets/images/img_bg.png";
import imgTsoo from "@assets/images/img_tsoo02.png";
import colors from "@assets/styles/colors.css";
import Calendar from "@components/common/calendar/Calendar";
import FindShop from "@components/common/find-shop/FindShop";
import { BottomSheet } from "@components/index";
import useAccountInfo from "@pages/myPage/hooks/useAccountInfo";

import { createPurchaseInfo } from "../hooks/createPaymentInfo";
import useGoodsDetail from "../hooks/useGoodsDetail";
import usePrePrice from "../hooks/usePrePrice";
import { useTossPayment } from "../hooks/useTossPurchase";
import * as styles from "../styles.css";
import Assets from "./Assets";
import ChangePriceModal from "./ChangePriceModal";
import EstimatedAmount from "./EstimatedAmount";
import HowToReceive from "./HowToReceive";
import PurchaseGuide from "./PurchaseGuide";

const paymentOptions = [
  {
    method: "TOSS_QUICK",
    icon: imgPassbook,
    title: "퀵계좌이체",
    description: "편한 결제",
    subDescription: "",
    limit: true,
  },
  {
    method: "NONE",
    icon: imgTsoo,
    title: "가상계좌",
    description: "",
    subDescription: "입금 계좌로 충전 후 결제하기",
    limit: false,
  },
] as const;

const validateUnit = (
  purchaseInfo: any,
  assetType: string,
  dispatch: any,
): boolean => {
  const { useGram } = purchaseInfo;
  const unit = assetType === "GOLD" ? 0.005 : 0.5;
  let isValidUnit = true;
  if (assetType === "GOLD") {
    const decimalPart = useGram.toString().split(".")[1] || "";

    if (decimalPart.length >= 3 && !["0", "5"].includes(decimalPart[2])) {
      isValidUnit = false;
    }
  } else if (assetType === "SILVER") {
    const decimalPart = useGram.toString().split(".")[1] || "";
    if (decimalPart.length >= 1 && !["0", "5"].includes(decimalPart[0])) {
      isValidUnit = false;
    }
  }

  if (!isValidUnit) {
    dispatch(
      showToast({
        message: `${unit} 단위로 입력해 주세요.`,
        icon: "caution",
      }),
    );
  }

  return isValidUnit;
};

const GoodsPurchase = () => {
  const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goodsId } = useParams();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const { depositMethod, depositPrice } = purchaseInfo;
  const { GoodsDetailInfo } = useGoodsDetail(goodsId || "");
  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);
  const [activeBottomSheet, setActiveBottomSheet] = useState<string>("shop");
  const { ConfigData: TossOnceLimit } = useConfig("TOSS_DEPOSIT_ONCE");
  const { TossProcess } = useTossPayment();
  const {
    prePrice: prePriceZero,
    isLoading: prePriceZeroLoading,
    prePriceRefetch: prePriceZeroRefetch,
  } = usePrePrice({
    qty: purchaseInfo.qty,
    goodsId: goodsId || "",
    useGram: 0,
  });
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { AccountInfo } = useAccountInfo("DEPOSIT");
  const checkedAccountInfo =
    AccountInfo && AccountInfo.bankAccountStatus === "COMPLETED"
      ? AccountInfo
      : null;

  useEffect(() => {
    if (GoodsDetailInfo) {
      dispatch(
        setPurchaseInfo({
          orderName: GoodsDetailInfo?.goodsName,
          goodsId: goodsId,
        }),
      );
    }
  }, [GoodsDetailInfo]);

  useEffect(() => {
    if (!prePriceZeroLoading) {
      dispatch(
        setPurchaseInfo({
          sellingPrice: prePriceZero?.price,
        }),
      );
    }
  }, [prePriceZeroLoading]);

  if (!GoodsDetailInfo) {
    return null;
  }

  const handleNavigateDetail = () => {
    navigate(`${routes.buyGoodsDetail}/${goodsId}`);
  };

  const handleSelectShop = (result: any) => {
    dispatch(
      setPurchaseInfo({
        shopId: result.id,
        shopName: result.name,
        shopDistance: result.distance,
        visitDate: "", // 대리점 변경 시 예약일 초기화
      }),
    );
    dispatch(closeBottomSheet());
  };

  const handleSelectDate = (result: any) => {
    dispatch(
      setPurchaseInfo({
        visitDate: result.visitDate,
      }),
    );
    dispatch(closeBottomSheet());
  };

  const handlePaymentChange = (method: string) => {
    if (method === "NONE" && !checkedAccountInfo) {
      navigate(routes.myAccountVerification, {
        state: {
          bankType: "DEPOSIT",
          fromPurchase: true,
        },
      });
      return;
    }
    dispatch(setPurchaseInfo({ depositMethod: method }));
  };

  const handleTermChange = () => {
    setIsTermChecked(!isTermChecked);
  };

  const handleOpenBottomSheet = (type: string) => {
    setActiveBottomSheet(type);
    dispatch(openBottomSheet());
  };

  // 가상 계좌 처리 로직
  const handleVirtualAccount = () => {
    if (purchaseInfo.depositPrice > 0) {
      dispatch(
        showToast({
          icon: "caution",
          message: "부족한 금액을 입금해주세요.",
        }),
      );
    } else {
      navigate(routes.commonSecureKeypad, {
        state: {
          type: "BUY",
          payload: {
            ...createPurchaseInfo(purchaseInfo),
            goodsId,
          },
        },
      });
    }
  };

  // 토스 결제 처리 로직
  const handleToss = () => {
    const tossLimit = Number(TossOnceLimit?.value);
    if (purchaseInfo.depositPrice > Number(TossOnceLimit?.value)) {
      dispatch(
        showToast({
          message: `최종 결제 금액이 ${formatCurrency(
            tossLimit,
          )}을 초과합니다.\n가상계좌 방식을 이용해주세요`,
          icon: "info",
        }),
      );
    } else if (purchaseInfo.depositPrice === 0) {
      // 최종 결제 금액이 0원이면, 가상 계좌 결제로 진행
      navigate(routes.commonSecureKeypad, {
        state: {
          type: "BUY",
          payload: {
            ...createPurchaseInfo(purchaseInfo),
            goodsId,
          },
        },
      });
    } else {
      TossProcess(purchaseInfo);
    }
  };

  const handleClickPurchase = async () => {
    /**
     * 가격 변동 있으면 변동 모달
     * 최종 결제 금액이 0원이면 결제 비밀번호로
     * 500만원 이하면 토스
     * 500만원 이상이면 가상계좌
     * > 계좌 없으면 계좌 인증
     * > 계좌 있으면,
     * >> 부족한 금액 체크
     * >>> yes: 부족한 금액 입금하라는 바텀시트
     */
    const validation = validateWithdrawalInfo(
      purchaseInfo.isDelivery,
      purchaseInfo,
    );
    // 배송지, 수령 대리점
    if (!validation.isValid) {
      dispatch(
        showToast({ icon: "caution", message: validation.message || "" }),
      );
      return;
    }

    // 가용 자산 단위 체크
    const unitValidation = validateUnit(
      purchaseInfo,
      GoodsDetailInfo.assetType,
      dispatch,
    );
    if (!unitValidation) {
      return;
    }

    // 결제 수단: 최종 결제 금액이 0원이면, 결제수단 체크하지 않음.
    if (depositMethod === "" && depositPrice !== 0) {
      dispatch(
        showToast({ icon: "caution", message: "결제 수단을 선택해주세요." }),
      );
      return;
    }

    // 약관
    if (!isTermChecked) {
      dispatch(showToast({ icon: "caution", message: "약관을 확인해주세요." }));
      return;
    }

    const newPrePriceByZero = await prePriceZeroRefetch();
    const totalGoodsGram = GoodsDetailInfo.goodsGram * purchaseInfo.qty;
    const useGram = purchaseInfo.useGram;

    if (newPrePriceByZero.data) {
      const updatedPrePriceZero = newPrePriceByZero.data;

      // 매도 잔량이 충분한지 확인
      if ((updatedPrePriceZero.balanceGrams || 0) < totalGoodsGram - useGram) {
        dispatch(
          showToast({ message: "매도물량이 부족합니다", icon: "error" }),
        );
        return;
      }

      // 가격 변동되면
      if (purchaseInfo.sellingPrice !== updatedPrePriceZero.price) {
        // 변동된 가격으로 업데이트
        prePriceZeroRefetch();
        dispatch(
          setPurchaseInfo({
            sellingPrice: updatedPrePriceZero.price,
            buyPrice: updatedPrePriceZero.buyPrice,
          }),
        );

        dispatch(
          openModal(
            <ChangePriceModal
              prevPrice={purchaseInfo.sellingPrice}
              nextPrice={updatedPrePriceZero.price || 0}
            />,
          ),
        );
        return;
      }

      if (depositMethod === "NONE" || depositMethod === "") {
        handleVirtualAccount();
      } else if (depositMethod === "TOSS_QUICK") {
        handleToss();
      }
    }
  };

  // 주소 선택
  const handleCompleteSearch = (result: any) => {
    dispatch(
      setPurchaseInfo({
        postCode: result.zonecode,
        address: result.address,
      }),
    );
    dispatch(closeBottomSheet());
  };

  const filteredPaymentOptions =
    depositMethod === "NONE"
      ? paymentOptions.filter(({ method }) => method !== "NONE")
      : paymentOptions;

  const handleCopyToClipboard = async () => {
    if (checkedAccountInfo?.virtualBankNumber) {
      try {
        await navigator.clipboard.writeText(
          checkedAccountInfo.virtualBankNumber,
        );
        dispatch(
          showToast({
            message: "계좌번호가 클립보드에 복사되었습니다.",
            icon: "success",
          }),
        );
      } catch (error) {
        dispatch(
          showToast({
            message: "클립보드 복사에 실패했습니다.",
            icon: "error",
          }),
        );
      }
    } else {
      dispatch(
        showToast({ message: "복사할 계좌번호가 없습니다.", icon: "caution" }),
      );
    }
  };
  return (
    <div>
      <BottomSheet>
        {activeBottomSheet === "shop" && (
          <FindShop
            onSelect={handleSelectShop}
            service={"buy"}
            goodsId={goodsId}
          />
        )}
        {activeBottomSheet === "calendar" && (
          <div className="sub_bottom cal_tab">
            <div className="sub_bottom_wrap">
              <div className="sub_top01">
                <h3 className="sub_bottom_tit">
                  <img
                    src={iconLeft}
                    alt=""
                    className="icon_back"
                    onClick={() => {
                      dispatch(closeBottomSheet());
                    }}
                  />
                  예약일 선택
                </h3>
              </div>
              <Calendar
                reservationData={{
                  shopId: purchaseInfo.shopId,
                  visitDate: purchaseInfo.visitDate,
                }}
                setReservationData={handleSelectDate}
                earliestVisitDate={GoodsDetailInfo.earliestVisitDate}
              />
            </div>
          </div>
        )}
        {activeBottomSheet === "address" && (
          <div className={styles.addressWrap}>
            <DaumPostcodeEmbed
              onComplete={handleCompleteSearch}
              style={{ height: 500 }}
              autoClose={false}
            />
          </div>
        )}
        {activeBottomSheet === "guide" && <PurchaseGuide />}
      </BottomSheet>
      <div className="sub_top02">
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={handleNavigateDetail}
          />
          <p>주문하기</p>
        </div>
      </div>
      <div className="sub02_wrap sub02_wrap02">
        {/* 수령방법 */}
        <HowToReceive
          isPost={GoodsDetailInfo?.isPost}
          handleOpenBottomSheet={handleOpenBottomSheet}
        />
        {/* 주문 상품*/}
        <div className="sell_accordion sell_vertical sell_main04 shadow88">
          <ul>
            <li style={{ width: "100%" }}>
              <input
                type="checkbox"
                id="checkbox-2"
                name="checkbox-accordion"
                defaultChecked
              />
              <label htmlFor="checkbox-2">
                <h3>주문 상품</h3>
                <div className="btn_cx_down"></div>
                <div className="btn_cx_up"></div>
              </label>
              <div className="sell_content sub02_02" style={{ paddingTop: 0 }}>
                <div className={styles.borderWrap}>
                  <div className={styles.dividingLine} />
                </div>
                <ul className={styles.contentBox}>
                  <li className="sub02_02_img">
                    <img
                      src={
                        GoodsDetailInfo?.goodsImages
                          ? `${S3_BASE_URL}/${GoodsDetailInfo?.goodsImages[0]}`
                          : imgBg
                      }
                    />
                  </li>
                  <li>
                    <p className={styles.brandName}>{GoodsDetailInfo?.brand}</p>
                    <p className={styles.goodsName}>
                      {GoodsDetailInfo?.goodsName}
                    </p>

                    <div style={{ marginTop: 16 }}>
                      {purchaseInfo.optionValue ? (
                        <div className={styles.goodsOption}>
                          <p>옵션 : {purchaseInfo.optionValue} </p>
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <div className={styles.goodsQty}>
                        <p>수량 : {purchaseInfo.qty}개</p>
                      </div>
                    </div>
                    <div className={styles.pdPrice}>
                      {numberWithCommas(purchaseInfo?.sellingPrice)}원
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        {/* 보유자산 */}
        <Assets
          assetType={GoodsDetailInfo?.assetType}
          goodsGram={GoodsDetailInfo?.goodsGram}
        />
        <EstimatedAmount assetType={GoodsDetailInfo?.assetType} />
        {purchaseInfo.depositPrice > 0 && (
          <div>
            <div className="shadow88 sub02_04">
              <h3 className="shadow88_tit">결제 수단</h3>
              <div className="select_btn">
                {filteredPaymentOptions.map(
                  ({
                    method,
                    icon,
                    title,
                    description,
                    subDescription,
                    limit,
                  }) => (
                    <label
                      key={method}
                      className={`${styles.bottomBox} ${
                        depositMethod === method
                          ? styles.bottomBoxChecked.checked
                          : ""
                      }`}
                      onClick={() => handlePaymentChange(method)}
                    >
                      <input
                        type="radio"
                        name="payment"
                        className={styles.hiddenRadio}
                        checked={depositMethod === method}
                        onChange={() => handlePaymentChange(method)}
                      />
                      <div className={styles.boxTitChecked}>
                        <div
                          className={`${styles.boxImgWrapperVar[method]}`}
                          style={{
                            backgroundImage: `url(${icon})`,
                            width: 32,
                          }}
                        />
                        <div className={styles.flexColumn}>
                          <div className={styles.flexRow}>
                            <h3 className={styles.h3}>{title}</h3>
                            {description && (
                              <span className={styles.blueChip}>
                                {description}
                              </span>
                            )}
                          </div>
                          {limit && (
                            <div className={styles.flexRow}>
                              <div className={styles.subDesc}>
                                <span className={styles.grayChip}>한도</span>
                                <span className={styles.subDescSpan}>
                                  {`${formatCurrency(
                                    Number(TossOnceLimit?.value),
                                  )} 이하`}
                                </span>
                              </div>
                            </div>
                          )}
                          {subDescription && (
                            <div className={styles.subDesc}>
                              {subDescription}
                            </div>
                          )}
                        </div>
                      </div>
                      <img
                        src={
                          depositMethod === method ? checkboxBlue : checkboxGray
                        }
                        alt={depositMethod === method ? "checked" : "unChecked"}
                        className={styles.paymentCheckImg}
                      />
                    </label>
                  ),
                )}
                {depositMethod === "NONE" && (
                  <div className="bottom_box bottom_box02 checked">
                    <div className="box_tit">
                      <img src={imgTsoo} />
                      <div className="box_cont">
                        <h3>가상계좌</h3>
                        <p>입금 계좌로 충전 후 결제하기</p>
                      </div>
                    </div>
                    <div className="box_cont_checked">
                      <h3 className="sub_bt3_tit">
                        금방금방 계좌로 <br />
                        <span className="fc-bl03">부족한 금액을 입금</span>해
                        주세요.
                      </h3>
                      <div className="box_cont_info">
                        <img src={iconInfoRd} />
                        <p>
                          입금 후 다시{" "}
                          <span>
                            금방금방 앱으로 돌아와서 반드시 <b>결제하기 버튼</b>
                          </span>
                          을 눌러주세요!
                        </p>
                      </div>
                      <button
                        className="box_cont_button"
                        onClick={() => {
                          handleOpenBottomSheet("guide");
                        }}
                      >
                        가상계좌 입금 결제 가이드 확인하기
                      </button>
                      <div className="dash_bar"></div>
                      <div className="bank_box01 mb08 arrow">
                        <p>내가 지정한 입금계좌</p>
                        <ul>
                          <li>
                            <p>{checkedAccountInfo?.bankName}</p>
                          </li>
                          <li>
                            <h3>{checkedAccountInfo?.bankAccountNumber}</h3>
                          </li>
                        </ul>
                      </div>
                      <div className="bank_box02">
                        <ul className="bank_box02_ul01">
                          <li>
                            <h3 className="fc-bl03">금방금방 가상계좌</h3>
                          </li>
                          <li>
                            <p onClick={handleCopyToClipboard}>계좌번호 복사</p>
                          </li>
                        </ul>
                        <ul className="bank_box02_ul02">
                          <li>
                            <p>
                              <span>KEB 하나은행</span> (구 외환은행)
                            </p>
                          </li>
                          <li>
                            <h3>{checkedAccountInfo?.virtualBankNumber}</h3>
                          </li>
                        </ul>
                      </div>
                      <p className="pay_noti">
                        <img src={iconNotiBlue} />
                        <span>
                          <b>입금 시 지정 계좌</b>의 <b>전용 은행 앱</b>을
                          이용해 입금해주세요.
                        </span>
                      </p>
                      <ul className="tt_pay">
                        <li>
                          <h3 className="fc-bk01">부족한 금액</h3>
                        </li>
                        <li>
                          <h3 className="fc-rd01">
                            {numberWithCommas(purchaseInfo.depositPrice)}원
                          </h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="sub02_aco">
          <div className="agency_accordion agency_vertical">
            <ul>
              <li>
                <input
                  type="checkbox"
                  id="checkbox-1"
                  name="checkbox-accordion"
                />
                <label htmlFor="checkbox-1" className="sub02_aco_l">
                  <div className="agency_check">
                    <input
                      type="checkbox"
                      id="check2"
                      checked={isTermChecked}
                      onChange={handleTermChange}
                    />
                    <label htmlFor="check2">
                      <h3>
                        <span>[필수]</span> 거래 취소 불가 동의
                      </h3>
                    </label>
                  </div>
                  <div className="btn_cx_down"></div>
                  <div className="btn_cx_up"></div>
                </label>
                <div className="agency_content">
                  <ul>
                    <li>
                      금방금방 쇼핑 주문은 타 사용자의 자산(금/은)을 매수거래
                      체결한 후 임가공의 절차를 통해 진행되오니 주문 후
                      거래(매수) 취소가 불가합니다.
                    </li>
                    <li>
                      만약 해당 상품의 제작(임가공) 전 취소를 원할 경우에는 주문
                      시 거래(매수) 완료된 해당 자산과 KRW로 돌려받게 됩니다.
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <button
          className="sub_wrap02_btn on"
          onTouchEnd={isMobile ? handleClickPurchase : undefined}
          onClick={!isMobile ? handleClickPurchase : undefined}
          style={{
            backgroundColor: colors.buttonColor,
          }}
        >
          결제하기
        </button>
      </div>
    </div>
  );
};

export default GoodsPurchase;
