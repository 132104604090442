import {
  ASSET_TYPES,
  PERIODS,
  SORT_TYPES,
  TRADE_TYPES,
} from "@utils/constants";
import { scrollToRoot } from "@utils/utils";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import IconLast from "@assets/icons/btn_arrow1.png";
import IconNext from "@assets/icons/btn_arrow2.png";
import IconPrev from "@assets/icons/btn_arrow3.png";
import IconFirst from "@assets/icons/btn_arrow4.png";
import IconFilter from "@assets/icons/ico_filter_24.png";

import useTradeCompleteList, {
  ICompleteParam,
} from "../hooks/useTradeCompleteList";
import * as styles from "../styles.css";
import NoRecords from "./NoRecords";
import {
  AppraiseTradeCard,
  AssetSendReceiveCard,
  AssetTradeCard,
  CouponCard,
  DepositCard,
  EventCard,
  ShoppingCard,
  StorageFeeCard,
  TradeCardProps,
  WithdrawCard,
} from "./TradeCard";
import TradeFilter from "./TradeFilter";

interface CompleteTradeHistoryProps {
  openBottomSheet: (content: React.ReactNode) => void;
}

// 3개월 전과 오늘의 날짜를 초기값으로 설정
const initialFilter = {
  assetTypeList: [],
  tradeTypeList: [],
  fromDt: moment().subtract(3, "months").format("YYYY-MM-DD"),
  toDt: moment().format("YYYY-MM-DD"),
  page: 0,
  size: 30,
  sortType: "DESC",
};

interface TradeType {
  type: string;
  label: string;
  component: React.ComponentType<TradeCardProps>;
}

const TRADE_TYPES_MAPPING: TradeType[] = [
  { type: "APPRAISAL", label: "감정평가", component: AppraiseTradeCard },
  { type: "GOODS", label: "쇼핑", component: ShoppingCard },
  { type: "SELL", label: "자산 팔래요", component: AssetTradeCard },
  { type: "BUY", label: "자산 살래요", component: AssetTradeCard },
  { type: "WITHDRAW", label: "출금", component: WithdrawCard },
  { type: "DEPOSIT", label: "입금", component: DepositCard },
  { type: "COUPON", label: "쿠폰", component: CouponCard },
  { type: "EVENT", label: "이벤트", component: EventCard },
  {
    type: "SEND_ASSET",
    label: "자산 보냄(상속)",
    component: AssetSendReceiveCard,
  },
  {
    type: "RECEIVE_ASSET",
    label: "자산 받음(상속)",
    component: AssetSendReceiveCard,
  },
  {
    type: "STORAGE_FEE",
    label: "보관료",
    component: StorageFeeCard,
  },
];

const detectPeriod = (fromDt: string, toDt: string): string => {
  const today = moment().startOf("day");
  const fromDate = moment(fromDt);
  const toDate = moment(toDt);

  if (fromDate.isSame(today) && toDate.isSame(today)) {
    return "TODAY";
  } else if (
    fromDate.isSame(today.clone().subtract(1, "weeks")) &&
    toDate.isSame(today)
  ) {
    return "WEEK";
  } else if (
    fromDate.isSame(today.clone().subtract(1, "months")) &&
    toDate.isSame(today)
  ) {
    return "MONTH";
  } else if (
    fromDate.isSame(today.clone().subtract(3, "months")) &&
    toDate.isSame(today)
  ) {
    return "3MONTH";
  } else {
    return "CUSTOM";
  }
};

const CompleteTradeHistory: React.FC<CompleteTradeHistoryProps> = ({
  openBottomSheet,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialCompleteParam = useMemo(() => {
    const savedParams = queryParams.get("completeParam");
    return savedParams ? JSON.parse(savedParams) : initialFilter;
  }, [queryParams]);

  const [completeParam, setCompleteParam] =
    useState<ICompleteParam>(initialCompleteParam);
  const [currentPage, setCurrentPage] = useState(completeParam.page || 0);
  const prevCompleteParam = useRef(completeParam);
  const prevPage = useRef(currentPage);
  const { TradeCompleteList, CompleteRefetch, totalPages } =
    useTradeCompleteList({ ...completeParam, page: currentPage });

  const initialPeriodType = detectPeriod(
    completeParam.fromDt,
    completeParam.toDt,
  );
  const [periodType, setPeriodType] = useState<string>(initialPeriodType);
  const [sortType, setSortType] = useState(SORT_TYPES[0].label);

  useEffect(() => {
    if (
      JSON.stringify(completeParam) !==
        JSON.stringify(prevCompleteParam.current) ||
      currentPage !== prevPage.current
    ) {
      CompleteRefetch();
      prevCompleteParam.current = completeParam;
      prevPage.current = currentPage;
    }
  }, [completeParam, currentPage, CompleteRefetch]);

  useEffect(() => {
    scrollToRoot({ isSmooth: false });
  }, [currentPage]);

  useEffect(() => {
    const updatedCompleteParam = { ...completeParam, page: currentPage };
    const params = new URLSearchParams(location.search);
    params.set("completeParam", JSON.stringify(updatedCompleteParam));
    params.set("statusType", "COMPLETE");
    navigate({ search: params.toString() }, { replace: true });
  }, [completeParam, navigate, currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleOpenTradeFilter = () => {
    openBottomSheet(
      <TradeFilter
        statusType="COMPLETE"
        onConfirm={(newFilter, newPeriod, newSort) => {
          setCompleteParam({ ...newFilter, page: 0 }); // 필터 변경 시 페이지를 0으로 초기화
          setPeriodType(newPeriod as string);
          setSortType(newSort);
          setCurrentPage(0); // 페이지 0으로 초기화
        }}
      />,
    );
  };

  const periodTypeLabel =
    PERIODS.find((period) => period.value === periodType)?.label || periodType;
  const sortTypeLabel =
    SORT_TYPES.find((sort) => sort.value === sortType)?.label || sortType;

  const getAssetTypeLabel = (completeParam: ICompleteParam) => {
    return completeParam.assetTypeList.length === ASSET_TYPES.length
      ? "전체"
      : "선택";
  };

  const getTradeTypeLabel = (completeParam: ICompleteParam) => {
    return completeParam.tradeTypeList.length === TRADE_TYPES.length
      ? "전체"
      : "선택";
  };

  const renderTradeCards = () => {
    if (TradeCompleteList?.length === 0) {
      return <NoRecords />;
    }
    return (
      <div>
        {TradeCompleteList?.map((trade, index) => {
          const matchedComponent = TRADE_TYPES_MAPPING.find(
            (item) => item.type === trade.tradeType,
          );
          const Component = matchedComponent?.component!;

          return (
            <Component
              key={trade?.requestAt + trade.requestId + index}
              statusType="COMPLETE"
              tradeInfo={trade}
            />
          );
        })}
        <div className={styles.pagination}>
          <button
            className={styles.pageBtn}
            onClick={() => handlePageChange(0)}
          >
            <img src={IconFirst} alt="처음" />
          </button>
          <button
            className={styles.pageBtn}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <img src={IconPrev} alt="이전" />
          </button>
          <div>
            <span className={styles.currentPage}>{currentPage + 1}</span>
            <span className={styles.totalPages}>/{totalPages}</span>
          </div>
          <button
            className={styles.pageBtn}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <img src={IconNext} alt="다음" />
          </button>
          <button
            className={styles.pageBtn}
            onClick={() => handlePageChange(totalPages - 1)}
          >
            <img src={IconLast} alt="마지막" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="sub08_slide complete" onClick={handleOpenTradeFilter}>
        <h3>
          {periodTypeLabel}/유형 {getTradeTypeLabel(completeParam)}/자산{" "}
          {getAssetTypeLabel(completeParam)}/{sortTypeLabel}
        </h3>
        <img src={IconFilter} className="filter" />
      </div>
      <div className={`scont_wrap complete}`}>{renderTradeCards()}</div>
    </div>
  );
};

export default CompleteTradeHistory;
