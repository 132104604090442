import { routes } from "@/routes";
import {
  hideLoading,
  selectLoading,
  showLoading,
} from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export interface IAccountInfo {
  bankType: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
  decAccountNumber: string;
  maskingAccountNumber: string;
  virtualBankNumber: null;
  bankAccountStatus: string;
}

const useAccountInfo = (bankType: string) => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getAccountInfo(bankType);
      dispatch(hideLoading());
      return response.data.data as IAccountInfo;
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IAccountInfo>(
    [routes.myAccountManagement, { bankType }],
    fetchDetails,
  );

  return { AccountInfo: data, isLoading, error, AccountInfoRefetch: refetch };
};

export default useAccountInfo;
