import { RootState } from "@app/store";
import { setLocalStorageItem } from "@services/storageService";
import { numberWithCommas, requestNativeLogin } from "@utils/utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction } from "react-router-dom";

import iconSellGd from "@assets/icons/icon_sell_gd.png";
import iconSellSv from "@assets/icons/icon_sell_sv.png";

import { routes } from "../../../routes";

interface Holdings {
  krw: number;
  gold: number;
  silver: number;
  point: number;
  acceptableKrw: number;
  acceptableGold: number;
  acceptableSilver: number;
  acceptablePoint: number;
}
interface FeeRate {
  configType: string;
  assetType: string;
  feeRate: number;
  priceLimit: number;
}

interface AssetsAvailableSaleProps {
  holdings: Holdings;
  feeRate: FeeRate[];
  currentPrice: string;
  navigate: NavigateFunction;
  marketPriceData: any;
}

const AssetsAvailableSale: React.FC<AssetsAvailableSaleProps> = ({
  holdings,
  currentPrice,
  feeRate,
  navigate,
  marketPriceData,
}) => {
  const { isSigned } = useSelector((state: RootState) => state.auth);
  const [goldPrice, setGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const { acceptableGold, acceptableSilver } = holdings;

  useEffect(() => {
    const goldFeeRate = feeRate.find(
      (item) => item.assetType === "GOLD" && item.configType === "SELL",
    )?.feeRate;
    const silverFeeRate = feeRate.find(
      (item) => item.assetType === "SILVER" && item.configType === "SELL",
    )?.feeRate;
    let goldFeeValue = 0;
    let silverFeeValue = 0;

    if (goldFeeRate) {
      goldFeeValue = 1 - goldFeeRate;
    }
    if (silverFeeRate) {
      silverFeeValue = 1 - silverFeeRate;
    }

    const gold =
      acceptableGold *
      parseInt(marketPriceData["gold"]?.current) *
      goldFeeValue;
    const silver =
      acceptableSilver *
      parseInt(marketPriceData["silver"]?.current) *
      silverFeeValue;

    setGoldPrice(
      acceptableGold === null
        ? "0"
        : numberWithCommas(Math.floor(gold).toString()),
    );
    setSilverPrice(
      acceptableSilver === null
        ? "0"
        : numberWithCommas(Math.floor(silver).toString()),
    );
  }, [currentPrice, feeRate, holdings]);

  const formatToDon = (value: number) => {
    return numberWithCommas(Math.round((value / 3.75) * 1000) / 1000); // 소수점 3자리 반올림
  };

  const handleCheckLogin = () => {
    // 로그인 여부 체크
    if (!isSigned) {
      requestNativeLogin(navigate);
      return;
    }
  };

  return (
    <div className="sell_accordion sell_vertical sell_main04 shadow88">
      <ul>
        <li>
          <input
            type="checkbox"
            id="checkbox-1"
            name="checkbox-accordion"
            defaultChecked={isSigned ? true : false}
            onChange={handleCheckLogin}
          />
          <label htmlFor="checkbox-1">
            <h3>판매 가능 자산</h3>
            <div className="btn_cx_down"></div>
            <div className="btn_cx_up"></div>
          </label>
          <div className="sell_content">
            <div className="sell_main04_as">
              <div>
                <img src={iconSellGd} alt="" className="icon_sell_main04" />
                <p>금</p>
              </div>
              <ul>
                <li>
                  <h3 className="spoqa">{numberWithCommas(acceptableGold)}</h3>g
                  <p>({formatToDon(acceptableGold)}돈)</p>
                </li>
                <li>
                  <span className="spoqa">{numberWithCommas(goldPrice)}</span>원
                </li>
              </ul>
            </div>
            <div className="sell_main04_as">
              <div>
                <img src={iconSellSv} alt="" className="icon_sell_main04" />
                <p>은</p>
              </div>
              <ul>
                <li>
                  <h3 className="spoqa">
                    {numberWithCommas(acceptableSilver)}
                  </h3>
                  g <p>({formatToDon(acceptableSilver)}돈)</p>
                </li>
                <li>
                  <span className="spoqa">{numberWithCommas(silverPrice)}</span>
                  원
                </li>
              </ul>
            </div>
            <button
              onClick={() => {
                navigate(routes.tradeAsset, {
                  state: { tradeType: "SELL" },
                });
                setLocalStorageItem("tradingAssetType", "GOLD");
              }}
            >
              내 자산 팔기
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AssetsAvailableSale;
