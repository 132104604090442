import { RootState } from "@/app/store";
import { setPurchaseInfo } from "@/features/purchase/purchaseSlice";
import { numberWithCommas } from "@/utils/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useUserAssets from "../hooks/useUserAssets";
import * as styles from "../styles.css";

interface IAssetsProps {
  assetType: string;
  goodsGram: number;
}

const Assets: React.FC<IAssetsProps> = ({ assetType, goodsGram }) => {
  const dispatch = useDispatch();
  const purchaseInfo = useSelector((state: RootState) => state.purchase);
  const { UserAssets } = useUserAssets();
  const maxGram = goodsGram * purchaseInfo.qty;
  const userGram =
    assetType === "GOLD"
      ? UserAssets?.acceptableGold
      : UserAssets?.acceptableSilver;
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (!UserAssets) return;
    const maxAvailableGrams = Math.min(maxGram, userGram ?? 0);
    if (isChecked) {
      dispatch(setPurchaseInfo({ useGram: maxAvailableGrams }));
    } else {
      dispatch(setPurchaseInfo({ useGram: 0 }));
    }
  }, [isChecked, UserAssets]);

  const handleAssetToggle = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <div>
      <div className="shadow88 sub02_04 pb24">
        <h3 className="shadow88_tit">보유 자산</h3>
        <div className="box_tit02 mb08">
          <div className="box_stit02">
            <h3>{assetType === "GOLD" ? "금" : "은"}</h3>
            <p className="box_stit02n">
              <span>{` 사용 가능한 ${
                assetType === "GOLD" ? "금" : "은"
              }`}</span>
              <span className={styles.userGram}>
                {numberWithCommas(userGram)}g
              </span>
            </p>
          </div>
          <div className="box_checkbox">
            <input
              type="checkbox"
              id="all-check"
              onChange={handleAssetToggle}
              checked={isChecked}
            />
            <label htmlFor="all-check">
              <p className={styles.checkInfo}>{`보유한 ${
                assetType === "GOLD" ? "금" : "은"
              } 모두 사용`}</p>
            </label>
          </div>
        </div>
        <div className="box_tit02_ul">
          <ul>
            <li>
              <b>현금</b>
            </li>
            <li>{numberWithCommas(UserAssets?.acceptableKrw ?? 0)}KRW</li>
          </ul>
          <ul>
            <li>
              <b>포인트</b>
            </li>
            <li>{numberWithCommas(UserAssets?.acceptablePoint ?? 0)}pt</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Assets;
