import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

interface IDepositConfig {
  minKrw: number;
  maxKrw: number;
  withdrawTimeLimit: number;
}

const useDepositConfig = () => {
  const dispatch = useDispatch();

  const fetchConfig = async () => {
    dispatch(showLoading());
    try {
      const [minResponse, maxResponse, timeLimitResponse] = await Promise.all([
        APIS.getEnvConfig("DEPOSIT_MIN_KRW"),
        APIS.getEnvConfig("MAX_KRW_DEPOSIT_DAY"),
        APIS.getEnvConfig("WITHDRAW_TIME_LIMIT"),
      ]);
      dispatch(hideLoading());
      return {
        minKrw: minResponse.data.data?.value || 100,
        maxKrw: maxResponse.data.data?.value || 100000000,
        withdrawTimeLimit: timeLimitResponse.data.data?.value || 1,
      };
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useCustomQuery<IDepositConfig>(
    [routes.deposit, "config"],
    fetchConfig,
  );

  return { config: data, isLoading, error, refetchConfig: refetch };
};

export default useDepositConfig;
