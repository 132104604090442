import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import {
  IPurchaseInfo,
  setPurchaseInfo,
} from "@features/purchase/purchaseSlice";
import { showToast } from "@features/toast/toastSlice";
import * as APIS from "@services/apis";
import { CUSTOM_AVAILABLE_MEMO, MEMO_LIST } from "@utils/constants";
import { formatPhoneNumberForPurchase, validatePhone } from "@utils/utils";

export const createPurchaseInfo = (
  purchaseInfo: IPurchaseInfo,
  tossOrderId?: string,
  tossPaymentKey?: string,
) => {
  const {
    useGram,
    useKrw,
    usePoint,
    optionValue,
    qty,
    buyPrice,
    sellingPrice,
    standardPrice,
    depositPrice,
    depositMethod,
    finPassword,
    goodsDisplayMode,
    orderName,
    goodsId,
    isDelivery,
    receiver,
    addressName,
    postCode,
    address,
    addressDetail,
    phone,
    mobile,
    memo,
    customMemo,
    visitDate,
    shopId,
    isDefault,
  } = purchaseInfo;

  const formatMemo = memo === "" ? "" : customMemo ? customMemo : memo;

  // mobile 지역번호만 입력하거나 유효성 틀릴 경우, 저장 X
  const formatMobile = validatePhone(mobile)
    ? mobile
    : { prefix: "", part1: "", part2: "" };

  const deliveryInfo = isDelivery
    ? {
        delivery: {
          receiver,
          addressName,
          postCode,
          address,
          addressDetail,
          phone: formatPhoneNumberForPurchase(phone),
          mobile: formatPhoneNumberForPurchase(formatMobile),
          memo: formatMemo,
          isDefault,
        },
      }
    : {
        visitDate,
        shopId,
      };

  const necessaryInfo = {
    useGram,
    useKrw,
    usePoint,
    optionValue,
    qty,
    buyPrice,
    sellingPrice,
    standardPrice,
    depositPrice,
    depositMethod,
    goodsDisplayMode,
    orderName,
    goodsId,
    finPassword,
    isDelivery,
    ...deliveryInfo,
  };
  // console.log(necessaryInfo);

  return { ...necessaryInfo, tossOrderId, tossPaymentKey };
};

// 결제 정보 업데이트
export const updatePurchaseInfo = async (txId: string, dispatch: any) => {
  try {
    dispatch(showLoading());
    const response = await APIS.getPreApproval(txId);
    dispatch(hideLoading());

    if (response.data.success) {
      const { data } = response.data;
      const formattedMemo = MEMO_LIST.includes(data.memo)
        ? data.memo
        : CUSTOM_AVAILABLE_MEMO;
      const formattedCustomMemo = MEMO_LIST.includes(data.memo)
        ? ""
        : data.memo;

      dispatch(
        setPurchaseInfo({
          ...data,
          memo: formattedMemo,
          customMemo: formattedCustomMemo,
        }),
      );
    } else {
      dispatch(showToast({ message: response.data.message, icon: "error" }));
    }
  } catch (error) {
    dispatch(hideLoading());
    const err = error as any;
    if (err.response && err.response.data && err.response.data.message) {
      dispatch(
        showToast({ message: err.response.data.message, icon: "error" }),
      );
    }
    throw error;
  }
};
