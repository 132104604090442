import iconLeft from "../../../assets/icons/icon_left.png";
import Calendar from "../../../components/common/calendar/Calendar";

interface ReservationDataProps {
  shopId?: number | null;
  visitDate?: any;
}
interface SelectDateProps {
  goHome: () => void;
  reservationData: ReservationDataProps;
  setReservationData: React.Dispatch<React.SetStateAction<object>>;
}

const SelectDate: React.FC<SelectDateProps> = ({
  goHome,
  reservationData,
  setReservationData,
}) => {
  return (
    <div className="sub_bottom cal_tab">
      <div className="sub_bottom_wrap">
        <div className="sub_top01">
          <h3 className="sub_bottom_tit">
            <img src={iconLeft} alt="" className="icon_back" onClick={goHome} />
            예약일 선택
          </h3>
        </div>
        <Calendar
          reservationData={reservationData}
          setReservationData={setReservationData}
        />
      </div>
    </div>
  );
};

export default SelectDate;
